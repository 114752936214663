/* global localStorage */
import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_HOST}/api`,
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
});

export const setHeaders = (token) => {
  Object.assign(axiosInstance.defaults, { headers: { authorization: `Bearer ${token}` } });
};
