import React from 'react';
import PropTypes from 'prop-types';
import FieldDetail from './FieldDetail';


const SectionDetail = ({
  level, fields, section }) => (
    <div className={level > 1 ? 'valoracion-detail-subsection' : 'valoracion-detail-section'}>
      <div className="valoracion-header">
        { level === 1
          ? (
            <>
              <h3>{`${section.title} (${section.percentage}%)`}</h3>
              <h3>{`Suma: ${section.total}  Subtotal: ${Math.round(section.total*(section.percentage/100))}`}</h3>
            </>
          )
          : (
            <>
              <div className="subtotal">{`${section.title} (${section.percentage}%)`}</div>
              <div className="subtotal">{`Suma: ${section.total}  Subtotal: ${Math.round(section.total*(section.percentage/100))}`}</div>
            </>
          )
        }
      </div>

      <div className={level > 1 ? 'nested-content' : ''}>
        { section.content.map(item => (
          item.type === 'section'
            ? <SectionDetail key={`subsec-${item.id}`} level={level + 1} fields={fields} section={item} />
            : <FieldDetail key={`field-${item.id}`} fieldForm={fields.find(f => f.id === item.field_id)} fieldValor={item} />
        ))}
      </div>
    </div>
);


SectionDetail.propTypes = {
  level: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  section: PropTypes.object.isRequired
};


export default SectionDetail;
