import { useState } from 'react';

export default ({ max, list }) => {
  const [count, setCount] = useState(list ? list.length : 0);
  const [validators, setValidators] = useState(list);

  const onValidatorChange = ({ index, ...val }) => {
    const newValidators = [...validators];
    newValidators[index] = val;
    setValidators(newValidators);
  };

  const onValidatorRemove = (index) => {
    const newValidators = [...validators];
    newValidators.splice(index, 1);
    setCount(count - 1);
    setValidators(newValidators);
  };

  const addValidator = () => {
    if (count < max) {
      const newValidators = validators ? [...validators] : [];
      newValidators.push({ type: '', value: '' });
      setCount(count + 1);
      setValidators(newValidators);
    }
  };

  const onChange = ({ index, ...val }) => {
    if (index === undefined) {
      addValidator();
    } else if (Object.keys(val).length === 0) {
      onValidatorRemove(index);
    } else {
      onValidatorChange({ index, ...val });
    }
  };

  return [validators, onChange];
};
