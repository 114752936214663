import {
  FETCH_LIST,
  CLEAR_ACTIONS
} from './types';


const BACKEND_API = 'BACKEND_API';

export const fetchValoracion = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_LIST,
    method: 'GET',
    url: `/valoraciones/${id}/`
  }
});

export const clearActions = () => ({
  type: CLEAR_ACTIONS
});
