import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const parseValue = (str) => {
  if (str.indexOf('{') > -1) {
    return JSON.parse(str);
  }
  return str;
};

const reducer = (state, action) => ({
  ...state,
  [action.type]: parseValue(action.payload)
});

const Conditional = ({ index, data, fields, onChange }) => {
  const [state, dispatch] = useReducer(reducer, { ...data });
  const [values, setValues] = useState([]);

  useEffect(() => {
    const field = fields.find(f => f.id === state.field);
    setValues(field ? field.values : []);
    onChange({ index, ...state });
  }, [state]);
  return (
    <div className="number-validator">
      <select name="state" onChange={e => dispatch({ type: 'state', payload: e.target.value })}>
        <option value='{"hidden": true}' selected={data.state.hidden}>Visible </option>
        <option value='{"required": true}' selected={data.state.required}>Requerido</option>
      </select>
      <span>si el campo </span>
      <select name="field" onChange={e => dispatch({ type: 'field', payload: e.target.value })} defaultValue={data.field}>
        <option value="" disabled selected={state.field === ''}>Seleccione una opción</option>
        {fields.map(f => <option value={f.id}>{f.title}</option>)}
      </select>
      <span> tiene el valor </span>
      {values ? (
        <select name="value" onChange={e => dispatch({ type: 'value', payload: e.target.value })}>
          <option value="" disabled selected={state.value === ''}>Seleccione una opción</option>
          {values.map(v => (
            <option value={v.key} selected={state.value === v.key}>
              {v.value}
            </option>
          ))}
        </select>
      )
        : <input type="text" defaultValue={data.value} onChange={e => dispatch({ type: 'value', payload: e.target.value })} />
    }
      <button type="button" className="remove-btn" onClick={() => onChange({ index })}>
      &times;
      </button>
    </div>
  );
};

Conditional.propTypes = {
  data: PropTypes.object.isRequired
};
export default Conditional;
