import React, { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from './components/Table';
import Paginator from '../../../components/paginator';
import PrimaryButton from '../../../components/PrimaryButton';
import { fetchList, clearDelayedProfile } from '../redux/actions';
import Secondarybutton from '../../../components/SecondaryButton';
import { fetchSector } from '../../Sectors/redux/actions';


const reducer = (state, action) => {
  if (action.type === 'reset') {
    return { page: 1, estado: undefined, nombre: undefined };
  }
  if (action.type === 'all') {
    return action.payload;
  }
  return {
    ...state,
    [action.type]: action.payload
  };
};

const ProfilesList = ({ match, location, fetch, list, maxCount, oldProfile, status, sector, fetchSectorData, clearDelayed }) => {
  const [params, dispatch] = useReducer(
    reducer,
    { page: 1, estado: undefined, nombre: undefined, proceso: undefined }
  );

  useEffect(() => {
    fetchSectorData(match.params.id, params);
  }, []);

  useEffect(() => {
    fetch(match.params.id, params);
  }, [params.page]);

  useEffect(() => {
    clearDelayed();
  }, [oldProfile.id]);

  const handleFilter = () => {
    const newParams = { ...params, page: 1 };
    fetch(
      match.params.id,
      newParams
    );
    dispatch({ type: 'all', payload: newParams });
  };

  const handleClearFilters = () => {
    dispatch({ type: 'reset' });
    fetch(
      match.params.id,

      { page: 1, estado: undefined, nombre: undefined }
    );
  };
  return (
    <>
      <div className="page-title-nav">
        <h1>Cargos/Roles</h1>
        <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
      </div>
      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">Sector</span>
            {sector && sector.nombre}
          </div>
        </div>
        <div className="content profiles">
          <div className="filters">
            <div className="filter-inputs">
              <div className="filter-pair">
                <input type="Nombre" placeholder="Filtar por nombre" value={params.nombre || ''}
                  onChange={e => dispatch({ type: 'nombre', payload: e.target.value })}
                />
                <select name="" id="" onChange={e => dispatch({ type: 'estado', payload: e.target.value })} value={params.estado || ''}>
                  <option value="" selected disabled>Filtrar por estado</option>
                  <option value="HA">Habilitado</option>
                  <option value="CO">En Construcción</option>
                </select>
              </div>
              <div className="filter-pair">
                <select name="" id="" onChange={e => dispatch({ type: 'proceso', payload: e.target.value })} value={params.proceso || ''}>
                  <option value="" selected disabled>Filtrar por proceso</option>
                  {sector && sector.procesos.map(p => (
                    <option value={p.id}>{p.nombre}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="filter-buttons">
                <PrimaryButton text="Filtrar" onClick={handleFilter} />
                <Secondarybutton text="Borrar filtros" onClick={handleClearFilters} />
              </div>
          </div>
          <Table location={location} data={list} />
          <Paginator page={parseInt(params.page)} max={maxCount} onChange={e => dispatch({ type: 'page', payload: e })} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  list: state.profiles.list,
  maxCount: state.profiles.count,
  sector: state.sectores.sector,
  oldProfile: state.profiles.profile
});

const mapDispatchToProps = dispatch => ({
  fetch: (id, page) => dispatch(fetchList(id, page)),
  fetchSectorData: id => dispatch(fetchSector(id)),
  clearDelayed: () => dispatch(clearDelayedProfile()),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilesList);
