/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-spring/renderprops';
import { connect } from 'react-redux';
import { transitions } from '../../../utils';

import StringValidator from '../../../validators/StringValidator';
import Conditional from '../../../conditionals/Conditional';
import useValidators from '../../../hooks/useValidators';
import useConditionals from '../../../hooks/useConditionals';


const EditString = ({ id, validators, conditionals, onChangeField, schemaFields }) => {
  const [vals, setVals] = useValidators({ max: 3, list: validators });
  const [conds, setConds] = useConditionals(conditionals);

  useEffect(() => {
    onChangeField(id, 'validators', vals);
  }, [vals]);

  useEffect(() => {
    if (conds) {
      if (conds.length > 0) {
        onChangeField(id, 'conditionals', conds);
        onChangeField(id, 'hidden', true);
      } else {
        onChangeField(id, 'conditionals', undefined);
      }
    }
  }, [conds]);

  return (
    <>
      <Transition items={validators !== undefined} {...transitions}>
        {show => show && (props => (
          <label className="validators" style={props}>
            Condiciones
            {vals && vals.map((v, k) => (
              <StringValidator index={k} type={v.type}
                value={v.value} onChange={setVals}
                used={vals.map(va => va.type).filter(va => va)}
              />
            ))}
            {(!vals || vals.length < 3) && (
            <button className="add-option" type="button" onClick={setVals}>
              Agregar
            </button>
            )}
          </label>
        ))}
      </Transition>
      <Transition items={conditionals !== undefined} {...transitions}>
        {show => show && (props => (
          <label className="conditionals" style={props}>
          Lógica
            {conds && conds.length > 0 && (
            <div>
              {conds.map((cond, i) => (
                <Conditional index={i} data={cond}
                  fields={schemaFields.filter(f => f.id !== id)} onChange={setConds}
                />
              ))}
            </div>
            )}
            <button className="add-option" type="button"
              onClick={setConds}
            >
            Agregar
            </button>
          </label>
        ))}
      </Transition>
    </>
  );
};

EditString.propTypes = {
  id: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.object),
  conditionals: PropTypes.arrayOf(PropTypes.object),
  schemaFields: PropTypes.arrayOf(PropTypes.object),
  onChangeField: PropTypes.func.isRequired
};

EditString.defaultProps = {
  validators: undefined,
  conditionals: undefined,
  schemaFields: undefined
};

const mapStateToProps = state => ({
  schemaFields: state.forms.fields
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditString);
