import { axiosInstance } from './axios';

const BACKEND_API = 'BACKEND_API';


const apiMiddleware = ({ dispatch }) => next => (action) => {
  if (action.type !== BACKEND_API) {
    return next(action);
  }

  const { method, url, params, data } = action.payload;

  dispatch({
    type: action.payload.pending,
    payload: action.payload.extra
  });

  axiosInstance({ method, url, data, params }).then(
    (response) => {
      dispatch({
        type: action.payload.success,
        payload: response.data
      });
    },

    (error) => {
      dispatch({
        type: action.payload.error,
        payload: error
      });
    }
  );
  return {};
};

export default apiMiddleware;
