import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Paginator = ({ page, max, onChange }) => {
  let range = [page - 1, page, page + 1].filter(e => e > 0 && e <= max);
  

  return (
    <div className="paginator">
      {page > 2 && <button onClick={() => onChange(1)} type="button">1</button>}
      {page > 3 && ('...')}
      {range.map(n => (
        <>
          <button className={n === page ? 'active' : ''}
            onClick={() => onChange(n)} type="button"
          >
            {n}
          </button>
        </>
      ))}
      {page < max - 2 && ('...')}
      {page < max - 1 && (
      <button onClick={() => onChange(max)} type="button">
        {max}
      </button>
      )}
    </div>
  );
};

Paginator.propTypes = {
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
export default Paginator;
