import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ValoracionField from './ValoracionField'


const ValoracionSection = ({
  level, number, data, valoraciones, fields, fieldsDict, handleChange, showErrors, validateField }) => {
  return (
    <div>
      { level === 1
        ? <h3>{`${number} - ${data.title}`}</h3>
        : <h4>{`${number} - ${data.title}`}</h4>
      }

      <div className={level > 1 ? 'nested-content' : ''}>
        { data.content.map((item, index) => (
          item.type === 'section'
            ? (
              <ValoracionSection key={`subsec-${item.id}`} level={level + 1} number={`${number}.${index + 1}`} data={item}
                valoraciones={valoraciones} fields={fields} fieldsDict={fieldsDict}
                handleChange={handleChange} showErrors={showErrors} validateField={validateField}
              />
            )
            : fieldsDict[item.field_id] && (
              <ValoracionField key={`field-${item.id}`} number={`${number}.${index + 1}`} fields={fields} fieldId={item.field_id}
                fieldInfo={fieldsDict[item.field_id]}
                valoracion={valoraciones[item.field_id]} handleChange={handleChange}
                showErrors={showErrors} validateField={validateField}
              />
            )
        ))}
      </div>
    </div>
  );
};


ValoracionSection.propTypes = {
  level: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  valoraciones: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldsDict: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  validateField: PropTypes.func.isRequired
};


export default ValoracionSection;
