import React, { useEffect, useState } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes, { instanceOf } from 'prop-types';
import moment from 'moment';


import {
  fetchProfile, updateProfile,
  fetchAnswers, clearActions } from '../redux/actions';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import AnswerField from '../../../pulpoforms/answer/AnswerField';
import ConfirmationScreen from '../../../components/ConfirmationScreen';
import Matrix from './components/Matrix';
import MultiSelect from './components/MultiSelect';
import Spinner from '../../../components/Spinner';

const isSection = sectionID => sectionID.includes('SEC');

const Wizard = ({
  match: { params: { profileId } },
  answers, profile, updateStatus,
  fetch, update, clear, fetchAllAnswers }) => {
  const [fields, setFields] = useState();
  const [ans, setAns] = useState(undefined);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [confirmation, setConfirmation] = useState(false);
  const [direction, setDirection] = useState();

  const calculateQuantities = (currentField, answers)=>{
    let quantities = null;
    if(currentField.type === "matrix"){
      quantities={};
      currentField.rows.map(row => {
        quantities[row.key] = {};
        currentField.columns.map(column => {      
          quantities[row.key][column.key] = 0;
        })
      })
      quantities.total=0;
      answers.map(answer => {
        quantities.total++;
        Object.keys(answer.response[currentField.id]).map(row=>{
          answer.response[currentField.id][row].map(column => {
            quantities[row][column]++;
          })
        })
      })
    }
    if(currentField.type === "select" || currentField.type === "multiselect"){
      quantities = {
        "0":{
          "quantity": 0,
          "value": "No responde"
        }
      };
      let total = 0;
      for (const option in currentField.values){
        quantities[currentField.values[option].key]= {};
        quantities[currentField.values[option].key].quantity = 0;
        quantities[currentField.values[option].key].value = currentField.values[option].value;
      }
      for (const answerInd in answers){
        if (answers[answerInd].response[currentField.id]){
          if (currentField.type === "select"){
            if (answers[answerInd].response[currentField.id].includes('otros - ')){
              quantities['otros - '].quantity++;
            }
            else{
              quantities[answers[answerInd].response[currentField.id]].quantity++;
            }
          }
          else if (currentField.type === "multiselect"){
            answers[answerInd].response[currentField.id].map(resp => {
              if (resp.includes('otros - ')){
                quantities['otros - '].quantity++;
              }
              else{
                quantities[resp].quantity++;
              }
            })
          }
        }
        else{
          quantities["0"].quantity++;
        }
        total++;
      }
      quantities.total = total;
    }
    return quantities;
  }

  const getNumeratedFields = () => {
    const theFields = [];
    profile.schema.pages.forEach((page, i1) => {
      page.content.forEach((c, i2) => {
        if (isSection(c)) {
          const sec = profile.schema.sections.find(s => s.id === c);
          sec.fields.forEach((fid, i3) => theFields.push(
            { ...profile.schema.fields.find(f => f.id === fid),
              index: `${i1 + 1}.${i2 + 1}.${i3 + 1}`
            }
          ));
        } else {
          theFields.push(
            { ...profile.schema.fields.find(f => f.id === c),
              index: `${i1 + 1}.${i2 + 1}`
            }
          );
        }
      });
    });
    return theFields.filter(f => !(!f.profile && !f.rate && f.hidden)).sort((x, y) => x.index < y.index);
  };

  useEffect(() => {
    fetch(profileId);
    return () => { clear(); };
  }, []);

  useEffect(() => {
    if (updateStatus.success) {
      const target = 
        direction === 'next' 
          ? currentIndex + 1 
          : currentIndex - 1;
      if (target < fields.length) {
        setCurrentIndex(target);
      } else {
        setConfirmation(true);
      }
    }
  }, [updateStatus]);



  useEffect(() => {
    if (profile.schema) {
      const thefields = getNumeratedFields(profile.schema);
      setFields(thefields);
      
      if (profile.perfil) {
        setAns(profile.perfil);
      } else {
        // si el perfile esta vacio debemos inicializar
        // el diccionario de respuestas.
        const newAnswers = {};
        thefields.forEach((f) => { newAnswers[f.id] = ''; });
        setAns(newAnswers);
      }
      fetchAllAnswers(profile.id);
    }
  }, [profile]);

  /**
   * motodo para navergar por los campos
   * */
  const navigateTo = (target, direction) => {
    if (target >= 0) {
      setDirection(direction);
      update(profileId, { perfil: ans });
    }
  };


  /**
   * maneja los cambios de valor en la respuesta
   */
  const handleAnswerChange = (id, value) => {
    setAns({ ...ans, [id]: value });
  };

  const handleAnswerChangeSelectOthers = (id, value) => {
    setAns({ ...ans, [id]: "otros - " + value });
  };

  const handleOthersMultiSelect = (id, value) => {
    let multiSelect = ans[id].filter(v => !v.includes('otros - '))
    multiSelect.push('otros - ' + value)
    setAns({ ...ans, [id]: multiSelect });
  };

  /**
   * formatea la respuesta de acuerdo a su tipo
   */
  const formatAnswer = (i, a) => {
    switch (fields[i].type) {
      case 'date':
        return moment(a).format('L');
      case 'datetime':
        return moment(a).format('L LT');
      case 'select': {
        const fi = fields[i].values.find(f => f.key === a);
        return fi ? fi.value : '';
      }
      case 'multiselect':
        if (typeof (a) === 'object') {
          return <MultiSelect answer={a} values={fields[i].values} />;
        }
        return '';
      case 'matrix':
        if (typeof (a) === 'object') {
          return <Matrix answer={a} rows={fields[i].rows} columns={fields[i].columns} />;
        }
        return '';
      default:
        return a;
    }
  };

  const currentField = fields && fields[currentIndex];
  
  let quantities = currentField && calculateQuantities(currentField, answers);

  return (
    confirmation
      ? (
        <ConfirmationScreen title={`Cargo/Rol - ${profile && profile.nombre}`}
          content="El Cargo/Rol fue guardado con éxito"
          link={{ url: `/sectores/${profile.sector.id}/perfiles`, text: 'Volver al listado de cargos/roles' }}
        />
      )
      : (
        <div className="profile-construction">
          <Prompt
            when={updateStatus.loading}
            message="Hay cambios pendientes de guardar. Seguro que quieres salir?"
          />
          <div className="page-title-nav">
            <h1>Construcción Cargo/Rol</h1>
            {profile.sector && <Link to={`/sectores/${profile.sector.id}/`}>Volver al sector</Link>}
          </div>
          <div className="col-container">
            <div className="col-left">
              <div className="info">
                <span className="label">Sector</span>
                {profile.sector && profile.sector.nombre}
              </div>
              <div className="info">
                <span className="label">Cargo/Rol</span>
                {profile && profile.nombre}
              </div>
            </div>
            <div className="content">
              {currentField && (
              <AnswerField {...currentField} errors="" index={currentField.index}
                answer={ans[currentField.id]} onAnswerChange={handleAnswerChange}
                key={currentField.id} satisfyConditional renderProfileText quantities={quantities} puedeEncuestar
                onOthersCHange={handleAnswerChangeSelectOthers}
                onOthersCHangeMulti={handleOthersMultiSelect}
                wizard
              />
              )}
              <div className="buttons">
                <SecondaryButton text="Anterior" onClick={() => navigateTo(currentIndex - 1, 'prev')} />
                <PrimaryButton text="Siguiente" onClick={() => navigateTo(currentIndex + 1, 'next')} />
                <Spinner className="right-spinner" active={updateStatus.loading} />
              </div>

              <table className="answers-table">
                <thead>
                  <tr>
                    <td>Respuestas</td>
                    <td />
                  </tr>
                </thead>
                <tbody>
                  {
                    fields && fields.length > 0 && answers.map(a => {
                      
                      let value = a.response[fields[currentIndex].id]

                      if (typeof value === 'string' && value.includes('otros - ')){
                        value = 'otros - '
                      }

                      
                      if (Array.isArray(value) && value.find(v => v.includes('otros - '))){
                        value = value.filter(v => !v.includes('otros - ')).concat(['otros - '])
                      }
                      
                      return({
                        key: a.id,
                        value: value
                      })
                    }).map(r => (
                      <tr>
                        <td>{formatAnswer(currentIndex, r.value)}</td>
                        <td><a rel="noopener noreferrer" target="_blank" href={profile.sector && `/ver-respuesta/${r.key}`}>Ver Respuesta</a></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

            </div>
          </div>
        </div>
      )
  );
};

Wizard.propTypes = {
  match: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  fetchAllAnswers: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profiles.profile,
  answers: state.profiles.answers,
  updateStatus: state.profiles.updateStatus
});

const mapDispatchToProps = dispatch => ({
  fetch: profileId => dispatch(fetchProfile(profileId)),
  fetchAllAnswers: versionID => dispatch(fetchAnswers(versionID)),
  update: (profileId, answers) => dispatch(updateProfile(profileId, answers)),
  clear: () => dispatch(clearActions())
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wizard);
