import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({isChecked, id, label, onChange, checked, readOnly, quantity, total }) => (
  <div className="checkbox">
    <label>
      {label}
      <input type="checkbox" checked={isChecked} id={id} disabled={readOnly} onChange={onChange} defaultChecked={checked} />
    </label>
      {(quantity!=null) &&
        <>
          <div className="info-radio-arrow"/>
          <div className="info-radio">
            <p>{`${quantity} / ${total}`}</p>
          </div>
        </>
      }
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  readOnly: PropTypes.bool
};

Checkbox.defaultProps = {
  checked: false,
  readOnly: false
};

export default Checkbox;
