import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Matrix = ({ readOnly, id, answer, onAnswerChange, rows, columns, widget, quantities }) => {
  const [checked, setChecked] = useState(answer);

  const handleChange = (e, rowKey, colKey) => {
    const newChecked = { ...checked };
    if (e.target.checked) {
      if (widget.type === 'radio') {
        newChecked[rowKey] = [colKey];
      } else if (!newChecked[rowKey]) {
        newChecked[rowKey] = [colKey];
      } else {
        newChecked[rowKey].push(colKey);
      }
    } else {
      newChecked[rowKey] = checked[rowKey].filter(ch => ch !== colKey);
    }
    setChecked(newChecked);
    onAnswerChange(id, newChecked);
  };

  const verifyChecked = (rowKey, colKey) => {
    if (answer && answer[rowKey] !== undefined && answer[rowKey] !== '') {
      const def = answer[rowKey].find(a => a === colKey);
      if (def) { return true; }
    }
    return false;
  };


  return (
    <div className="table-scroll">
      <table className="matrix-field-table">
        <thead>
          <tr>
            <th />
            {columns.map(c => <th key={c.key}>{c.value}</th>)}
          </tr>
        </thead>
        <tbody>
          {rows.map(r => (
            <tr>
              <th key={r.key}>{r.value}</th>
              {columns.map(c => {
                return(
                <td key={`${r.key}-${c.ley}`}>
                  {widget && widget.type === 'radio'
                    ? (
                      <input type="radio" disabled={readOnly} onChange={e => handleChange(e, r.key, c.key)} name={`${id}-${r.key}`}
                        defaultChecked={(answer && answer[r.key] !== undefined) && answer[r.key][0] === c.key}
                      />
                    )
                    : (
                      <input type="checkbox" disabled={readOnly} defaultChecked={verifyChecked(r.key, c.key)}
                        onChange={e => handleChange(e, r.key, c.key)} name={r.key}
                      />
                    )
                  }
                  {quantities && 
                    <>
                      <div className="info-radio" style={r.key%2==0 ? {backgroundColor : "#ffffff"} :{backgroundColor : "#F4F8F9"}}>
                        <p>{`${quantities[r.key][c.key]} / ${quantities.total}`}</p>
                      </div>
                    </>
                  }
                </td>
              )})}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Matrix.propTypes = {
  id: PropTypes.string.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  answer: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  widget: PropTypes.object
};

Matrix.defaultProps = {
  answer: {},
  widget: null
};

export default Matrix;
