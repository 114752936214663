import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const Table = ({ data, readOnly }) => {
  const getClassName = (estado) => {
    switch (estado) {
      case 'SP':
        return 'badge gray';
      case 'RP':
        return 'badge green';
      default:
        return 'badge orange';
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: '5%' }}>ID</th>
          <th style={{ width: '20%' }}>Cargo/Rol</th>
          <th style={{ width: '20%' }}>Proceso</th>
          <th style={{ width: '10%' }}>Fecha de creacion</th>
          <th style={{ width: '20%' }}>Encuestador</th>
          <th style={{ width: '20%' }}>Responsable</th>
          <th style={{ width: '15%' }}>Estado</th>
          <th style={{ width: '5%' }}>Accion</th>
          <th style={{ width: '5%' }}>Respuestas</th>
          <th style={{ width: '5%' }}>Originales</th>
        </tr>
      </thead>
      <tbody>
        {data.map(d => {
          return(
          <tr>
            <td>{d.id}</td>
            <td>{d.perfil}</td>
            <td>{d.proceso}</td>
            <td>{d.creationDate.slice(0, 10)}</td>
            <td>{d.encuestador}</td>
            <td>{d.responsable}</td>
            <td>
              <span className={getClassName(d.estado.key)}>{d.estado.value}</span>
            </td>
            <td>
              {d.puede_procesar
                ? <Link to={readOnly ? `/ver-respuesta/${d.id}` : `/respuestas/${d.id}`}>Procesar</Link>
                : '-'
              }
            </td>
            <td>
              <Link to={`/ver-respuesta/${d.id}`}>Ver respuesta</Link>
            </td>
            <td>
              {d.original ? <Link to={`/ver-respuesta-original/${d.original}`}>Ver original</Link>: ''}
            </td>
          </tr>
        )})
      }
      </tbody>
    </table>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default Table;
