import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import Field from './Field';


const SectionContainer = SortableContainer(({
  content,
  sec,
  active,
  onSelectField,
  onChangeField,
  onHoverField,
  onRemove,
  setInsideSection,
  setCurrentSec,
  handleMoveField,
  changesLimited,
  form_editable,
  errors
}) => (
  <div className="fields-section-container">
    <div className="fields-in-section">
      <div className="fields">
        {content.map((f, i) => (
          <Field key={`item-${f.id}`} index={i}
            info={f} active={active === f.id} onSelectField={onSelectField}
            onChangeField={onChangeField} onHoverField={onHoverField} onRemove={onRemove}
            insideSection setInsideSection={setInsideSection} sec={sec}
            setCurrentSec={setCurrentSec} handleMoveField={handleMoveField}
            changesLimited={changesLimited} form_editable={form_editable}
            errors={errors}
          />
        ))}
      </div>
    </div>
  </div>
));

export default SectionContainer;
