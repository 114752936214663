import React from 'react';
import PropTypes from 'prop-types';
import Clickable from '../../../../../components/Clickable';

const Select = ({ id, title, onSelectField, values }) => (
  <Clickable onClick={() => onSelectField(id)}>
    {title}
    <ol>
      {values.map(v => <li key={v.key}>{v.value}</li>)}
    </ol>
  </Clickable>
);

Select.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
export default Select;
