import React from 'react';
import PropTypes from 'prop-types';

const Secondarybutton = ({ text, onClick }) => (
  <button className="secondary-btn" type="button" onClick={onClick}>{text}</button>
);

Secondarybutton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
export default Secondarybutton;
