import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import { SortableContainer } from 'react-sortable-hoc';
import { updateSectorForValoration, fetchFullSector, clearActions as ponderacionesClear } from '../Sectors/redux/actions';
import PrimaryButton from '../../components/PrimaryButton';
import Section from './components/Section';
import Spinner from '../../components/Spinner';
import ConfirmModal from '../../components/ConfirmModal';
import Trash from '../../images/trash.svg';


const DummyContainer = SortableContainer(
  ({ level, fields, availableFields, schema, onFieldChange, sectionData, onSectionDataChange,
    removeSection, getFieldsRecursive, setAvailableFields, showErrors }) => (
      <div className="content sortable-container">
        <Section index={0} level={level} fields={fields}
          availableFields={availableFields} onFieldChange={onFieldChange} sectionData={sectionData}
          onSectionDataChange={onSectionDataChange} removeSection={removeSection}
          getFieldsRecursive={getFieldsRecursive} setAvailableFields={setAvailableFields}
          showErrors={showErrors} schema={schema}
        />
      </div>
  )
);


const ValoracionDesign = ({
  match, sector: { schema_valoracion, schema, estado }, status, fetch,
  update, clear, action, saveAction }) => {
  /**
   * permite modificar el árbol de valoración
   */

  // Hooks
  const [fieldsDict, setFieldsDict] = useState({});
  const [availableFields, setAvailableFields] = useState([]);
  const [sections, setSections] = useState([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(-1);
  const [removeSectionIndex, setRemoveSectionIndex] = useState(-1);
  const [shouldPrevent, setShouldPrevent] = useState(false);
  const [total, setTotal] = useState(0);
  const [key, setKey] = useState(1);
  const [showErrors, setShowErrors] = useState(false);
  useEffect(() => {
    clear();
    fetch(match.params.id);
  }, []);

  const getFieldsRecursive = (item) => {
    if (item.type === 'field' && item.field_id !== '' && schema.fields.find(f=>f.id ===  item.field_id)) { return [item.field_id]; }
    if (!item.content || !item.content.length) { return []; }

    const newFields = item.content.reduce(
      (f, subItem) => f.concat(getFieldsRecursive(subItem)), []
    );
    return newFields;
  };

  const updateTotal = (newSections) => {
    const sum = newSections.reduce((acc, item) => acc + item.percentage, 0);
    setTotal(sum);
  };

  const addSection = () => {
    const newSection = {
      id: key,
      type: 'section',
      content: [],
      percentage: 0,
      field_id: '',
      title: `Sección ${sections.length + 1}`
    };
    setSections([...sections, newSection]);
    setCurrentSectionIndex(sections.length);
    setShouldPrevent(true);
    setKey(key + 1);
    setShowErrors(false);
  };

  useEffect(() => {
    if (schema_valoracion && schema_valoracion.length) {
      setCurrentSectionIndex(0);
      setSections(schema_valoracion);
      updateTotal(schema_valoracion);
      let maxId = key;
      schema_valoracion.forEach((elem) => { if (elem.id > maxId) { maxId = elem.id; } });
      setKey(maxId + 1);

      if (availableFields.length) {
        const used = getFieldsRecursive({ type: 'section', content: sections });
        const available = availableFields.filter(id => !(id in used));
        
        setAvailableFields(available);
      }
    } else if (schema_valoracion && !schema_valoracion.length) {
      addSection();
    }
    
  }, [schema_valoracion]);

  useEffect(() => {
    if (schema) {
      const fieldsData = {};
      let available = [];

      // Agrega los fields que valoran al diccionario y a la lista de available
      schema.fields.forEach((elem) => {
        if (elem.rate) {
          fieldsData[elem.id] = { title: elem.profile_text ? elem.profile_text : elem.title, num: '' };
          available.push(elem.id);
        }
      });

      // Agrega a los fields del diccionario su num en el form
      schema.pages.filter(page => page.content).forEach((page, indexPage) => {
        page.content.forEach((pageEntry, pageEntryIndex) => {
          if (pageEntry.startsWith('FIELD') && pageEntry in fieldsData) {
            fieldsData[pageEntry].num = `${indexPage + 1}.${pageEntryIndex}`;
          } else if (pageEntry.startsWith('SEC')) {
            const sec = schema.sections.find(section => section.id === pageEntry);
            if (sec && sec.fields) {
              sec.fields.filter(f => f in fieldsData).forEach((f, fieldIndex) => {
                fieldsData[f].num = `${indexPage + 1}.${pageEntryIndex}.${fieldIndex}`;
              });
            }
          }
        });
      });

      setFieldsDict(fieldsData);

      if (schema_valoracion && schema_valoracion.length) {
        const used = getFieldsRecursive({ type: 'section', content: schema_valoracion });
        available = available.filter(id => !used.includes(id));
      }
      setAvailableFields(available);
    }
  }, [schema]);

  const onChangeSectionContent = (index, section) => {
    const newSections = [...sections];
    newSections[index] = section;
    setSections(newSections);
    setShouldPrevent(true);
    updateTotal(newSections);
    setShowErrors(false);
  };

  const deleteSection = (index) => {
    const newSections = sections.filter((s, sIndex) => sIndex !== index);
    const sectionToDelete = sections.find((s, sIndex) => sIndex === index);
    const newAvailableFields = availableFields.concat(getFieldsRecursive(sectionToDelete));
    
    setSections(newSections);
    setAvailableFields(newAvailableFields);
    setCurrentSectionIndex(0);
    setShouldPrevent(true);
    updateTotal(newSections);
    setShowErrors(false);
  };

  const onFieldChange = (useFieldId, freeFieldId) => {
    let newAvailableFields = availableFields;
    if (freeFieldId && schema['fields'].find(v => v.id === freeFieldId)) { newAvailableFields.push(freeFieldId); }
    if (useFieldId) { newAvailableFields = newAvailableFields.filter(f => f !== useFieldId); }
    setAvailableFields(newAvailableFields);
  };

  const handleConfirmDelete = (confirm) => {
    if (confirm && removeSectionIndex !== -1) {
      deleteSection(removeSectionIndex);
    }
    setRemoveSectionIndex(-1);
  };

  const validateItem = (item) => {
    if (item.percentage < 0 || item.percentage > 100) {
      return false;
    }
    
    if (item.type === 'field') {
      return item.field_id !== '' && schema && schema.fields.find(f=>f.id ===  item.field_id);
    }
    if (item.title === '' || !item.content.length) {
      return false;
    }
    let valid = true;
    let sum = 0;
    for (let i = 0; valid && i < item.content.length; i += 1) {
      valid = validateItem(item.content[i]);
      sum += item.content[i].percentage;
    }
    return valid && sum === 100;
  };

  const onSaveDesign = () => {
    // Valida el esquema
    if (total !== 100 || sections.length === 0) {
      clear();
      setShowErrors(true);
      return;
    }
    let valid = true;
    let index = 0;
    for (index = 0; valid && index < sections.length;) {
      valid = validateItem(sections[index]);
      if (valid) { index += 1; }
    }
    if (estado==='PD' ? !valid || availableFields.length !== 0 : !valid) {
      clear();
      setShowErrors(true);
      setCurrentSectionIndex(index < sections.length ? index : 0);
      return;
    }

    const data = { schema_valoracion: sections };
    update(match.params.id, data);
    setShouldPrevent(false);
  };


  if (action && action.success === false) {
    return (
      <div className="valoraciones-design">
        <div className="page-title-nav">
          <h1>Diseño de esquema de Valoraciones</h1>
          <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
        </div>
        <div className="col-container">
          { action.message ? action.message : 'Ocurrió un error al intentar diseñar el esquema de valoración.'}
        </div>
      </div>
    );
  }
  if (!sections) return null;
  return (
    <div className="valoraciones-design">
      <Prompt
        when={shouldPrevent}
        message="Seguro que quieres salir? se perderán todos los cambios sin guardar"
      />
      <div className="page-title-nav">
        <h1>Diseño de esquema de Valoraciones</h1>
        <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
      </div>

      <div className="col-container">
        <div className="col-left">
          <h2 className="secciones-header">
            <span>Secciones</span>
            <span className={showErrors && total !== 100 ? 'valoracion-error' : ''}>{`Total: ${total}%`}</span>
          </h2>
          {sections.map((s, index) => (
            <div key={`section-h-${s.id}`} className={`single-page style-pages ${currentSectionIndex === index ? 'active' : ''}`}>
              <button className="nav-pages" type="button" onClick={() => setCurrentSectionIndex(index)}>
                {s.title}
              </button>
              { sections.length > 1 && (
                <button type="button" className="delete-page" onClick={() => setRemoveSectionIndex(index)}>
                  <img src={Trash} alt="" width="18px" />
                </button>
              )}
            </div>
          ))}
          <button type="button" className="add-page" onClick={addSection}>+ Añadir Sección</button>
        </div>
        {sections.length && currentSectionIndex !== -1 && (
          <DummyContainer key={`section-${sections[currentSectionIndex].id}`} level={1}
            fields={fieldsDict} availableFields={availableFields} useDragHandle
            onFieldChange={onFieldChange}
            sectionData={sections[currentSectionIndex]}
            onSectionDataChange={data => onChangeSectionContent(currentSectionIndex, data)}
            removeSection={() => setRemoveSectionIndex(currentSectionIndex)}
            getFieldsRecursive={getFieldsRecursive}
            setAvailableFields={setAvailableFields}
            showErrors={showErrors}
            schema={schema}
          />
        )}
        <div className="col-right" />
      </div>

      <div className="col-container save">
        <div className="content align-right">
          <Spinner active={saveAction.loading} />
          {showErrors && (
            <span className="feedback-error">
              { availableFields.length !== 0 ? 'Se deben agregar todos los campos disponibles.' : 'El esquema contiene errores.'}
            </span>
          )
        }
          {saveAction.error && <span className="feedback-error">{saveAction.message}</span> }
          {saveAction.success && <span className="feedback-success">Guardado con éxito</span> }
          <PrimaryButton text="Guardar" onClick={onSaveDesign} />
        </div>
        <div className="col-right" />
      </div>

      <ConfirmModal active={removeSectionIndex !== -1} title="Borrar Sección" content="Está seguro que desea eliminar la sección?"
        onConfirm={() => handleConfirmDelete(true)} onCancel={() => handleConfirmDelete(false)}
      />
    </div>
  );
};


ValoracionDesign.defaultProps = {
  sector: {}
};

const mapStateToProps = state => ({
  sector: state.sectores.sector,
  saveAction: state.sectores.saveAction
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(fetchFullSector(id)),
  clear: () => dispatch(ponderacionesClear()),
  update: (id, data) => dispatch(updateSectorForValoration(id, data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValoracionDesign);
