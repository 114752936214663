import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/* ****************************************************** */
/* Components import */
/* ***************** */
import Footer from '../components/Footer';
import MainContainer from '../components/MainContainer';
import Header from '../components/Header';
import SectorFlow from './SectorFlow/SectorFlow';
import List from './Sectors/List/SectorList';
import ValidationForm from './Validation/ValidationForm';
import AnswersList from './Answers/List/AnswersList';
import Profiles from './Profiles/List/ProfilesList';
import FormBuilder from './FormBuilder/FormBuilder';
import AnswerForm from './Answers/AnswerForm';
import Wizard from './Profiles/Wizard/Wizard';
import ProfileBuilder from './Profiles/Builder/ProfileBuilder';
import ValoracionList from './Valoracion/List/ValoracionList';
import ValoracionDetail from './Valoracion/Detail/ValoracionDetail';
import SectorDescription from './Sectors/Description/SectorDescription';
import EstructuraOcupacional from './EstructuraOcupacional/EstructuraOcupacional';
import ValoracionDesign from './ValoracionDesign/ValoracionDesign';
import ValoracionScore from './ValoracionScore/ValoracionScore';
import GlosaryFrameForm from './GlosaryFrame/GlosaryFrameForm'

const MainLayout = () => (
  <>
    <Header />
    <MainContainer>
      <Route exact path="/" render={() => <Redirect to="/sectores" />} />
      <Route exact path="/sectores" component={List} />
      <Route exact path="/sectores/:id" component={SectorFlow} />
      <Route exact path="/sectores/:id/descripcion" component={SectorDescription} />
      <Route exact path="/sectores/:id/validacion" render={props => <ValidationForm {...props} type="formulario" />} />
      <Route exact path="/sectores/:id/glosario" render={props => <GlosaryFrameForm {...props} type="glosario" />} />
      <Route exact path="/sectores/:id/marco" render={props => <GlosaryFrameForm {...props} type="marco" />} />
      <Route exact path="/sectores/:id/respuestas" component={AnswersList} />
      <Route exact path="/sectores/:id/respuestas/prueba" render={props => <AnswersList {...props} prueba />} />
      <Route exact path="/sectores/:id/perfiles" component={Profiles} />
      <Route exact path="/sectores/:id/construccion" component={FormBuilder} />
      <Route exact path="/sectores/:id/imprimir" render={props => <AnswerForm {...props} print />} />
      <Route exact path="/sectores/:id/responder" component={AnswerForm} />
      <Route exact path="/sectores/:id/responder/:uuid" component={AnswerForm} />
      <Route exact path="/respuestas/:answerId" render={props => <AnswerForm {...props} revisar />} />
      <Route exact path="/ver-respuesta/:answerId" render={props => <AnswerForm {...props} wizard />} />
      <Route exact path="/ver-respuesta-original/:answerId" render={props => <AnswerForm {...props} wizard original/>} />
      <Route exact path="/r/:encoded/:uuid" component={AnswerForm} />
      <Route exact path="/perfiles/:profileId" component={ProfileBuilder} />
      <Route exact path="/perfiles/:profileId/wizard" component={Wizard} />
      
      <Route exact path="/perfiles/:profileId/validacion" render={props => <ValidationForm {...props} type="perfil" />} />
      <Route exact path="/sectores/:id/esquema_valoracion" component={ValoracionDesign} />
      <Route exact path="/sectores/:id/valoracion" component={ValoracionScore} />
      <Route exact path="/sectores/:id/valoraciones" component={ValoracionList} />
      <Route exact path="/sectores/:id/estructura_ocupacional" component={EstructuraOcupacional} />
      <Route exact path="/valoraciones/:id" component={ValoracionDetail} />

    </MainContainer>
    <Footer />
  </>
);

export default MainLayout;
