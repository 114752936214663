import {
  FETCH_LIST,
  CLEAR_ACTIONS
} from './types';

const INITIAL_STATE = {
  valoracion: undefined,
  action: {
    success: false,
    loading: false,
    message: ''
  }
  /* initialize state */
};

export const actionForState = (action, state, message) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: message || state
});

const getRelevantSchema = (rawschema, property) => {
  const schema = { ...rawschema };
  const newFields = schema.fields.filter(f => f[property]);

  const relevantFieldsID = newFields.map(f => f.id);
  const newSections = [];
  schema.sections.forEach((s) => {
    const newSection = { ...s };
    newSection.fields = s.fields.filter(f => relevantFieldsID.includes(f));
    // If the section has not relevant fields, we must to delete it.
    if (newSection.fields.length !== 0) newSections.push(newSection);
  });

  const relevantSectionsID = schema.sections.map(f => f.id);
  const newPages = [];
  schema.pages.forEach((p) => {
    const newPage = { ...p };
    newPage.content = p.content.filter(f => (
      relevantFieldsID.concat(relevantSectionsID)).includes(f));
    // If the page has not relevant content, we must to delete it.
    if (newPage.content.length !== 0) newPages.push(newPage);
  });

  return { ...schema, fields: newFields, sections: newSections, pages: newPages };
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*          ACTION STATUS           */
    /* ******************************** */
    case FETCH_LIST.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_LIST.success: {
      const schema = getRelevantSchema({ ...action.payload.schema }, 'rate');
      const profileSchema = getRelevantSchema({ ...action.payload.schema }, 'profile');
      return {
        ...state,
        valoracion: { ...action.payload, schema, profileSchema },
        action: actionForState(state.action, 'success')
      };
    }

    case FETCH_LIST.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case CLEAR_ACTIONS:
      return INITIAL_STATE;

    default:
      return state;
  }
}
