import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fetchProfile, updateProfile, clearActions, printProfileDescription } from '../redux/actions';
import AnswerField from '../../../pulpoforms/answer/AnswerField';
import AnswerSection from '../../Answers/components/AnswerSection';
import PrimaryButton from '../../../components/PrimaryButton';
import Spinner from '../../../components/Spinner';
import ConfirmationScreen from '../../../components/ConfirmationScreen';

import Matrix from '../Wizard/components/Matrix';
import MultiSelect from '../Wizard/components/MultiSelect';


const isSection = sectionID => sectionID.includes('SEC');

const ProfileBuilder = ({
  match: { params: { profileId } },
  profile, updateStatus,
  fetch, update, clear, print }) => {
  // hooks
  const [page, setPage] = useState();
  const [answers, setAnswers] = useState({});
  const [generalInfo, setGeneralInfo] = useState('');
  const [relevantPages, setRelevantPages] = useState([]);
  const [errors, setErrors] = useState({});
  const [confirmation, setConfirmation] = useState(false);

  /**
   * Trae el perfil
   */
  useEffect(() => {
    fetch(profileId);

    return () => { clear(); };
  }, []);


  useEffect(() => {
    if(updateStatus.success) {
      if (pageIndex !== relevantPages.length) {
        setPage(relevantPages[pageIndex]);
      } else {
        setConfirmation(true);
      }
    };
  },[updateStatus])

  /**
   * fija la currentPage y respuestas
   */
  useEffect(() => {
    if (profile.schema && !page) {
      const newRelevantPages = [];

      profile.schema.pages.forEach((p, i) => {
        if (getPageFields(p).length > 0) {
          newRelevantPages.push({ ...p, index: i + 1 });
        }
      });
      setRelevantPages(newRelevantPages);
      setPage(newRelevantPages[0]);

      if (profile.perfil) {
        setAnswers(profile.perfil);
      } else {
        const ans = {};
        profile.schema.fields.forEach((f) => {
          if (f.profile || f.rate) { ans[f.id] = ''; }
        });
        setAnswers(ans);
      }
      setGeneralInfo(profile.general_info);
    }
  }, [profile]);

  /**
   * maneja los cambios de los valores en la respuesta.
   */
  const handleAnswerChange = (id, value) => {
    setAnswers({ ...answers, [id]: value });
    const newErrors = { ...errors };
    delete newErrors[id];
    setErrors(newErrors);
  };


  const getPageFields = (p) => {
    const fields = [];

    p.content.forEach((c) => {
      const collection = isSection(c) ? 'sections' : 'fields';
      const item = profile.schema[collection].find(e => e.id === c);
      if (collection === 'fields') {
        if (!(!item.profile && !item.rate && item.hidden)) fields.push(item);
      } else {
        item.fields.forEach((f) => {
          const item2 = profile.schema.fields.find(e => e.id === f);
          if (!(!item2.profile && !item2.rate && item2.hidden)) fields.push(item2);
        });
      }
    });
    return fields;
  };

  const pageIndex = page && relevantPages.findIndex(p => p.id === page.id) + 1;

  const handlePrint = () => {
    print(profileId);
  };

  /**
   * maneja las acciones de cambio en la página
   * valida si la respuesta es valida.
   */
  const handlePageChange = () => {
    if (!updateStatus.loading) {
      const data = {
        perfil: answers,
        general_info: generalInfo
      };
      update(profileId, data);
    }
  };

  const handleAnswerChangeSelectOthers = (id, value) => {
    setAnswers({ ...answers, [id]: "otros - " + value });
    const newErrors = { ...errors };
    delete newErrors[id];
    setErrors(newErrors);
  };

  const handleOthersMultiSelect = (id, value) => {
    let multiSelect = answers[id].filter(v => !v.includes('otros - '))
    multiSelect.push('otros - ' + value)
    setAnswers({ ...answers, [id]: multiSelect });
    const newErrors = { ...errors };
    delete newErrors[id];
    setErrors(newErrors);
  };

  if (confirmation) {
    return (
      <ConfirmationScreen title={`Cargo/Rol - ${profile.sector && profile.sector.nombre}`}
        content="El cargo/rol fue guardado con éxito"
        link={{ url: `/sectores/${profile.sector.id}/perfiles`, text: 'Volver al listado de cargos/roles' }}
      />
    );
  }

  /**
   * formatea la respuesta de acuerdo a su tipo
   */
  const formatAnswer = (field, a) => {
    switch (field.type) {
      case 'date':
        if (moment(a).format('L') === 'Invalid date') {
          return '';
        }
        return moment(a).format('L');
      case 'datetime':
        return moment(a).format('L LT');
      case 'select': {
        const fi = field.values.find(f => f.key === a);
        return fi ? fi.value : '';
      }
      case 'multiselect':
        if (typeof (a) === 'object') {
          return <MultiSelect answer={a} values={field.values} />;
        }
        return '';
      case 'matrix':
        if (typeof (a) === 'object') {
          return <Matrix answer={a} rows={field.rows} columns={field.columns} />;
        }
        return '';
      default:
        return a;
    }
  };

  // todas las respuestas en un arreglo (orden incorrecto)
  const answersArray = Object.keys(answers);

  // todos los campos en las páginas en orden correcto 
  const pagesFields = profile.schema && profile.schema.pages.reduce((acc, p) => {
    const content = [...p.content];
    return (
      [...acc, content]
    );
  }, []).flat();

  const fieldsInOrder = pagesFields && pagesFields.reduce((acc, f) => {
    if (answersArray.find(a => a === f)) {
      return [...acc, f];
    }
    return acc;
  }, []);

  if (!profile || Object.keys(profile).length === 0) return null;
  return (
    <div key={`profile-${profileId}`}>
      <Prompt
        when={updateStatus.loading}
        message="Hay cambios pendientes de guardar. Seguro que quieres salir?"
      />
      <div className="profile-print">
        <h1>
        Cargo/Rol
          {profile && <small>{` - ${profile.nombre}`}</small>}
        </h1>
        <div className="answer-entry">
          <p>{profile.sector && profile.sector.descripcion}</p>
        </div>
        <div className="answer-entry">
          <p>{profile.general_info}</p>
        </div>
        {fieldsInOrder && fieldsInOrder.map((k, i) => {
          const field = profile.schema && profile.schema.fields.find(f => f.id === k);
          return (
            <div key={field.id} className="answer-entry">
              <i>{field.profile_text || field.title}</i>
              <p>{formatAnswer(field, answers[k])}</p>
            </div>
          );
        })}
      </div>
      <div className="answer-form profile">
        {profile && (
        <div className="page-title-nav">
          <h1>
            Construcción Cargo/Rol
            <small>{` - ${profile.nombre}`}</small>
          </h1>
          {profile.sector && <Link to={`/sectores/${profile.sector.id}/`}>Volver al sector</Link>}
        </div>
        )}
        <div className="wizard-btn">
          <Link to={`/perfiles/${profileId}/wizard`} className="primary-btn">Wizard</Link>
          <Link to={`/perfiles/${profileId}/imprimir`} onClick={handlePrint} className="primary-btn">Imprimir</Link>
        </div>
        <div className="col-container">
          <div className="content">
            {page && (page.index === relevantPages[0].index) && (
              <label className="general-info">
                Información general del cargo/rol
                <textarea name="" id="" cols="30" rows="5" defaultValue={profile.general_info} onChange={e => setGeneralInfo(e.target.value)} />
              </label>
            )}

            <h3>{`${page && page.index} - ${page && page.title}`}</h3>
            {page && getPageFields(page).map((c, idx) => (
              (
                isSection(c.id)
                  ? (
                    <AnswerSection key={c.id} sec={c} fields={profile.schema.fields}
                      index={`${page.index}.${idx + 1}`}
                      onAnswerChange={handleAnswerChange}
                      errors={errors} answers={answers} renderProfileText puedeEncuestar
                      onOthersCHange={handleAnswerChangeSelectOthers}
                      onOthersCHangeMulti={handleOthersMultiSelect}
                    />
                  )
                  : (
                    <AnswerField {...c} key={c.id} errors={errors[c.id]} index={`${page.index}.${idx + 1}`}
                      answer={answers[c.id]} onAnswerChange={handleAnswerChange}
                      satisfyConditional renderProfileText puedeEncuestar
                      onOthersCHange={handleAnswerChangeSelectOthers}
                      onOthersCHangeMulti={handleOthersMultiSelect}
                    />
                  )
              )
            ))}
          </div>
        </div>
        <div className="col-container save">
          <div className="content">
            <Spinner active={updateStatus.loading} />
            {updateStatus.error && <span className="feedback-error">{updateStatus.message}</span> }
            {profile.schema && (
            <PrimaryButton
              text={pageIndex === relevantPages.length ? 'Guardar Perfil' : 'Guardar y seguir'}
              onClick={handlePageChange}
            />
            )}
          </div>
          <div className="col-right" />
        </div>
      </div>
    </div>
  );
};

ProfileBuilder.propTypes = {
  match: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired
};


const mapStateToProps = state => ({
  profile: state.profiles.profile,
  updateStatus: state.profiles.updateStatus
});

const mapDispatchToProps = dispatch => ({
  fetch: profileId => dispatch(fetchProfile(profileId)),
  update: (profileId, data) => dispatch(updateProfile(profileId, data)),
  clear: () => dispatch(clearActions()),
  print: profileId => dispatch(printProfileDescription(profileId))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileBuilder);
