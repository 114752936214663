import React, { useEffect, useReducer, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from './components/Table';
import Paginator from '../../../components/paginator';
import PrimaryButton from '../../../components/PrimaryButton';
import Secondarybutton from '../../../components/SecondaryButton';
import { fetchListPage, fetchPruebaAnswers, clearDelayedAnswer } from '../redux/actions';
// import { fetchList } from '../../Profiles/redux/actions';
import { fetchSector } from '../../Sectors/redux/actions';


const reducer = (state, action) => {
  if (action.type === 'reset') {
    return { page: '1', estado: undefined, perfil: undefined, proceso: undefined, encuestador: undefined };
  }
  if (action.type === 'all') {
    return action.payload;
  }
  return {
    ...state,
    [action.type]: action.payload
  };
};

const AnswersList = ({
  match,
  listAnswers,
  listPruebaAnswers,
  getSector,
  clearDelayed,
  sector,
  answers,
  oldAnswer,
  maxCount,
  prueba,
  originales,
  listOriginals
}) => {
  const [params, dispatch] = useReducer(
    reducer,
    { page: 1, estado: undefined, perfil: undefined, proceso: undefined, encuestador: undefined, tipo_orden: 'creación', orden: 'DE' }
  );

  

  const fetch = prueba ? listPruebaAnswers : (originales ? listOriginals : listAnswers);
  useEffect(() => {
    getSector(match.params.id);
  }, []);

  useEffect(() => {
    fetch(match.params.id, params);
  }, [params.page]);

  useEffect(() => {
    if (oldAnswer){
      clearDelayed();
    }
  }, [oldAnswer && oldAnswer.id]);

  const handleFilterOrder = () => {
    const newParams = { ...params, page: '1' };
    fetch(
      match.params.id,
      newParams
    );
    dispatch({ type: 'all', payload: newParams });
  };

  const handleClearFilters = () => {
    dispatch({ type: 'reset' });
    fetch(
      match.params.id,
      { page: '1', estado: undefined, perfil: undefined, proceso: undefined, encuestador: undefined, tipo_orden: 'creación', orden: 'DE' }
    );
  };

  const handleOrder = () => {
    const newParams = { ...params, page: '1' };
    fetch(
      match.params.id,
      newParams
    );
    dispatch({ type: 'all', payload: newParams });
  };

  

  if (!answers) return null;

  return (
    <>
      <div className="page-title-nav">
      <h1>Respuestas{originales && " originales"}</h1>
        <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
      </div>
      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">Sector</span>
            {sector && sector.nombre}
          </div>
        </div>
        <div className="content">
          <div className="filters">
            <div className="filter-inputs">
              <div className="filter-pair">
                <input type="Perfil" placeholder="Filtar por cargo/rol" value={params.perfil || ''}
                  onChange={e => dispatch({ type: 'perfil', payload: e.target.value })}
                />
                <input type="Proceso" placeholder="Filtar por proceso" value={params.proceso || ''}
                  onChange={e => dispatch({ type: 'proceso', payload: e.target.value })}
                />
              </div>
              <div className="filter-pair">
                <input type="Encuestador" placeholder="Filtar por encuestador" value={params.encuestador || ''}
                  onChange={e => dispatch({ type: 'encuestador', payload: e.target.value })}
                />
                {!prueba && (
                <select name="" id="" onChange={e => dispatch({ type: 'estado', payload: e.target.value })} value={params.estado || ''}>
                  <option value="" selected disabled>Filtrar por estado</option>
                  <option value="SP">Sin Procesar</option>
                  <option value="RP">Respuesta Procesada</option>
                </select>
                )}
              </div>
              <div className="filter-pair">
                <select name="" id="" onChange={e => dispatch({ type: 'tipo_orden', payload: e.target.value })} value={params.tipo_orden || ''}>
                  <option selected value="creación">Ordenar por Fecha de Creación</option>
                  <option value="id">Ordenar por ID</option>
                  <option value="ingreso">Ordenar por Fecha de Ingreso</option>
                  <option value="cargo">Ordenar por Cargo</option>
                </select>
                <select name="" id="" onChange={e => dispatch({ type: 'orden', payload: e.target.value })} value={params.orden || ''}>
                  <option selected value="DE">Orden Descendente</option>
                  <option value="AS">Orden Ascendente</option>
                </select>
              </div>
            </div>
            
            <div className="filter-buttons">
              <PrimaryButton text="Aplicar" onClick={handleFilterOrder} />
              <Secondarybutton text="Borrar filtros" onClick={handleClearFilters} />
            </div>
          </div>
          <Table originales={originales} data={answers} readOnly={prueba} />
          <Paginator page={parseInt(params.page)} max={maxCount} onChange={e => dispatch({ type: 'page', payload: e })} />
        </div>
      </div>
    </>
  );
};


const mapStateToProps = state => ({
  sector: state.sectores.sector,
  answers: state.answers.list,
  oldAnswer: state.answers.answer,
  maxCount: state.answers.count
});

const mapDispatchToProps = dispatch => ({
  listAnswers: (id, page) => dispatch(fetchListPage(id, page)),
  listPruebaAnswers: (id, params) => dispatch(fetchPruebaAnswers(id, params)),
  getSector: id => dispatch(fetchSector(id)),
  clearDelayed: () => dispatch(clearDelayedAnswer())

});


export default connect(mapStateToProps, mapDispatchToProps)(AnswersList);
