const asyncActionType = type => ({
  pending: `${type}_PENDING`,
  success: `${type}_SUCCESS`,
  error: `${type}_ERROR`
});

export const FETCH_ANSWERS = asyncActionType('cetfor/answers/FETCH');
export const SAVE_ANSWER = asyncActionType('cetfor/answers/SAVE');
export const CLEAR_ACTIONS = 'cetfor/answers/CLEAR_ACTIONS';
export const CLEAR_DELAYED_ANSWER = 'cetfor/answers/CLEAR_DELAYED_ANSWER';


export const FETCH_LIST_ANSWERS = asyncActionType('cetfor/answers/FETCH_ANSWERS');
export const FETCH_ANSWER = asyncActionType('cetfor/answer/FETCH_ANSWER');
export const UPDATE_ANSWER = asyncActionType('cetfor/answers/UPDATE_ANSWER');
