import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import { updateSectorForValoration, fetchFullSector, clearActions as ponderacionesClear } from '../Sectors/redux/actions';
import PrimaryButton from '../../components/PrimaryButton';
import ValoracionField from './components/ValoracionField';
import ValoracionSection from './components/ValoracionSection';
import Spinner from '../../components/Spinner';


const ValoracionScore = ({
  match, sector: { schema_valoracion, schema, nombre, responsable, score_valoracion },
  fetch, update, clear, action, saveAction }) => {
  // Hooks
  const [fieldsDict, setFieldsDict] = useState({});
  const [sections, setSections] = useState([]);
  const [shouldPrevent, setShouldPrevent] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [valoracionFields, setValoracionFields] = useState({});

  useEffect(() => {
    clear();
    fetch(match.params.id);
  }, []);

  useEffect(() => {
    if (schema_valoracion && schema_valoracion.length) {
      setSections(schema_valoracion);
    }
  }, [schema_valoracion]);

  useEffect(() => {
    if (schema) {
      const fieldsData = {};

      // Agrega los fields que valoran al diccionario
      schema.fields.forEach((elem) => {
        if (elem.rate) { fieldsData[elem.id] = { title: elem.title, num: '' }; }
      });

      // Agrega a los fields del diccionario su num en el form
      schema.pages.filter(page => page.content).forEach((page, indexPage) => {
        page.content.forEach((pageEntry, pageEntryIndex) => {
          if (pageEntry.startsWith('FIELD') && pageEntry in fieldsData) {
            fieldsData[pageEntry].num = `${indexPage + 1}.${pageEntryIndex}`;
          } else if (pageEntry.startsWith('SEC')) {
            const sec = schema.sections.find(section => section.id === pageEntry);
            if (sec && sec.fields) {
              sec.fields.filter(f => f in fieldsData).forEach((f, fieldIndex) => {
                fieldsData[f].num = `${indexPage + 1}.${pageEntryIndex}.${fieldIndex}`;
              });
            }
          }
        });
      });

      // Agrega las valoraciones de cada campo (inicializadas en 0)
      const newValoracionFields = {};
      schema.fields.filter(field => field.rate).forEach((field) => {
        newValoracionFields[field.id] = { method: '' };
        if (field.values) {
          field.values.forEach((v) => { newValoracionFields[field.id][v.key] = ""; });
        }
        if (field.rows && field.columns) {
          field.rows.forEach((row) => {
            field.columns.forEach((col) => {
              newValoracionFields[field.id][`${row.key}-${col.key}`] = '';
            });
          });
        }
      });

      // En caso de tener datos de valoracion los completa
      if (score_valoracion) {
        Object.keys(score_valoracion).forEach((k) => {
          if (newValoracionFields[k]) {newValoracionFields[k] = { ...newValoracionFields[k], ...score_valoracion[k] }}
        });
      }
      setFieldsDict(fieldsData);
      setValoracionFields(newValoracionFields);
    }
  }, [schema]);

  const handleChange = (id, key, value) => {
    setValoracionFields({
      ...valoracionFields,
      [id]: { ...valoracionFields[id], [key]: value }
    });
    setShouldPrevent(true);
    setShowErrors(false);
  };

  const validateField = (fieldVal, fieldForm) => {
    // Que tenga un metodo de calculo seleccionado
    if (!fieldVal.method || fieldVal.method === '') { return [false, 'Debe seleccionar el modo de cálculo.']; }

    // Que todos los valores se encuentren entre 0 y 1000
    const inputs = Object.entries(fieldVal).filter(item => item[0] !== 'method');
    let valid = true;
    for (let i = 0; valid && i < inputs.length; i += 1) {
      valid = parseInt(inputs[i][1], 10) >= 0 && parseInt(inputs[i][1], 10) <= 1000;
    }
    if (!valid) { return [false, 'Los puntajes se deben encontrar en el rango 0 a 1000.']; }

    const esSum = field => field.method === 'SUM';
    const esAvg = field => field.method === 'PROM';
    const esSelectMul = field => field.type === 'multiselect';
    const esMatriz = field => field.type === 'matrix' && field.widget.type === 'radio';
    const esMatrizMul = field => field.type === 'matrix' && field.widget.type !== 'radio';

    // Matriz de decision
    //     | SELECT | SELECT MUL | MATRIZ S | MATRIZ MUL
    // SUM |      1 |          2 |        3 |         2
    // MAX |      1 |          1 |        1 |         1
    // AVG |      1 |          1 |        4 |         1

    if (esAvg(fieldVal) && esMatriz(fieldForm)) {
      // 4) Chequear que el maximo de cada fila es 1000
      let rowsValid = true;
      fieldForm.rows.forEach((row) => {
        let max = 0;
        fieldForm.columns.forEach((col) => {
          const id = `${row.key}-${col.key}`;
          if (fieldVal[id] > max) { max = fieldVal[id]; }
        });
        rowsValid = rowsValid && max === 1000;
      });
      if (!rowsValid) { return [false, 'El máximo puntaje de cada fila debe ser igual a 1000.']; }
    } else if (esSum(fieldVal) && esMatriz(fieldForm)) {
      // 3) Chequear que los maximos de cada fila suman 1000
      let maxSum = 0;
      fieldForm.rows.forEach((row) => {
        let max = 0;
        fieldForm.columns.forEach((col) => {
          const id = `${row.key}-${col.key}`;
          if (fieldVal[id] > max) { max = fieldVal[id]; }
        });
        maxSum += max;
      });
      if (maxSum !== 1000) { return [false, 'Los puntajes máximos de todas las filas deben sumar 1000.']; }
    } else if (esSum(fieldVal) && (esSelectMul(fieldForm) || esMatrizMul(fieldForm))) {
      // 2) Chequear que todas las opciones suman 1000
      const sum = inputs.reduce((tot, item) => tot + item[1], 0);
      if (sum !== 1000) { return [false, 'La suma de todas las opciones debe ser igual a 1000.']; }
    } else {
      // 1) Chequear que la max opt es 1000
      const max = inputs.reduce((curr, item) => {
        if (item[1] > curr) {
          return item[1];
        }
        return curr;
      }, 0);
      if (max !== 1000) { return [false, 'El puntaje máximo de las opciones debe ser igual a 1000.']; }
    }

    return [true, ''];
  };

  const onSaveScore = () => {
    // Valida todos los campos
    let valid = true;
    const fields = Object.entries(valoracionFields);
    for (let i = 0; valid && i < fields.length; i += 1) {
      const fieldForm = schema.fields.find(field => field.id === fields[i][0]);
      valid = valid && validateField(fields[i][1], fieldForm)[0];
    }

    if (!valid) {
      clear();
      setShowErrors(true);
      return;
    }

    const data = { score_valoracion: valoracionFields };
    update(match.params.id, data);
    setShouldPrevent(false);
  };

  if (!schema) { return null; }
  return (
    <div>
      <Prompt
        when={shouldPrevent}
        message="Seguro que quieres salir? se perderán todos los cambios sin guardar"
      />
      <div className="page-title-nav">
        <h1>Valoracion</h1>
        <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
      </div>

      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">Sector</span>
            {nombre}
          </div>
          <div className="info">
            <span className="label">Responsables INEFOP</span>
            {responsable.map(a => <div>{a}</div>)}
          </div>
        </div>

        <div className="content m-b-16">
          { sections.length && sections.map((section, index) => {
            return(
            <ValoracionSection key={`sec-${section.id}`} level={1} number={`${index + 1}`} data={section} valoraciones={valoracionFields}
              fields={schema.fields} fieldsDict={fieldsDict} handleChange={handleChange}
              showErrors={showErrors} validateField={validateField}
            />
          )})}
          { !sections.length && Object.entries(fieldsDict).map(([key, value]) => (
            <ValoracionField key={`field-${value.id}`} fields={schema.fields} fieldId={key} fieldInfo={value}
              valoracion={valoracionFields[key]} handleChange={handleChange} showErrors={showErrors}
              validateField={validateField}
            />
          ))}
        </div>

        <div className="col-right" />
      </div>

      <div className="col-container save">
        <div className="content align-right">
          <Spinner active={saveAction.loading} />
          {showErrors && <span className="feedback-error">La valoración contiene errores.</span> }
          {saveAction.error && <span className="feedback-error">{saveAction.message}</span> }
          {saveAction.success && <span className="feedback-success">Guardado con éxito</span> }
          <PrimaryButton text="Guardar" onClick={onSaveScore} />
        </div>
        <div className="col-right" />
      </div>
    </div>
  );
};


ValoracionScore.defaultProps = {
  sector: {}
};

const mapStateToProps = state => ({
  sector: state.sectores.sector,
  saveAction: state.sectores.saveAction
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(fetchFullSector(id)),
  clear: () => dispatch(ponderacionesClear()),
  update: (id, data) => dispatch(updateSectorForValoration(id, data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValoracionScore);
