import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Link, Prompt } from 'react-router-dom';
import { arrayMove } from 'react-sortable-hoc';
import { fetchForm, updateForm, setSchemaFields, clearFlags } from './redux/actions';

/* *************************************************** */
/* Component import */
/* **************** */
import PrimaryButton from '../../components/PrimaryButton';
import Page from './components/Page';
import Spinner from '../../components/Spinner';
import useModal from '../../hooks/useModal';
import ConfirmModal from '../../components/ConfirmModal';
import Trash from '../../images/trash.svg';
import { updateSectorForValoration, fetchFullSector, clearActions as ponderacionesClear } from '../Sectors/redux/actions';


const FormBuilder = ({
  match, updateFields, schema, saveStatus, form_editable, status,
  fetch, update, clear, action, validated, updateValoracion}) => {
  
  /**
   * comopnente que permite la
   * modificacion del formulario
   */

  // Hooks
  const [fields, setFields] = useState(undefined);
  const [pages, setPages] = useState(undefined);
  const [sections, setSections] = useState(undefined);

  const [currentPageID, setCurrentPageID] = useState(-1);
  const [maxPageID, setMaxPageID] = useState(-1);
  const [active, toggleModal] = useModal();
  const [moveActive, toggleMoveModal] = useModal();
  const [removePage, setRemovePage] = useState(-1);

  const [fieldToMove, setFieldToMove] = useState(-1);
  const [moveTo, setMoveTo] = useState(-1);
  const [shouldPrevent, setShouldPrevent] = useState(false);

  // If === '' then we can't nav trough pages
  const [currentSectionTitle, setCurrentSectionTitle] = useState(' ');
  useEffect(() => {
    fetch(match.params.id, { a: 'modify' });
    
    // clear thet status when unmounting component
    return (() => { clear();});
  }, []);

  useEffect(() => {
    if (schema) {
      const max = Math.max(...schema.pages.map(f => parseInt(f.id.substring(4), 10)));
      setMaxPageID(max);
      setCurrentPageID(schema.pages[0].id);
      updateFields(schema.fields);
      setFields(schema.fields);
      setPages(schema.pages);
      setSections(schema.sections);
    }
  }, [schema]);

  /**
   * Store in redux the fields available for conditionals
   * */
  useEffect(() => {
    if (fields) {
      updateFields(fields.filter(
        f => !['date', 'datetime'].includes(f.type)
      ));
    }
  }, [fields]);

  /**
   * limpiamos las action flags
   * cuando la operación fue un exito
   * */
  useEffect(() => {
    if (saveStatus.success) {
      setTimeout(() => { clear(); }, 3000);
    }
  }, [saveStatus.success]);

  /**
   * Handler para cambiar la página
   * */
  const onClickPage = (clickedPageId) => {
    const currentPage = pages.find(p => p.id === currentPageID);
    if (currentPage.title && currentSectionTitle) {
      const newPage = pages.find(p => p.id === clickedPageId);
      setCurrentPageID(newPage.id);
    }
  };


  const addPage = () => {
    const currentPage = pages.find(p => p.id === currentPageID);
    if (currentPage.title && currentSectionTitle) {
      const newMaxID = maxPageID + 1;
      const newPageSchema = {
        id: `PAGE${newMaxID}`,
        title: `Módulo ${newMaxID}`,
        content: []
      };
      setMaxPageID(newMaxID);
      setPages([...pages, newPageSchema]);
      setCurrentPageID(newPageSchema.id);
      setShouldPrevent(true);
    }
  };

  const onSaveForm = () => {
    const newSchema = { ...schema };
    newSchema.pages = pages;
    newSchema.fields = fields.map((f) => {
      const { isNew, ...rest } = f;
      if (rest.values) {
        rest.values.forEach((v, i) => {
          delete rest.values[i].isNew;
        });
      }
      if (rest.type !== 'matrix' && rest.type !== 'select'
          && rest.type !== 'multiselect') {
        rest.rate = false;
      }
      return rest;
    });
    newSchema.sections = sections;
    newSchema.version = schema.version;
    update(match.params.id, newSchema);
    setShouldPrevent(false);
    
    // updateSector(match.params.id, newSector);
    // change state to CF
  };

  const onChangePageContent = (page) => {
    const index = pages.findIndex(p => p.id === page.id);
    const newPages = [...pages];
    newPages[index].title = page.title;
    newPages[index].content = page.content;
    setPages([...newPages]);
  };

  const deletePage = (pageId) => {
    // borrar pageId de las páginas
    const newPages = pages.filter(p => p.id !== pageId);
    const pageToDelete = pages.find(p => p.id === pageId);
    let newFields = [...fields];
    let newSections = [...sections];
    pageToDelete.content.forEach((c) => {
      if (!c.includes('SEC')) {
        // borrar pageId fields, de los campos
        newFields = newFields.filter(f => f.id !== c);
      } else {
        // borrar pageId sections, de las secciones (y campos)
        const sectionToDelete = newSections.find(s => s.id === c);
        sectionToDelete.fields.forEach((secf) => {
          newFields = newFields.filter(f => f.id !== secf);
        });
        newSections = newSections.filter(s => s.id !== sectionToDelete.id);
      }
    });
    setSections(newSections);
    setFields(newFields);
    setPages([...newPages]);
    setCurrentPageID(newPages[0].id);
    setShouldPrevent(true);
  };

  const handleDelete = (pageId) => {
    toggleModal();
    setRemovePage(pageId);
  };

  const handleConfirm = () => {
    deletePage(removePage);
    toggleModal();
  };

  if (action && action.success === false) {
    return (
      <div className="form-builder">
        <h1>Definición de Formulario</h1>
        <div className="col-container">
          {action.message ? action.message : 'Este sector ya tiene cargos/roles validados, por lo que no puede modificar el formulario'}
        </div>
      </div>
    );
  }
  if (!pages) return null;


  const moveField = () => {
    const currentPage = pages.find(p => p.id === currentPageID);
    let removeField = '';
    if (fieldToMove.secId) {
      // remover campo de sección
      const sectionIndex = sections.findIndex(s => s.id === fieldToMove.secId);
      const removeIndex = sections[sectionIndex].fields.findIndex(f => f === fieldToMove.id);
      removeField = sections[sectionIndex].fields.splice(removeIndex, 1);
      if (sections[sectionIndex].fields.length === 0) {
        currentPage.content = currentPage.content.filter(c => c !== sections[sectionIndex].id);
        sections.splice(sectionIndex, 1);
      }
    } else {
      // remover campo de página
      const removeIndex = currentPage.content.findIndex(c => c === fieldToMove.id);
      removeField = currentPage.content.splice(removeIndex, 1);
    }

    if (moveTo.includes('SEC')) {
      // mover campo a otra sección (fuera o dentrode la página)
      const section = sections.find(sec => sec.id === moveTo);
      section.fields.splice(0, 0, removeField[0]);
    } else {
      // mover campo a otra página (fuera de la sección)
      const page = pages.find(p => p.id === moveTo);
      page.content.splice(0, 0, removeField[0]);

      // actualizar el estado de la página donde moví el campo
      onChangePageContent({ ...page });
    }
    onChangePageContent({ ...currentPage });
    setSections([...sections]);
    setPages([...pages]);
  };

  const handleMoveField = (id, title, secId) => {
    toggleMoveModal();
    setFieldToMove({ id, title, secId });
  };

  const handleConfirmMove = () => {
    moveField();
    toggleMoveModal();
  };

  const reorderPages = (isUp, index) => {
    if ((isUp && index === 0) || (!isUp && index === pages.length - 1)) {
      return;
    }
    const newIndex = isUp ? index - 1 : index + 1;
    const newPages = arrayMove(pages, index, newIndex);
    setPages([...newPages]);
  };


  const contentModalMove = (
    <>
      <p>Seleccione el módulo/sección a la cual desea mover el campo:</p>
      <i>{fieldToMove.title}</i>
      <select name="" id="" onChange={e => setMoveTo(e.target.value)}>
        <option value="" selected disabled>Módulo o sección</option>
        <optgroup label="Módulo">
          {pages.map(p => <option value={p.id}>{p.title}</option>)}
        </optgroup>
        <optgroup label="Secciones">
          {sections.map(s => s.id !== fieldToMove.secId && <option value={s.id}>{s.title}</option>)}
        </optgroup>
      </select>
    </>
  );

  let errorMessageSave = (errorMessage) => {
    if (errorMessage.schema && errorMessage.schema.result == 'SCHEMA ERROR'){
      if (errorMessage.schema.errors.some((
        elem => (
          elem.message == "Missing field's title"
          )
        ))){
        return "Hay preguntas sin texto cargado";
      }
      if (errorMessage.schema.errors.some((
        elem => (
          elem.message == "MO has no options" ||
          elem.message == "Matrix has no rows" ||
          elem.message == "Matrix has no columns" 
          )
        ))){
        return "Hay opciones sin texto cargado";
      }
    }
    return "Hubo un error en el servidor";
  }
  return (
    <div className="form-builder">
      <Prompt
        when={shouldPrevent}
        message="Seguro que quieres salir? se perderán todos los cambios sin guardar"
      />
      <div className="page-title-nav">
        <h1>Definición de formulario</h1>
        <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
      </div>
      <div className="col-container">
        <div className="col-left">
          <h2>Módulos</h2>
          {pages && pages.map((p, index) => (
            <div className={`single-page style-pages ${currentPageID === p.id ? 'active' : ''}`}>
              {form_editable ? <span className="order-buttons">
                <button className="order-button-up" type="button" onClick={() => reorderPages(true, index)} />
                <button className="order-button-down" type="button" onClick={() => reorderPages(false, index)} />
              </span> : null}

              <button key={p.id} className="nav-pages"
                type="button" onClick={() => onClickPage(p.id)}
              >
                {p.title}
              </button>
              { form_editable ? pages.length > 1 && status !== 1 && (
                <button type="button" className="delete-page" onClick={() => handleDelete(p.id)}>
                  <img src={Trash} alt="" width="18px" />
                </button>
              )
              : null}
            </div>
          ))}
          {form_editable ? <button type="button" className="add-page" onClick={addPage}>+ Añadir Módulo</button> : null} 
        </div>
        {pages && sections && (
          <Page fields={fields} onChangeFields={setFields}
            changesLimited={status === 1}
            validated = {validated}
            sections={sections} onChangeSections={setSections}
            currentPage={pages.find(p => p.id === currentPageID)}
            onChangePageContent={onChangePageContent} onChangeSectionTitle={setCurrentSectionTitle}
            handleMoveField={handleMoveField} prevent={setShouldPrevent} form_editable={form_editable}
            errors={saveStatus.error && saveStatus.message.schema}
          />
        )}
      </div>
      <div className="col-container save">
        <div className="content">
          <Spinner active={saveStatus.loading} />
          {saveStatus.error && <span className="feedback-error">{errorMessageSave(saveStatus.message)}</span> }
          {saveStatus.success && <span className="feedback-success">Guardado con éxito</span> }
          {form_editable ? <PrimaryButton text="Guardar" onClick={onSaveForm} /> : null}
        </div>
        <div className="col-right" />
      </div>
      {form_editable ? <ConfirmModal active={active} title="Borrar Módulo" content="Está seguro que desea eliminar el módulo ?"
        onConfirm={() => handleConfirm()} onCancel={toggleModal}
      /> : null}
      {form_editable ? <ConfirmModal active={moveActive} title="Mover a" content={contentModalMove}
        onConfirm={() => handleConfirmMove()} onCancel={toggleMoveModal}
      /> : null}
    </div>
  );
};


const mapStateToProps = state => ({
  schema: state.forms.schema,
  status: state.forms.status,
  validated: state.forms.validado,
  action: state.forms.action,
  saveStatus: state.forms.saveStatus,
  form_editable: state.sectores.sector ? state.sectores.sector.form_editable: false,
});

const mapDispatchToProps = dispatch => ({
  fetch: (id, params) => dispatch(fetchForm(id, params)),
  update: (id, data) => dispatch(updateForm(id, data)),
  clear: () => dispatch(clearFlags()),
  updateFields: fields => dispatch(setSchemaFields(fields)),
  updateValoracion: (id, data) => dispatch(updateSectorForValoration(id, data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormBuilder);
