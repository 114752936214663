import React from 'react';
import logo from '../images/logo-white.svg';

const Footer = () => (
  <footer>
    <div className="left">
      <img src={logo} alt="inefop" />
      <p>CETFOR</p>
    </div>
    <div className="right">
      <a href="/#">Versión 4.1.0</a>
    </div>
  </footer>
);

export default Footer;
