/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Transition } from 'react-spring/renderprops';

import useConditionals from '../../../hooks/useConditionals';
import Conditional from '../../../conditionals/Conditional';
import { transitions } from '../../../utils';

const EditSelect = ({ interpretation, id, values, conditionals, onChangeField, schemaFields, changesLimited, form_editable }) => {
  const [maxID, setMaxID] = useState(-1);
  const [conds, setConds] = useConditionals(conditionals);


  useEffect(() => {
    if (conds) {
      onChangeField(id, 'conditionals', conds);
      onChangeField(id, 'hidden', true);
    }
  }, [conds]);


  /**
   *  Chequea si los valores estan vacios y los inicializa
   *  valores pueden ser vacios si el tipo del campo no es select.
   * */
  useEffect(() => {
    let max = -1;

    if (!values || values.length === 0) {
      onChangeField(id, 'values', []);
      max = 0;
    } else {
      max = Math.max(...values.map(f => parseInt(f.key, 10)));
    }
    setMaxID(max);
  }, []);

  /**
   *  Handler para camnejar los cambios en el valor seleccionado
   * */
  const onOptionsChange = (k, event) => {
    const newValues = [...values];
    const toChange = values.findIndex(f => f.key === k);
    newValues[toChange].value = event.target.value;
    onChangeField(id, 'values', newValues);
  };

  useEffect(() => {
    if (interpretation && !values.find(v => v.key === 'otros - ')){
      const newMaxID = maxID + 1;
      const newVal = { key: 'otros - ', value: 'Otros' };
      if (changesLimited) newVal.isNew = true;
      let newValues = [...values, newVal];
      onChangeField(id, 'values', newValues);
      setMaxID(newMaxID);
    }
    else if (!interpretation){
      const newValues = values.filter(v => v.key !== 'otros - ');
      onChangeField(id, 'values', newValues);
    }
  }, [interpretation])

  /**
   *  Handler para agregar nuevas opciones
   * */
  const onAddOption = () => {
    const newMaxID = maxID + 1;
    const newVal = { key: `${newMaxID}`, value: '' };
    if (changesLimited) newVal.isNew = true;
    let newValues;
    if (interpretation){
      newValues = [...values]
      newValues.splice(values.length-1, 0, newVal)
    }
    else{
      newValues = [...values, newVal];
    }
    onChangeField(id, 'values', newValues);
    setMaxID(newMaxID);
  };

  /**
   *  Handler para remover la opción seleccionada
   * */
  const removeOption = (key) => {
    const newValues = values.filter(v => v.key !== key);
    onChangeField(id, 'values', newValues);
  };

  return (
    <>
      <div className="select-values">
        <label htmlFor="input">
        Opciones
          {values && values.map((v, ind) => (
            <div className="value">
              {ind+1}. &nbsp;
              <input disabled={!form_editable} id={v.key} key={v.key} defaultValue={v.value}
                onChange={e => onOptionsChange(v.key, e)}
              />
              {(form_editable && (!changesLimited || (changesLimited && v.isNew))) && v.key!=='otros - ' && (
                <button className="remove-btn" type="button" onClick={() => removeOption(v.key)}>&times;</button>
              )}
            </div>
          ))}
          {form_editable ? <button className="add-option" type="button" onClick={onAddOption}>Agregar opción</button> : null}
        </label>
      </div>
      <Transition items={conditionals !== undefined} {...transitions}>
        {show => show && (props => (
          <label className="conditionals" style={props}>
            Lógica
            {conds && conds.length > 0 && (
            <div>
              {conds.map((cond, i) => (
                <Conditional key={i} index={i} data={cond}
                  fields={schemaFields.filter(f => f.id !== id)} onChange={setConds}
                />
              ))}
            </div>
            )}
            {form_editable ? <button className="add-option" type="button" onClick={setConds}>
              Agregar
            </button> : null}
          </label>
        ))}
      </Transition>
    </>
  );
};

EditSelect.propTypes = {
  onChangeField: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = state => ({
  schemaFields: state.forms.fields,
  form_editable: state.sectores.sector.form_editable,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSelect);
