import React from 'react';
import PropTypes from 'prop-types';

const StringValidator = ({ index, type, value, used, onChange }) => {
  //
  const availableValidators = [
    { key: 'contains', text: 'Contiene' },
    { key: 'minLength', text: 'Mínimo de caracteres' },
    { key: 'maxLength', text: 'Máximo de caracteres' }
  ];

  const onValidatorChange = (event) => {
    const { name, value: newValue } = event.target;
    onChange({ index, type, value, [name]: newValue });
  };

  const validatorsToUse = availableValidators.filter(a => !used.includes(a.key) || type === a.key);

  return (
    <div className="number-validator" key={`${type}-${index}`}>
      <span>Texto</span>
      <select name="type" defaultValue={type} onChange={onValidatorChange}>
        <option value="" disabled>Seleccione una opción</option>
        {validatorsToUse.map(op => (<option value={op.key}>{op.text}</option>))}
      </select>
      {type !== 'contains'
        ? (
          <input type="number" placeholder="valor" defaultValue={value}
            onChange={onValidatorChange} name="value"
          />
        )
        : (
          <input type="text" placeholder="valor" defaultValue={value}
            onChange={onValidatorChange} name="value"
          />
        )
      }
      <button className="remove-btn" type="button"
        onClick={() => onChange({ index })}
      >
      &times;
      </button>
    </div>
  );
};

StringValidator.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  used: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};
export default StringValidator;
