import React from 'react';
import PropTypes from 'prop-types';

const AnswerField = ({
  printable, wizard, readOnly, errors, onOthersCHangeMulti, onOthersCHange, onAnswerChange, index, satisfyConditional, renderProfileText, quantities, ...info
}) => {

  /**
   * componente para mostrar las
   * respuestas
   */

  // eslint-disable-next-line
  const comp = require(`./fields/${info.type}`);
  const Component = comp ? comp.default : null;
  const compProps = { readOnly, errors, onAnswerChange, onOthersCHange, onOthersCHangeMulti, ...info };

  if (info.pollster && !compProps.puedeEncuestar) {
    return null;
  }

  return (
    satisfyConditional ? (
      <div className="answer-field-container">
        <div className="col-left" />
        <label htmlFor={info.id}>
          <span>
            <small>{`${index} - `}</small>
            {wizard && (info.profile || info.rate) ? 
            <b className="text-title-red">*{renderProfileText ? (info.profile_text || info.title) : info.title}</b> : 
            renderProfileText ? (info.profile_text || info.title) : info.title}
            {info.pollster && <small style={{ color: '#216b96' }}> - Pregunta Encuestador</small>}
            
          </span>
          <div className={`field-group ${errors ? 'field-error' : ''}`}>
            {(printable && info.tooltip )&& <span className="tooltip"><pre>{info.tooltip}</pre></span>}
            <Component {...compProps} quantities={quantities} />
            {errors && <span className="error-message">{errors}</span>}
            {(!printable && info.tooltip) && <span className="tooltip"><pre>{info.tooltip}</pre></span>}
          </div>
        </label>
      </div>
    ) : null
  );
};

AnswerField.propTypes = {
  errors: PropTypes.string,
  index: PropTypes.string.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  printable: PropTypes.bool
};

AnswerField.defaultProps = {
  readOnly: false,
  errors: null
};

export default AnswerField;
