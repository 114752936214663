import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import FileInput from './components/FileInput';
import { fetchSector, updateSector, clearActions as sectorClear } from '../Sectors/redux/actions';

const GlosaryFramenForm = ({
  type, match, clear,
  getSector, sector, updateSector }) => {

  /*
    component to load and modify the glosary
    and refenrences mail
  */
  const [file, setFile] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);


  useEffect(() => {
    getSector(match.params.id);

    setFile('');
    setError(false);
    setSuccess(false);

    return () => { clear(); };
  }, []);

  const saveFile = () => {
    if (file && file !== '') {
      const formData = new FormData();
      if (type === 'glosario'){
        formData.append('glosario', file);
      }
      else if (type === 'marco'){
        formData.append('marco_competencias', file);
      }
      updateSector(match.params.id, formData);
      
      setError(false);
      setSuccess(true);
    } else {
      setError(true);
    }
  };

  const content = sector;


  if (!content) return null;
  
  return (
    <div className="validation">

      <div className="page-title-nav">
        <h1>
          {`Cargar ${type}`}
        </h1>
        <Link to={`/sectores/${match.params.id || (content.sector && content.sector.id)}/`}>Volver al sector</Link>
      </div>
      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">
              Sector
            </span>
            {content.nombre}
          </div>
        </div>
        <div className="content">
          {content && content.validacion && (
          <div className="val-actual">
            {type==='glosario' ? 'Glosario' : 'Marco de referencias'} Actual: &nbsp;
            <a href={type==='glosario' ? content.glosario : content.marco_competencias}> Ver </a>
          </div>
          )}
          <div className="file-form">
            <FileInput onSetFile={setFile} text={file ? 'Modificar' : 'Adjuntar'} errorMessage={error} />
            {file && <PrimaryButton text="Guardar" onClick={() => saveFile()} />}
          </div>
          {error && <span className="feedback-error">Adjunte al archivo antes de Guardar</span>}
          {success && <span className="feedback-success">Guardado con éxito</span>}
          <p>
            {`Adjunte el ${type} y luego presione guardar`}
          </p>
        </div>
        <div className="col-right" />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sector: state.sectores.sector,
});

const mapDispatchToProps = dispatch => ({
  getSector: id => dispatch(fetchSector(id)),
  updateSector: (id, data) => dispatch(updateSector(id, data)),
  clear: () => dispatch(sectorClear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlosaryFramenForm);
