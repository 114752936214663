import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { loginAction } from './redux/actions';
import PrimaryButton from '../../components/PrimaryButton';

import logo from '../../images/inefop-celeste.svg';

const Login = ({ history, login, loginStatus }) => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors({});
  }, [user, password]);

  useEffect(() => {
    if (loginStatus.success) {
      history.push('/sectores');
    } else if (!loginStatus.loading) {
      setWrongCredentials(true);
    }
  }, [loginStatus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (user === '') newErrors.user = 'Usuario no puede ser vacío';
    if (password === '') newErrors.password = 'Contraseña no puede ser vacía';
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      login(user, password);
    }
  };

  return (
    <div className="login">
      <div className="header">
        <img src={logo} alt="logo" />
        <span>sistema de gestión</span>
      </div>
      <div className="form" onSubmit={handleSubmit}>
        <form>
          <h1>Inicio de Sesión</h1>
          <label className={errors.user ? 'field-error' : ''}>
          Usuario
            <input type="text" onChange={e => setUser(e.target.value)} />
            {errors.user && <span className="error-message">{errors.user}</span>}
          </label>
          <label className={errors.password ? 'field-error' : ''}>
          Contraseña
            <input type="password" onChange={e => setPassword(e.target.value)} />
            {errors.password && <span className="error-message">{errors.password}</span>}
          </label>
          {wrongCredentials && loginStatus.message !== 'pending'
          && <span className="wrong-credentials">{loginStatus.message}</span>}
          <PrimaryButton text="Ingresar" type="submit" />
        </form>
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  loginStatus: state.auth.loginStatus
});

const mapDispatchToProps = dispatch => ({
  login: (user, pass) => dispatch(loginAction(user, pass))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
