import {
  FETCH_LIST,
  FETCH_PROFILE,
  UPDATE_PROFILE,
  CLEAR_ACTIONS,
  FETCH_ANSWERS,
  FETCH_VALORACION_LIST,
  UPDATE_TEMPANSWER,
  FETCH_PDF,
  CLEAR_DELAYED_PROFILE } from './types';


const BACKEND_API = 'BACKEND_API';

export const fetchList = (id, params) => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_LIST,
    method: 'GET',
    url: `/sectores/${id}/perfiles/`,
    params
  }
});

export const updateTempAnswer = (mail, mailText, profile, uuid) => ({
  type: BACKEND_API,
  payload: {
    ...UPDATE_TEMPANSWER,
    method: 'post',
    url: `/perfiles/${uuid}/updateTempAnswer/`,
    data: {
      mail,
      mailText,
      profile
    }
  }
});

export const fetchListWithValoracion = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_VALORACION_LIST,
    method: 'GET',
    url: `/sectores/${id}/valoraciones/`
  }
});

export const fetchProfile = pid => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_PROFILE,
    method: 'GET',
    url: `/perfiles/${pid}/`
  }
});

export const updateProfile = (pid, data) => ({
  type: BACKEND_API,
  payload: {
    ...UPDATE_PROFILE,
    method: 'PATCH',
    url: `/perfiles/${pid}/`,
    data
  }
});

export const printProfileDescription = pid => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_PDF,
    method: 'GET',
    url: `/perfiles/${pid}/generate_pdf`
  }
});

export const updateSectorProfile = (pid, data) => ({
  type: BACKEND_API,
  payload: {
    ...UPDATE_PROFILE,
    method: 'PATCH',
    url: `/perfiles/${pid}/`,
    data
  }
});

export const fetchAnswers = perfilID => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_ANSWERS,
    method: 'GET',
    url: `/perfiles/${perfilID}/respuestas/`
  }
});

export const clearActions = () => ({
  type: CLEAR_ACTIONS
});

export const clearDelayedProfile = () => ({
  type: CLEAR_DELAYED_PROFILE
});
