import React from 'react';
import PropTypes from 'prop-types';

const String = ({ readOnly, id, widget, answer, onAnswerChange }) => {
  if (widget) {
    if (widget.type === 'textfield') {
      return (
        <textarea className="" disabled={readOnly} name={id} id={id}
          rows="5" onChange={e => onAnswerChange(id, e.target.value)} defaultValue={answer}
        />
      );
    }
    if (widget.type === 'extensivetextfield') {
      return (
        <textarea className="extensivetextfield" disabled={readOnly} name={id} id={id} rows="5"
          onChange={e => onAnswerChange(id, e.target.value)} defaultValue={answer}
        />
      );
    }
  }
  return (
    <input disabled={readOnly} name={id} id={id} type="text"
      onChange={e => onAnswerChange(id, e.target.value)} defaultValue={answer}
    />
  );
};


String.propTypes = {
  id: PropTypes.string.isRequired,
  widget: PropTypes.object,
  onAnswerChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  answer: PropTypes.string
};

String.defaultProps = {
  widget: null,
  answer: ''
};

export default String;
