const asyncActionType = type => ({
  pending: `${type}_PENDING`,
  success: `${type}_SUCCESS`,
  error: `${type}_ERROR`
});

export const SET_SCHEMA = 'cetfor/forms/SET_SCHEMA';
export const FETCH_SCHEMA = asyncActionType('cetfor/forms/FETCH');
export const SAVE_SCHEMA = asyncActionType('cetfor/forms/SAVE');
export const CLEAR_ACTIONS = 'cetfor/forms/CLEAR_ACTIONS';
export const CLEAR_ACTIONS_FLAGS = 'cetfor/forms/CLEAR_ACTIONS_FLAGS';
