import { LOGIN } from './types';
import Auth from '../../../utils/Auth';

const INITIAL_STATE = {
  user: {},
  loginStatus: {
    success: false,
    loading: false,
    message: ''
  }
};

export const actionForState = (action, state, message) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: message || state
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*          ACTION STATUS           */
    /* ******************************** */
    case LOGIN.pending:
      return {
        ...state,
        loginStatus: actionForState(state.action, 'pending')
      };
    case LOGIN.success:
      Auth.getInstance().setAuthToken(action.payload.token);
      return {
        ...state,
        loginStatus: actionForState(state.action, 'success')
      };
    case LOGIN.error: {
      const errors = action.payload.response.data.non_field_errors;
      return {
        ...state,
        loginStatus: actionForState(state.action, 'error', errors && 'El nombre o la contraseña son incorrectos')
      };
    }
    default:
      return state;
  }
}
