import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchEstructuraOcupacional } from '../Sectors/redux/actions';
import { printProfileDescription } from '../Profiles/redux/actions';
import Nivel from './components/Nivel';


const EstructuraOcupacional = ({ match, fetch, estructura, print }) => {
  /**
   * muestra los perfiles en una matriz
   * por nivel y proceso
   */
  
  const [max, setMax] = useState(1);
  
  useEffect(() => {
    fetch(match.params.id);
  }, []);
  
  useEffect(() => {
    if (estructura){
      var maxTemp = 1;
      setMax(1)
      var item;
      for (item in estructura){
        var indice = 1;
        var i;
        for (i in estructura[item]){
          if (estructura[item][i].length > 0){
            if(indice > maxTemp){
              maxTemp = indice;
            }
          }
          indice++;
        }
      }
      setMax(maxTemp)
    }
  }, [estructura]);

  if (!estructura) return null;

  const handlePrint = (perfil) => {
    print(perfil.perfilId);
  };

  function LinkTo(perfil) {
    if (perfil.perfilId) {
      return <Link to={`/perfiles/${perfil.perfilId}/imprimir`} onClick={() => handlePrint(perfil)}>{perfil.nombre}</Link>;
    }
    return perfil.nombre;
  }

  function ordenar_procesos(a, b){
    if (estructura[a].peso > estructura[b].peso){
      return 1
    }
    if (estructura[a].peso < estructura[b].peso){
      return -1
    }
    if (a > b){
      return 1
    }
    if (a<b){
      return -1
    }
    return 0
  }
  function ordenar_alfabeticamente(a,b){
    let comparison = 0;
    if (a.nombre > b.nombre) {
      comparison = 1;
    } else if (a.nombre < b.nombre) {
      comparison = -1;
    }
    return comparison;

  }

  return (
    <div className="estructura">
      <div className="page-title-nav">
        <h1>Estructura ocupacional</h1>
        <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
      </div>
      <div className="col-container">
        <div className="content">
          <div className="scroll-table">
          <table>
            <thead>
              <th />
              {[...Array(max)].map((u, i) => (
                <th><Nivel nivel={i+1} /></th>
              ))}      
            </thead>
            <tbody>
              {Object.keys(estructura).sort(ordenar_procesos).map(e => (
                <tr>
                  <td>{e}</td>
                  {[...Array(max)].map((u, i) => i + 1).map(i => (
                    <td>
                      <ul>
                        {estructura[e][i] && estructura[e][i].sort(ordenar_alfabeticamente).map(p => (
                          <li>
                            {LinkTo(p)}
                          </li>
                        ))}
                      </ul>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>

  );
};

const mapStateToProps = state => ({
  estructura: state.sectores.estructura
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(fetchEstructuraOcupacional(id)),
  print: profileId => dispatch(printProfileDescription(profileId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstructuraOcupacional);
