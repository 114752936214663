import React from 'react';
import PropTypes from 'prop-types';

import Step from './Step';
import step1 from '../../../../images/step.svg';
import step2 from '../../../../images/respuestas.svg';
import step3 from '../../../../images/perfiles.svg';
import step4 from '../../../../images/valoracion.svg';
import Secondarybutton from '../../../../components/SecondaryButton';


const FormFlow = ({ sector, estado, enableValidaciones, valido }) => {

  /*
    displays the flow steps of a sector
    and the button habilitar
  */

  const steps = [
    {
      title: 'Construcción del formulario',
      image: step1,
      actions: [
        { title: 'Modificar',
          link: `/sectores/${sector.id}/construccion`,
          condition: sector.form_editable && sector.estado!=='PD'},
        { title: 'Visualizar',
          link: `/sectores/${sector.id}/construccion`,
          condition: !sector.form_editable},
        { title: 'Validación', link: `/sectores/${sector.id}/validacion`, condition: sector.puede_administrar },
        { title: 'Imprimir', link: `/sectores/${sector.id}/imprimir` }
      ],
      pruebaActions: [
        { title: 'Ingresar nueva', link: `/sectores/${sector.id}/responder`, condition: sector.puede_colaborar },
        { title: 'Ver Listado', link: `/sectores/${sector.id}/respuestas/prueba`, condition: sector.puede_colaborar }
      ]
    },
    {
      title: 'Relevamiento',
      image: step2,
      actions: [
        { title: 'Ingresar respuestas', link: `/sectores/${sector.id}/responder`, condition: sector.puede_encuestar },
        { title: 'Link trabajador', link: '', modal: sector.id, condition: sector.puede_colaborar },
        { title: 'Procesar respuestas', link: `/sectores/${sector.id}/respuestas`, condition: sector.puede_encuestar || sector.puede_colaborar }
      ]
    },
    {
      title: 'Descripción del cargo/rol',
      image: step3,
      actions: [
        { title: 'Listado de descripciones de cargos/roles', link: `/sectores/${sector.id}/perfiles`, condition: sector.puede_colaborar }
      ]
    },
    {
      title: 'Cargar Valoración',
      image: step4,
      actions: [
        { title: 'Diseño y Ponderación', link: `/sectores/${sector.id}/esquema_valoracion`, condition: sector.puede_administrar },
        { title: 'Valoración por item', link: `/sectores/${sector.id}/valoracion`, condition: sector.puede_administrar }
      ]
    },
    {
      title: 'Valoraciones',
      image: step4,
      actions: [
        { title: 'Ver Valoraciones', link: `/sectores/${sector.id}/valoraciones` }
      ]
    }
  ];

  const stepsArray = [0, 1, 2, 3];
  let active = 0;
  switch (estado) {
    case 'FD':
      active = 3;
      break;
    case 'CP':
      active = 3;
      break;
    case 'PD':
      active = 4;
      break;
    default:
      active = 0;
      break;
  }

  if (valido && valido.success) active = 4;

  const errors = valido && valido.data ? [...valido.data] : [];
  return (  
    <div className="form-flow">
      
      {stepsArray.map(k => (
        <Step step={k} key={k} info={steps[k]} current={active} inactive={k > active} />
      ))}

      {estado !== 'PD' && sector.puede_administrar && (
      <div className="enable-vals">
        Habilitar valoraciones:
        <Secondarybutton text="habilitar" onClick={enableValidaciones} />
        {valido && !valido.success && (errors.map(v => (<div className="val-error">{v}</div>)))}
        {valido && valido.success && <div className="val-success">Habilitado</div>}
      </div>
      )}
      <Step step={4} key={4} info={steps[4]} current={4} inactive={active < 4} />
    </div>
  );
};


FormFlow.propTypes = {
  sector: PropTypes.object.isRequired,
  estado: PropTypes.string

};

FormFlow.defaultProps = {
  estado: 'CF'
};
export default FormFlow;
