import React from 'react';
import PropTypes from 'prop-types';


const MainContainer = ({ children }) => (
  <div className="main">
    {children}
  </div>
);

MainContainer.propTypes = {
  children: PropTypes.node.isRequired
};
export default MainContainer;
