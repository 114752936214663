import { useState } from 'react';

export default (initialState) => {
  const [conditionals, setConditionals] = useState(initialState ? [...initialState] : undefined);

  const onConditionalChange = ({ index, ...val }) => {
    const newConditionals = [...conditionals];
    newConditionals[index] = val;
    setConditionals(newConditionals);
  };

  const onConditionalRemove = (index) => {
    const newConditionals = [...conditionals];
    newConditionals.splice(index, 1);
    setConditionals(newConditionals);
  };

  const addConditional = () => {
    const newConditionals = conditionals ? [...conditionals] : [];
    setConditionals([...newConditionals,
      {
        type: 'equals',
        field: '',
        value: '',
        state: { hidden: false }
      }]);
  };

  const onChange = ({ index, ...val }) => {
    if (index === undefined) {
      addConditional();
    } else if (Object.keys(val).length === 0) {
      onConditionalRemove(index);
    } else {
      onConditionalChange({ index, ...val });
    }
  };

  return [conditionals, onChange];
};
