import React from 'react';
import PropTypes from 'prop-types';
import Clickable from '../../../../../components/Clickable';

const String = ({ id, title, widget, onSelectField }) => (
  <Clickable onClick={() => onSelectField(id)}>
    <label id={id}>
      {title}
      <input type="text" disabled
        placeholder={widget && (widget.type === 'textfield' || widget.type === 'extensivetextfield') ? 'Respuesta Párrafo' : 'Respuesta breve'}
      />
    </label>
  </Clickable>
);

String.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSelectField: PropTypes.func.isRequired,
  widget: PropTypes.object.isRequired
};
export default String;
