import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import FileInput from './components/FileInput';
import { fetchSector, updateSector, clearActions as sectorClear } from '../Sectors/redux/actions';
import { fetchProfile, updateSectorProfile, clearActions } from '../Profiles/redux/actions';

const ValidationForm = ({
  type, match, clear,
  getSector, getProfile,
  sector, profile, updateSector, updateProfile }) => {
  const [file, setFile] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);


  useEffect(() => {
    if (type === 'formulario') {
      getSector(match.params.id);
    } else {
      getProfile(match.params.profileId);
    }

    setFile('');
    setError(false);
    setSuccess(false);

    return () => { clear(); };
  }, []);

  const saveFile = () => {
    if (file && file !== '') {
      const formData = new FormData();
      formData.append('validacion', file);
      if (type === 'formulario') {
        formData.append('estado', 'FD');
        updateSector(match.params.id, formData);
      } else {
        formData.append('estado', 'HA');
        updateProfile(match.params.profileId, formData);
      }
      setError(false);
      setSuccess(true);
    } else {
      setError(true);
    }
  };

  const content = type === 'formulario' ? sector : profile;

  // Chequea que este definido el perfil y que tanto los campos obligatorios que
  // van para el perfil como los que puntuan tengan una respuesta no vacia
  let canUploadValidationProfile = (profile && Object.keys(profile).length > 0
    && profile.perfil && profile.schema);

  if (canUploadValidationProfile) {
    profile.schema.fields.forEach((f) => {
      const rateOrProfile = f.rate || (f.profile && f.required);
      const missOrEmpty = !(f.id in profile.perfil) || profile.perfil[f.id] === '';
      if (rateOrProfile && missOrEmpty) {
        canUploadValidationProfile = false;
      }
    });
  }


  if (!content) return null;

  return (
    <div className="validation">

      <div className="page-title-nav">
        <h1>{`Validación de ${type}`}</h1>
        <Link to={`/sectores/${match.params.id || (content.sector && content.sector.id)}/`}>Volver al sector</Link>
      </div>
      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">
              { type === 'formulario' && 'Sector' }
              { type === 'perfil' && 'Cargo/Rol' }
            </span>
            {content.nombre}
          </div>
        </div>
        <div className="content">
          {content && content.validacion && (
          <div className="val-actual">
            Validación Actual: &nbsp;
            <a href={content.validacion}> Ver </a>
          </div>
          )}
          {(type === 'formulario' || canUploadValidationProfile) && (
          <div className="file-form">
            <FileInput onSetFile={setFile} errorMessage={error} />
            <PrimaryButton text="Guardar" onClick={() => saveFile()} />
          </div>
          )}
          {Object.keys(profile).length > 0 && !canUploadValidationProfile && (
          <span className="profile-error">
            Debe completar todos los campos del cargo/rol antes de validarlo.
          </span>
          )}
          {error && <span className="feedback-error">Adjunte al archivo antes de Guardar</span>}
          {success && <span className="feedback-success">Guardado con éxito</span>}
          <p>
            {`El archivo debe contener la validación del ${type} y debe estar firmada
          por un representante (al menos) de cada parte.
          Puede ser el acta de la CBET firmada.
          Al finalizar esta acción se da por concluida la elaboración.`}
          </p>
        </div>
        <div className="col-right" />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sector: state.sectores.sector,
  profile: state.profiles.profile
});

const mapDispatchToProps = dispatch => ({
  getSector: id => dispatch(fetchSector(id)),
  getProfile: id => dispatch(fetchProfile(id)),
  updateSector: (id, data) => dispatch(updateSector(id, data)),
  updateProfile: (id, data) => dispatch(updateSectorProfile(id, data)),
  clear: () => { dispatch(clearActions()); dispatch(sectorClear()); }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidationForm);
