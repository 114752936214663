const asyncActionType = type => ({
  pending: `${type}_PENDING`,
  success: `${type}_SUCCESS`,
  error: `${type}_ERROR`
});

export const FETCH_LIST = asyncActionType('cetfor/Sectors/FETCH');

export const FETCH_SECTOR = asyncActionType('cetfor/Sector/FETCH');
export const FETCH_ANSWER_ID = asyncActionType('cetfor/Sector/FETCH_ANSWER_ID');
export const FETCH_SECTOR_FULL = asyncActionType('cetfor/Sector/FETCH_FULL');
export const SAVE_SECTOR_FULL = asyncActionType('cetfor/Sector/SAVE_FULL');
export const SAVE_SECTOR = asyncActionType('cetfor/Sector/SAVE');
export const ENABLE_VALS = asyncActionType('cetfor/Sector/ENABLE_VALS');
export const FETCH_ESTRUCTURA = asyncActionType('cetfor/Sector/ESTRUCTURA');
export const CLEAR_ACTIONS = 'cetfor/Sector/SAVE';