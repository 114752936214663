import { LOGIN, LOGOUT } from './types';

const BACKEND_API = 'BACKEND_API';

export const loginAction = (username, password) => ({
  type: BACKEND_API,
  payload: {
    ...LOGIN,
    method: 'post',
    url: '/auth/',
    data: {
      username,
      password
    }
  }
});

export const logoutAction = () => ({
  type: BACKEND_API,
  payload: {
    ...LOGOUT,
    method: 'post',
    url: '/logout/'
  }
});
