import React from 'react';
import PropTypes from 'prop-types';


const ProgressBar = ({
  pages,
  currentPage,
  onSetCurrentPage
}) => {
  const currentPagePosition = parseInt(currentPage.id.substring(4), 10);

  const onChangePage = (page) => {
    onSetCurrentPage(page);
  };

  return (
    <div className="containerProgressBar">
      <ul className="progress-bar">
        {pages.map((page, i) => (
          <li className={parseInt(page.id.substring(4), 10) <= currentPagePosition ? 'active' : 'no-active'}>
            <button type="button" onClick={() => onChangePage(page)}>
              {i + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.object.isRequired,
  onSetCurrentPage: PropTypes.func.isRequired
};
