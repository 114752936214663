import {
  SET_SCHEMA,
  FETCH_SCHEMA,
  SAVE_SCHEMA,
  CLEAR_ACTIONS,
  CLEAR_ACTIONS_FLAGS } from './types';

const FORMS = 'FORMS';
const BACKEND_API = 'BACKEND_API';

export const setSchemaFields = fields => ({
  type: FORMS,
  payload: {
    ...SET_SCHEMA,
    fields
  }
});

export const fetchForm = (id, params) => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_SCHEMA,
    method: 'get',
    url: `/sectores/${id}/formulario/`,
    params
  }
});

export const fetchFormUUID = (uuid, params) => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_SCHEMA,
    method: 'get',
    url: `/sectores/${uuid}/formulario_uuid/`,
    params
  }
});

export const updateForm = (id, data) => ({
  type: BACKEND_API,
  payload: {
    ...SAVE_SCHEMA,
    method: 'post',
    url: `/sectores/${id}/actualizar_formulario/`,
    data
  }
});

export const clearActions = () => ({
  type: CLEAR_ACTIONS
});

export const clearFlags = () => ({
  type: CLEAR_ACTIONS_FLAGS
});
