/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Transition } from 'react-spring/renderprops';

import useConditionals from '../../../hooks/useConditionals';
import { transitions } from '../../../utils';
import Conditional from '../../../conditionals/Conditional';

const EditEmail = ({ id, conditionals, onChangeField, schemaFields, form_editable }) => {
  const [conds, setConds] = useConditionals(conditionals);

  useEffect(() => {
    if (conds) {
      onChangeField(id, 'conditionals', conds);
      onChangeField(id, 'hidden', true);
    }
  }, [conds]);

  return (
    <>
      <Transition items={conditionals !== undefined} {...transitions}>
        {show => show && (props => (
          <label className="conditionals" style={props}>
            Lógica
            {conds && conds.length > 0 && (
            <div>
              {conds.map((cond, i) => (
                <Conditional key={i} index={i} data={cond}
                  fields={schemaFields.filter(f => f.id !== id)} onChange={setConds}
                />
              ))}
            </div>
            )}
            {form_editable ? <button className="add-option" type="button" onClick={setConds}>
              Agregar
            </button> : null}
          </label>
        ))}
      </Transition>

    </>
  );
};

EditEmail.propTypes = {
  id: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.object),
  onChangeField: PropTypes.func.isRequired
};

EditEmail.defaultProps = {
  validators: undefined
};
const mapStateToProps = state => ({
  schemaFields: state.forms.fields,
  form_editable: state.sectores.sector.form_editable,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmail);
