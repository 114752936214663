import {
  FETCH_LIST,
  FETCH_PROFILE,
  UPDATE_PROFILE,
  CLEAR_ACTIONS,
  FETCH_ANSWERS,
  CLEAR_DELAYED_PROFILE,
  FETCH_VALORACION_LIST,
  FETCH_PDF } from './types';

const INITIAL_STATE = {
  list: [],
  profile: {},
  answers: [],
  action: {
    success: false,
    loading: false,
    message: ''
  },
  detailStatus: {
    success: false,
    loading: false,
    message: ''
  },
  updateStatus: {
    success: false,
    loading: false,
    message: ''
  },
  answersStatus: {
    success: false,
    loading: false,
    message: ''
  }
  /* initialize state */
};

export const actionForState = (action, state, message) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: message || state
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*          ACTION STATUS           */
    /* ******************************** */
    case FETCH_LIST.pending:
      return {
        ...state,
        profile: {},
        action: actionForState(state.action, 'pending')
      };
    case FETCH_LIST.success: {
      if (action.payload.results) {
        return {
          ...state,
          list: action.payload.results,
          profile: {},
          count: Math.ceil(action.payload.count / 10),
          action: actionForState(state.action, 'success')
        };
      }
      return {
        ...state,
        list: action.payload.results,
        profile: {},
        count: Math.ceil(action.payload.count / 10),
        action: actionForState(state.action, 'success')
      };
    }
    case FETCH_LIST.error:
      return {
        ...state,
        profile: {},
        action: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*          ACTION STATUS           */
    /* ******************************** */
    case FETCH_VALORACION_LIST.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_VALORACION_LIST.success:
      return {
        ...state,
        valoraciones: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_VALORACION_LIST.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };





    /* ******************************** */
    /*          ACTION STATUS           */
    /* ******************************** */
    case FETCH_PDF.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_PDF.success:
      return {
        ...state,
        descripcionCargo: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_PDF.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };













    /* ******************************** */
    /*          FETCH PROFILE           */
    /* ******************************** */
    case FETCH_PROFILE.pending:
      return {
        ...state,
        detailStatus: actionForState(state.action, 'pending')
      };
    case FETCH_PROFILE.success:
      return {
        ...state,
        profile: action.payload,
        detailStatus: actionForState(state.action, 'success')
      };
    case FETCH_PROFILE.error:
      return {
        ...state,
        detailStatus: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*          UPDATE PROFILE          */
    /* ******************************** */
    case UPDATE_PROFILE.pending:
      return {
        ...state,
        updateStatus: actionForState(state.action, 'pending')
      };
    case UPDATE_PROFILE.success:
      return {
        ...state,
        profile: action.payload,
        updateStatus: actionForState(state.action, 'success')
      };
    case UPDATE_PROFILE.error:
      return {
        ...state,
        updateStatus: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*           FETCH ANSWERS          */
    /* ******************************** */
    case FETCH_ANSWERS.pending:
      return {
        ...state,
        answersStatus: actionForState(state.action, 'pending')
      };
    case FETCH_ANSWERS.success:
      return {
        ...state,
        answers: action.payload,
        answersStatus: actionForState(state.action, 'success')
      };
    case FETCH_ANSWERS.error:
      return {
        ...state,
        answersStatus: actionForState(state.action, 'error')
      };
    case CLEAR_ACTIONS:
      return INITIAL_STATE;
    
    case CLEAR_DELAYED_PROFILE:{
      const {list, ...rest} = INITIAL_STATE;
      return {
        ...state,
        ...rest
      };
    }
    default:
      return state;
  }
}
