import React from 'react';
import PropTypes from 'prop-types';

const IconButton = ({text, image, onClick }) => {
  return(
  <button title={text} type="button" className="icon-btn" onClick={onClick}>
    <img src={image} alt="" />
  </button>
)};

IconButton.propTypes = {
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
export default IconButton;
