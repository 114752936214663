import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const Table = ({ data }) => (
  <table>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Total</th>
        <th style={{ width: '20%' }}>Accion</th>
      </tr>
    </thead>
    <tbody>
      {data && data.map(d => (
        <tr>
          <td>
            <Link to={`/valoraciones/${d.id}`}>{d.perfil}</Link>
          </td>
          <td>
            {Math.round(d.total)}
          </td>
          <td>
            <Link to={`/valoraciones/${d.id}`}>Ver</Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default Table;
