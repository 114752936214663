import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FormFlow from './components/FormFlow';
import { fetchSector, enableValidaciones } from '../Sectors/redux/actions';


const SectorFlow = ({ match, fetch, sector, enable, valsAction }) => {
  useEffect(() => {
    fetch(match.params.id);
  }, [valsAction]);


  const enableVals = () => {
    enable(match.params.id);
  };

  return (
    <div className="page">
      <div className="page-title-nav">
        <h1>Construcción de Formulario</h1>
        <Link to="/sectores/">Volver a sectores</Link>
      </div>
      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">Sector</span>
            {sector && sector.nombre}
            {sector && sector.puede_administrar && <Link to={`/sectores/${match.params.id}/descripcion`}>Modificar descripción</Link>}
            <Link to={`/sectores/${match.params.id}/estructura_ocupacional`}>Estructura Ocupacional</Link>
            
            <div className='text_info'>
            {
              (sector && sector.glosario) ?
              <a href={sector.glosario}> Glosario</a>: 
                'Sin glosario'
            }
            {(sector && sector.puede_administrar) && 
              <>
                <br/>
                <Link to={`/sectores/${match.params.id}/glosario`}>{sector.glosario ? 'Modificar glosario' : 'Cargar glosario'}</Link>
              </>
            }
            </div>
            
            <div className='text_info'>
            {
              (sector && sector.marco_competencias) ? 
              <a href={sector.marco_competencias}>Marco de competencia</a>: 
              'Sin marco de competencia'
            }
          
            {(sector && sector.puede_administrar) &&
              <>
                <br/>
                <Link to={`/sectores/${match.params.id}/marco`}>{sector.marco_competencias ? 'Modificar marco' : 'Cargar marco'}</Link>
              </>
            }
            </div>
          </div>
          <div className="info">
            <span className="label">Responsables INEFOP</span>
            {sector && sector.responsable.map(a => <div>{a}</div>)}
          </div>
          {sector && sector.colaboradores.length > 0 && (
            <div className="info">
              <span className="label">Colaboradores</span>
              {sector.colaboradores.map(a => <div>{a}</div>)}
            </div>
          )}
          {sector && sector.encuestadores.length > 0 && (
            <div className="info">
              <span className="label">Encuestadores</span>
              {sector.encuestadores.map(e => <div>{e}</div>)}
            </div>
          )}
        </div>
        <div className="mobile-col-left">
          <div className="info">
            <p className="label">Sector</p>
            {sector && sector.nombre}
            <div>
            <Link to={`/sectores/${match.params.id}/estructura_ocupacional`}>Estructura Ocupacional</Link>
            </div>
            <div className='text_info'>
            {
              (sector && sector.glosario) ?
              <a href={sector.glosario}> Glosario</a>: 
                'Sin glosario'
            }
            {(sector && sector.puede_administrar) && 
              <>
                <br/>
                <Link to={`/sectores/${match.params.id}/glosario`}>{sector.glosario ? 'Modificar glosario' : 'Cargar glosario'}</Link>
              </>
            }
            </div>
            
            <div className='text_info'>
            {
              (sector && sector.marco_competencias) ? 
              <a href={sector.marco_competencias}>Marco de competencia</a>: 
              'Sin marco de competencia'
            }
          
            {(sector && sector.puede_administrar) &&
              <>
                <br/>
                <Link to={`/sectores/${match.params.id}/marco`}>{sector.marco_competencias ? 'Modificar marco' : 'Cargar marco'}</Link>
              </>
            }
            </div>
          </div>
          
        </div>
        <div className="content">
          {sector && (
          <FormFlow sector={sector} estado={sector && sector.estado}
            enableValidaciones={enableVals} valido={valsAction}
          />
          )}
        </div>
        <div className="col-right" />
      </div>
    </div>

  );
};

const mapStateToProps = state => ({
  sector: state.sectores.sector,
  valsAction: state.sectores.valsAction
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(fetchSector(id)),
  enable: id => dispatch(enableValidaciones(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectorFlow);
