import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


const methodTypes = [
  ['SUM', 'Suma'],
  ['PROM', 'Foco en diversidad (promedio)'],
  ['MAX', 'Foco en máximo esfuerzo/mérito']
];


const ValoracionField = ({
  number, fields, fieldId, fieldInfo, valoracion, handleChange, showErrors, validateField }) => {
  const [msgError, setMsgError] = useState('');

  useEffect(() => {
    if (showErrors) {
      const validation = validateField(valoracion, fields.find(f => f.id === fieldId));
      if (!validation[0]) {
        setMsgError(validation[1]);
        return;
      }
    }
    setMsgError('');
  }, [showErrors]);

  const field = fields.find(f => f.id === fieldId);

  const onChange = (e) => {
    const { name, value } = e.target;
    handleChange(fieldId, name, parseInt(value, 10));
  };

  return (
    <div className="score-leaf">
      <h4>
        {`${number} - (`}
        <span className="primary-text">{fieldInfo.num}</span>
        {`) ${field.profile_text || field.title}`}
      </h4>

      <div className="nested-content">
        {msgError !== '' && <p className="valoracion-error">{msgError}</p>}
        <select defaultValue={valoracion.method} onChange={e => handleChange(fieldId, 'method', e.target.value)}>
          <option value="">------</option>
          { methodTypes.map(([key, val]) => (
            <option key={`method-${key}`} value={key}>{val}</option>
          ))}
        </select>

        {field.values && field.values.map(v => (
          <div key={v.key} className={`score-value ${field.type === 'multiselect' && 'separated'}`}>
            <input name={v.key} type="number" min="0" max="1000" onChange={onChange}
              defaultValue={valoracion && valoracion[v.key] !== "" ? valoracion[v.key] : ""}
            />
            {v.value}
          </div>
        ))}

        {field.rows && (
          <table>
            <thead>
              <tr>
                <td />
                {field.columns.map(col => <td key={col.value}>{col.value}</td>)}
              </tr>
            </thead>
            <tbody>
              {field.rows.map(row => (
                <tr key={row.value}>
                  <td>{row.value}</td>
                  {field.columns.map(col => (
                    <td key={col.key}>
                      <input name={`${row.key}-${col.key}`} type="number" min="0" max="1000" onChange={onChange}
                        defaultValue={valoracion && valoracion[`${row.key}-${col.key}`] !== "" ? valoracion[`${row.key}-${col.key}`] : ""}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

ValoracionField.propTypes = {
  number: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldId: PropTypes.string.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  valoracion: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  validateField: PropTypes.func.isRequired
};
export default ValoracionField;
