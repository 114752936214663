import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import FormContainer from './FormContainer';

import IconButton from '../../../components/IconButton';
import add from '../../../images/add.svg';
import section from '../../../images/section.svg';


const Page = SortableContainer(({
  fields,
  currentPage,
  onChangePageContent,
  onChangeFields,
  sections,
  onChangeSections,
  onChangeSectionTitle,
  changesLimited,
  handleMoveField,
  prevent,
  form_editable,
  validated,
  errors
}) => {
  // hookss
  const [active, setActive] = useState('');
  const [maxID, setMaxID] = useState(-1);
  const [hoverField, setHoverField] = useState(-1);

  const [insideSection, setInsideSection] = useState(false);
  const [currentSec, setCurrentSec] = useState('');

  // Setting up the max field ID
  useEffect(() => {
    const max = Math.max(...fields.map(f => parseInt(f.id.substring(5), 10)));
    setMaxID(max > 0 ? max : 0);
  }, []);

  useEffect(() => {
    setInsideSection(false);
  }, [currentPage]);

  /**
   * Handler para cambios en los campos.
   */
  const onFieldChange = (id, property, value) => {
    prevent(true);
    const newFields = [...fields];
    const toChange = newFields.findIndex(f => f.id === id);
    newFields[toChange][property] = value;
    onChangeFields(newFields);
    
  };
  /**
   * Handler for changes in fields.
   */
  const addField = () => {
    const newMaxID = maxID + 1;
    prevent(true);
    const newFields = [...fields, {
      id: `FIELD${newMaxID}`,
      title: '',
      type: 'string',
      required: true,
      hidden: false,
      profile: true,
      rate: true,
      isNew: true
    }];
    onChangeFields(newFields);
    const newActualPage = { ...currentPage };
    // c es el content, que puede ser Field o SEC.
    // Si c===SEC entonces, tengo que buscar en los fields que pertenecen a SEC
    // y comprobar si son igual a hoverField. Si lo son, retornar el index de SEC dentro de content.
    const index = currentPage.content.findIndex(c => c === hoverField
        || (c.includes('SEC') && sections.find(s => s.id === c).fields.filter(f => f === hoverField).length > 0));
    currentPage.content.splice(index + 1, 0, `FIELD${newMaxID}`);
    const newPageFields = [...currentPage.content];
    newActualPage.content = newPageFields;
    onChangePageContent(newActualPage);
    setActive(`FIELD${newMaxID}`);
    setMaxID(newMaxID);
  };

  /**
   * Agregar campos dentro de la sección
   */

  const addFieldToSection = (sec) => {
    prevent(true);
    const newMaxID = maxID + 1;
    const newField = {
      id: `FIELD${newMaxID}`,
      title: '',
      type: 'string',
      required: true,
      hidden: false,
      profile: true,
      rate: true,
      conditionals: [],
      isNew: true
    };
    const newFields = [...fields, newField];
    onChangeFields(newFields);
    const index = sec.fields.findIndex(f => f === hoverField);
    sec.fields.splice(index + 1, 0, `FIELD${newMaxID}`);
    const updateFieldsInSection = [...sec.fields];
    sec.fields = updateFieldsInSection;
    onChangeSections([...sections]);
    setActive(`FIELD${newMaxID}`);
    setMaxID(newMaxID);
  };


  /**
   * Handlerpara cambios en secciónes
  */
  const addSection = () => {
    prevent(true);
    const newMaxID = maxID + 1;
    const newSection = {
      id: `SEC${newMaxID}`,
      title: 'SECCION',
      fields: []
    };
    // agregar campo vacio a la sección
    addFieldToSection(newSection);
    // agregar newSection a Sections
    onChangeSections([...sections, newSection]);
    // agregar newSection a currentPage.content
    const newActualPage = { ...currentPage };
    const index = currentPage.content.findIndex(c => c === hoverField
      || (c.includes('SEC') && sections.find(s => s.id === c).fields.filter(f => f === hoverField).length > 0));
    currentPage.content.splice(index + 1, 0, `SEC${newMaxID}`);
    const newPageContent = [...currentPage.content];
    newActualPage.content = newPageContent;
    onChangePageContent(newActualPage);
    setActive(`SEC${newMaxID}`);
    setMaxID(newMaxID);
  };

  /**
   * Handler para remover campos
   */
  const removeField = (removeId) => {
    prevent(true);
    const updateFields = fields.filter(f => f.id !== removeId);
    onChangeFields(updateFields);
    const updateContent = currentPage.content.filter(f => f !== removeId);
    const updatePage = currentPage;
    updatePage.content = updateContent;
    onChangePageContent({ ...updatePage });
  };


  /**
   *
   * Handler para remover secciones
   */
  const removeSection = (sectionId) => {
    prevent(true);
    const updateContent = currentPage.content.filter(f => f !== sectionId);
    const updatePage = currentPage;
    updatePage.content = updateContent;

    let newFields = [...fields];
    let newSections = [...sections];
    const sectionToDelete = newSections.find(s => s.id === sectionId);
    sectionToDelete.fields.forEach((secf) => {
      newFields = newFields.filter(f => f.id !== secf);
    });
    newSections = newSections.filter(s => s.id !== sectionToDelete.id);
    onChangeSections(newSections);
    onChangeFields(newFields);
    onChangePageContent({ ...updatePage });
    setInsideSection(false);
  };


  /**
   * Handler para ordenar las secciones/campos dentro de FormContainer
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    prevent(true);
    const currentContent = currentPage.content;
    const newCurrent = arrayMove(currentContent, oldIndex, newIndex);
    const updatePage = currentPage;
    updatePage.content = newCurrent;
    onChangePageContent({ ...updatePage });
    const div = document.getElementsByClassName('fields-container')[0];
    div.classList.remove('dragging');
  };

  const onSortStart = () => {
    prevent(true);
    const div = document.getElementsByClassName('fields-container')[0];
    div.classList.add('dragging');
  };

  /**
   * Funcion para determinar donde agregar el campo,
   * dentro o fuera de una sección
   */
  const addFieldInFormOrSection = (sec) => {
    if (insideSection) {
      addFieldToSection(sec);
    } else {
      addField();
    }
  };


  return (
    <React.Fragment key={currentPage.id}>
      <div className="content">
        <label className={`page-title ${currentPage.title === '' && 'field-error'}`}>
        Título del módulo
          <div className="form-group">
            <input disabled={!form_editable} type="text" defaultValue={currentPage.title}
              onChange={e => onChangePageContent({ ...currentPage, title: e.target.value })}
            />
            {currentPage.title === '' && <span className="error-message">Inserte un nombre de módulo válido</span>}
          </div>
        </label>
        <FormContainer content={
          currentPage.content.map((c) => {
            if (c.includes('SEC')) {
              return sections.find(s => s.id === c);
            }
            return fields.find(f => f.id === c);
          })}
          active={active} onSelectField={setActive} onChangeField={onFieldChange}
          onHoverField={setHoverField} onRemove={removeField} onRemoveSection={removeSection} useDragHandle lockAxis="y"
          helperClass="drag" allFields={fields} addFieldToSection={addFieldToSection}
          sections={sections} onChangeSections={onChangeSections}
          onChangeSectionTitle={onChangeSectionTitle}
          onSortEnd={onSortEnd} onChangeFields={onChangeFields}
          setInsideSection={setInsideSection} setCurrentSec={setCurrentSec}
          addField={addField} addSection={addSection} onSortStart={onSortStart}
          changesLimited={changesLimited}
          validated={validated}
          handleMoveField={handleMoveField} form_editable={form_editable}
          errors={errors}
        />

        {form_editable ? <div className="add-btn-outside-sec">
          { currentPage.content.length > 0 && currentPage.content[currentPage.content.length - 1].includes('SEC') && (
          <>
            <IconButton text='Agregar pregunta a continuación' image={add} onClick={() => addField()} />
            <IconButton text='Agregar sección a continuación' image={section} onClick={() => addSection()} />
          </>
          )
         }
        </div> : null}
      </div>
      <div className="col-right">
        {form_editable ? <div className="add-btn">
          <IconButton text='Agregar pregunta a continuación' image={add} onClick={() => addFieldInFormOrSection(currentSec)} />
          { !insideSection && <IconButton text='Agregar sección a continuación' image={section} onClick={addSection} />}
        </div> : null}
      </div>
    </React.Fragment>
  );
});

Page.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.object.isRequired,
  onChangePageContent: PropTypes.func.isRequired,
  onChangeFields: PropTypes.func.isRequired,
  sections: PropTypes.func.isRequired,
  onChangeSections: PropTypes.func.isRequired

};

export default Page;
