import React from 'react';
import PropTypes from 'prop-types';

const Matrix = ({ answer, rows, columns }) => {
  const verifyChecked = (rowKey, colKey) => {
    if (answer !== null && answer[rowKey] !== undefined) {
      const def = answer[rowKey].find(a => a === colKey);
      if (def) { return true; }
    }
    return false;
  };


  return (
    <table>
      <thead>
        <tr>
          <th />
          {columns.map(c => <th key={c.key}>{c.value}</th>)}
        </tr>
      </thead>
      <tbody>
        {rows.map(r => (
          <tr>
            <th key={r.key}>{r.value}</th>
            {columns.map(c => (
              <td>
                {verifyChecked(r.key, c.key) && <>&#10004;</>}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Matrix.propTypes = {
  id: PropTypes.string.isRequired
};


export default Matrix;
