import { 
  FETCH_ANSWERS,
  SAVE_ANSWER,
  CLEAR_ACTIONS,
  CLEAR_DELAYED_ANSWER,
  FETCH_LIST_ANSWERS,
  FETCH_ANSWER,
  UPDATE_ANSWER } from './types';


const INITIAL_STATE = {
  schema: {
    id: '',
    version: '',
    fields: [],
    sections: [],
    pages: []
  },
  fields: [],
  saveStatus: {
    success: false,
    loading: false,
    message: ''
  },
  list:[]
};

export const actionForState = (action, state, message) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  error: state,
  message: message || state
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_ANSWER.pending:
      return {
        ...state,
        saveStatus: actionForState(state.action, 'pending')
      };
    case SAVE_ANSWER.success:
      return {
        ...state,
        saveStatus: actionForState(state.action, 'success')
      };
    case SAVE_ANSWER.error:
      return {
        ...state,
        saveStatus: actionForState(state.action, 'error', 'Hubo un error en el servidor')
      };
    case UPDATE_ANSWER.pending:
      return {
        ...state,
        saveStatus: actionForState(state.action, 'pending')
      };
    case UPDATE_ANSWER.success:
      return {
        ...state,
        answer: action.payload,
        saveStatus: actionForState(state.action, 'success')
      };
    case UPDATE_ANSWER.error:
      return {
        ...state,
        saveStatus: actionForState(state.action, 'error')
      };
    case CLEAR_ACTIONS: {
      return {
        ...state,
        saveStatus: {
          success: false,
          loading: false,
          message: ''
        },
        list: [],
        answer: undefined,
        answerAction: {
          success: false,
          loading: false,
          message: ''
        }
      };
    }
    case CLEAR_DELAYED_ANSWER: {
      return {
        ...state,
        saveStatus: {
          success: false,
          loading: false,
          message: ''
        },
        answer: undefined,
        answerAction: {
          success: false,
          loading: false,
          message: ''
        }
      };
    }
    case FETCH_ANSWERS.pending:
      return {
        ...state,
        answer:undefined,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_ANSWERS.success:
      return {
        ...state,
        list: action.payload.results,
        answer:undefined,
        count: Math.ceil(action.payload.count / 10),
        action: actionForState(state.action, 'success')
      };
    case FETCH_ANSWERS.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };

    case FETCH_LIST_ANSWERS.pending:
      // when it's not finished yet.
      // usually for loading states like spinner
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_LIST_ANSWERS.success:
      // when the api call return 200 OK
      // we have to manipulate the data
      return {
        ...state,
        list: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_LIST_ANSWERS.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    case FETCH_ANSWER.pending:
      return {
        ...state,
        answerAction: actionForState(state.action, 'pending')
      };
    case FETCH_ANSWER.success:
      return {
        ...state,
        answer: action.payload,
        answerAction: actionForState(state.action, 'success')
      };
    case FETCH_ANSWER.error:
      return {
        ...state,
        answerAction: actionForState(state.action, 'error')
      };
    default:
      return state;
  }
}
