import React from 'react';
import PropTypes from 'prop-types';

const FileInput = ({ onSetFile, text }) => {
  const getFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const updateInput = document.getElementsByTagName('input')[0];
      updateInput.value = file.name;
      onSetFile(file);
    }
  };

  return (
    <div className="file-input">
      <input type="text" placeholder="Archivo" disabled />
      <input className="file" type="file" id="files" onChange={e => getFile(e)} />
      {text ? <label htmlFor="files">{text}</label> : <label htmlFor="files">Adjuntar</label>}
    </div>
  );
};

FileInput.propTypes = {
  onSetFile: PropTypes.func.isRequired
};


export default FileInput;
