import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc';
import Field from './Field';
import IconButton from '../../../components/IconButton';
import Trash from '../../../images/trash.svg';
import ConfirmModal from '../../../components/ConfirmModal';
import add from '../../../images/add.svg';
import section from '../../../images/section.svg';
import handle from '../../../images/handler.svg';


const DragHandle = sortableHandle(() => <img className="handler" src={handle} alt="handle" />);


const Section = SortableElement(
  ({ level, fields, availableFields, onFieldChange, sectionData,
    onSectionDataChange, removeSection, getFieldsRecursive, setAvailableFields, showErrors, schema}) => {
    const [key, setKey] = useState(1);
    const [subTotal, setSubTotal] = useState(0);
    const [removeSectionFlag, setRemoveSectionFlag] = useState(false);

    const updateTotal = (newSections) => {
      const sum = newSections.reduce((acc, item) => acc + item.percentage, 0);
      setSubTotal(sum);
    };

    useEffect(() => {
      updateTotal(sectionData.content);
      let maxId = key;
      sectionData.content.forEach((elem) => { if (elem.id > maxId) { maxId = elem.id; } });
      setKey(maxId + 1);
    }, []);

    const onChangePercentage = (e) => {
      const perc = parseFloat(e.target.value, 10);
      onSectionDataChange({ ...sectionData, percentage: isNaN(perc) ? 0 : perc });
    };

    const onChangeTitle = (e) => {
      onSectionDataChange({ ...sectionData, title: e.target.value });
    };

    const onChangeItem = (index, data) => {
      const newContent = [...sectionData.content];
      newContent[index] = data;

      // If there was a field change
      if (data.type === 'field' && data.field_id !== sectionData.content[index].field_id) {
        onFieldChange(data.field_id, sectionData.content[index].field_id);
      }
      onSectionDataChange({ ...sectionData, content: newContent });
      updateTotal(newContent);
    };

    const onRemoveItem = (index) => {
      
      const newContent = sectionData.content.filter((i, iIndex) => iIndex !== index);
      const itemToDelete = sectionData.content.find((s, sIndex) => sIndex === index);
      const newAvailableFields = availableFields.concat(getFieldsRecursive(itemToDelete));

      onSectionDataChange({ ...sectionData, content: [...newContent] });
      setAvailableFields(newAvailableFields);
      updateTotal(newContent);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
      const newContent = arrayMove(sectionData.content, oldIndex, newIndex);
      onSectionDataChange({ ...sectionData, content: newContent });
    };

    const addSection = () => {
      const newSection = {
        id: key,
        type: 'section',
        content: [],
        percentage: 0,
        field_id: '',
        title: ''
      };
      const newData = { ...sectionData };
      newData.content.push(newSection);
      onSectionDataChange(newData);
      setKey(key + 1);
    };

    const addField = () => {
      const newField = {
        id: key,
        type: 'field',
        content: [],
        percentage: 0,
        title: '',
        field_id: ''
      };
      const newData = { ...sectionData };
      newData.content.push(newField);
      onSectionDataChange(newData);
      setKey(key + 1);
    };

    const sectionButtons = (
      <div className={level === 1 ? 'add-btn-section' : 'add-btn-subsection'}>
        <IconButton text='Agregar pregunta a continuación' image={add} onClick={() => addField()} />
        { level <= 5 && <IconButton text='Agregar sección a continuación' image={section} onClick={() => addSection()} />}
      </div>
    );

    return (
      <div className={level === 1 ? 'valoracion-section' : 'valoracion-subsection'}>
        {level > 1 && <DragHandle />}

        <div className="ponderacion-item">
          <input className={showErrors && (sectionData.percentage < 0 || sectionData.percentage > 100) ? 'valoracion-error' : ''}
            type="number" min="0" max="100" defaultValue={sectionData.percentage} onChange={e => onChangePercentage(e)}
          />
          <input className={showErrors && sectionData.title === '' ? 'valoracion-error' : ''}
            type="text" defaultValue={sectionData.title} onChange={e => onChangeTitle(e)}
            placeholder={level === 1 ? 'Título de la sección' : 'Título de la sub-sección'}
          />
        </div>

        {level > 1 && (
          <button type="button" className="delete-button-ponderacion" onClick={() => setRemoveSectionFlag(true)}>
            <img src={Trash} alt="" width="18px" />
          </button>
        )}

        <div className="section-container">
          <div className="container-actions">
            <b className={showErrors && subTotal !== 100 ? 'valoracion-error' : ''}>{`Sub total:  ${subTotal}%`}</b>
            { level !== 1 && sectionButtons }
          </div>

          <SectionContainer sectionContent={sectionData.content} level={level + 1} fields={fields}
            availableFields={availableFields} onChangeItem={onChangeItem}
            onRemoveItem={onRemoveItem} onFieldChange={onFieldChange}
            getFieldsRecursive={getFieldsRecursive} setAvailableFields={setAvailableFields}
            onSortEnd={onSortEnd} useDragHandle lockAxis="y" showErrors={showErrors} schema={schema}
          />
          { level === 1 && sectionButtons }
        </div>

        <ConfirmModal active={removeSectionFlag} title="Borrar sub-sección"
          content="Está seguro que desea eliminar la sub-sección?" onConfirm={removeSection}
          onCancel={() => setRemoveSectionFlag(false)}
        />
      </div>
    );
  }
);


const SectionContainer = SortableContainer(
  ({ sectionContent, level, fields, availableFields, onChangeItem, onRemoveItem,
    onFieldChange, getFieldsRecursive, setAvailableFields, showErrors, schema }) => {
    return (
      <div className="sortable-container">
        {sectionContent.map((item, index) => (item.type === 'field'
          ? (
            <Field key={`field-${item.id}`} index={index} fields={fields}
              availableFields={availableFields}
              onFieldDataChange={data => onChangeItem(index, data)}
              removeField={() => onRemoveItem(index)} fieldData={item}
              showErrors={showErrors} schema={schema}
            />
          )
          : (
            <Section key={`subsection-${item.id}`} index={index} level={level} fields={fields}
              availableFields={availableFields} onFieldChange={onFieldChange} sectionData={item}
              onSectionDataChange={data => onChangeItem(index, data)}
              removeSection={() => onRemoveItem(index)}
              getFieldsRecursive={getFieldsRecursive} setAvailableFields={setAvailableFields}
              showErrors={showErrors} schema={schema}
            />
          )
        ))}
      </div>
    );
  }
);


Section.propTypes = {
  level: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  availableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFieldChange: PropTypes.func.isRequired,
  sectionData: PropTypes.object.isRequired,
  onSectionDataChange: PropTypes.func.isRequired,
  removeSection: PropTypes.func.isRequired,
  getFieldsRecursive: PropTypes.func.isRequired,
  setAvailableFields: PropTypes.func.isRequired,
  showErrors: PropTypes.bool
};


SectionContainer.propTypes = {
  sectionContent: PropTypes.arrayOf(PropTypes.object).isRequired,
  level: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  availableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  getFieldsRecursive: PropTypes.func.isRequired,
  setAvailableFields: PropTypes.func.isRequired,
  showErrors: PropTypes.bool
};


export default Section;
