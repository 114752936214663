import React from 'react';
import ReactDOM from 'react-dom';

import { useSpring, animated } from 'react-spring';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

const ConfirmModal = ({ active, title, content, onCancel, onConfirm, hideCancelBtn = false }) => {
  const props = useSpring({ opacity: active ? 1 : 0 });
  return (active ? ReactDOM.createPortal(
    <>
      <animated.div style={props} className="modal-overlay" />
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
          <div className="modal-header">
            <span>{title}</span>
            <button type="button" data-dismiss="modal" aria-label="Close"
              className="modal-close" onClick={onCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <p>{content}</p>
          <div className="modal-footer">
            {!hideCancelBtn && <SecondaryButton text="Cancelar" onClick={onCancel} />}
            <PrimaryButton text="Aceptar" onClick={onConfirm} />
          </div>
        </div>
      </div>
    </>, document.body
  ) : null);
};
export default ConfirmModal;
