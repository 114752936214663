/* global window */
/* eslint-disable no-underscore-dangle */

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import apiMiddleware from './middleware';
import sectores from '../modules/Sectors/redux';
import forms from '../modules/FormBuilder/redux';
import answers from '../modules/Answers/redux';
import auth from '../modules/Auth/redux';
import profiles from '../modules/Profiles/redux';
import valoraciones from '../modules/Valoracion/redux';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({ sectores, forms, answers, auth, profiles, valoraciones }),
  composeEnhancers(applyMiddleware(apiMiddleware))
);

export default store;
