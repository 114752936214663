import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from './components/Table';
import Paginator from '../../../components/paginator';
import PrimaryButton from '../../../components/PrimaryButton';
import { fetchList } from '../redux/actions';

class SectorList extends React.Component {
  /**
   * componente que lista todos los sectores
   * y permite filtrarlos
   */

  constructor(props) {
    super(props);
    this.state = {page: 1};
  }

  componentDidMount() {
    const { listSectors } = this.props;
    listSectors(1);
  }

  render() {
    const { listSectors, sectors, maxCount } = this.props;
    return (
      <>
        <h1>Sectores</h1>
        <div className="container-full">
          <div className="filters">
            <input type="text" placeholder="Nombre Sector" />
            <input type="text" placeholder="Responsable" />
            <select>
              <option value="construyendo_formulario">Diseño de formulario</option>
              <option value="perfil_definido">Cargo/Rol Definido</option>
              <option value="perfil_en_progreso">Diseño Descripción del Cargo/Rol</option>
              <option value="formulario_definido">Formulario Final</option>
            </select>
            <PrimaryButton text="Filtrar" onClick={() => console.log('')} />
          </div>
          <Table data={sectors} />
          <Paginator page={this.state.page} max={maxCount} onChange={e => {
            this.setState(()=>{return {page: e}})
            listSectors(e)}} />
        </div>
      </>
    );
  }
}

SectorList.propTypes = {
  sectors: PropTypes.arrayOf(PropTypes.object).isRequired,
  listSectors: PropTypes.func.isRequired
};


const mapStateToProps = state => ({
  sectors: state.sectores.list,
  maxCount: state.sectores.count
});
const mapDispatchToProps = dispatch => ({
  listSectors: page => dispatch(fetchList(page))
});


export default connect(mapStateToProps, mapDispatchToProps)(SectorList);
