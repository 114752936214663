import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import Trash from '../../../images/trash.svg';
import handle from '../../../images/handler.svg';


const DragHandle = sortableHandle(() => <img className="handler" src={handle} alt="handle" />);


const Field = SortableElement(({
  fields, availableFields, onFieldDataChange, removeField, fieldData, showErrors, schema}) => {
  const onChangePercentage = (e) => {
    const perc = parseFloat(e.target.value, 10)
    onFieldDataChange({ ...fieldData, percentage: isNaN(perc) ? 0 : perc });
  };

  const onChangeField = (e) => {
    onFieldDataChange({ ...fieldData, field_id: e.target.value });
  };

  const freeOrOwn = item => (
    availableFields.includes(item[0]) || item[0] === fieldData.field_id
  );
  return (
    <div className="valoracion-field">
      <DragHandle />
      <div className="ponderacion-item new-field">
        <input className={showErrors && (fieldData.percentage < 0 || fieldData.percentage > 100) ? 'valoracion-error' : ''}
          type="number" defaultValue={fieldData.percentage} onChange={onChangePercentage}
        />
        <select className={showErrors && (fieldData.field_id === '' || schema && !schema.fields.find(f=>f.id===fieldData.field_id)) ? 'valoracion-error' : ''}
          defaultValue={fieldData.field_id} onChange={onChangeField}
        >
          <option value="">------</option>
          {Object.entries(fields).filter(freeOrOwn).map(([key, value]) => (
            <option key={`field-opt-${key}`} value={key}>{`${value.num} ${value.title}`}</option>
          ))}
        </select>
      </div>
      <button type="button" className="delete-button-ponderacion" onClick={removeField}>
        <img src={Trash} alt="" width="18px" />
      </button>
    </div>
  );
});


Field.propTypes = {
  fields: PropTypes.object.isRequired,
  availableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFieldDataChange: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
  fieldData: PropTypes.object.isRequired,
  showErrors: PropTypes.bool
};


export default Field;
