import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import EditField from './EditField';
import handle from '../../../images/handler.svg';


const DragHandle = sortableHandle(() => <img className="handler-section" src={handle} alt="handle" />);

const Field = SortableElement((
  {
    active,
    info,
    onSelectField,
    onChangeField,
    onHoverField,
    onRemove,
    insideSection,
    setInsideSection,
    sec,
    setCurrentSec,
    changesLimited,
    handleMoveField,
    form_editable,
    validated,
    errors
   }
) => {
  // eslint-disable-next-line
  const Component = require(`../../../pulpoforms/builder/fields/${info.type}/show`).default;
  const componentProps = {
    active, onSelectField, changesLimited, validated, form_editable, handleMoveField, sec, onChangeField, onRemove, ...info
  };
  const field = useRef(null);

  // this function updates the add button position
  // to align with the current active field
  const updateButtonPosition = () => {
    if (insideSection) {
      setCurrentSec(sec);
    }
    setInsideSection(insideSection);
    if (form_editable){
      const addButton = document.getElementsByClassName('add-btn')[0];
      addButton.style.top = `${field.current.offsetTop - 105}px`;
    }
    onHoverField(info.id);
  };
  const errorMsg = errors && errors.errors && errors.errors.find(e => e.id===info.id)
  const displayMessage = (msg) =>{
    if (msg.message === "Missing field's title"){
      return "La pregunta no tiene título"
    }
    else if (msg.message === "MO has no options"){
      return "El multiple opción no tiene opciones"
    }
    else if (msg.message === "Matrix has no rows"){
      return "La matriz no tiene filas"
    }
    else if (msg.message === "Matrix has no columns"){
      return "La matriz no tiene columnas"
    }
    else {
      return msg.message
    }
  }
  return (
    <>
    <div className="builder-field" ref={field} onMouseEnter={updateButtonPosition}>
      {form_editable ? <DragHandle /> : <span className="left-margin"></span> }
      {active
        ? <EditField {...componentProps} />
        : <Component {...componentProps} />}
    </div>
      {errorMsg && <div className="feedback-error">{displayMessage(errorMsg)}</div> }
    </>
  );
});

Field.propTypes = {
  info: PropTypes.object.isRequired,
  onSelectField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  active: PropTypes.bool,
  onRemove: PropTypes.func.isRequired
};

Field.defaultProps = {
  active: false
};

export default Field;
