import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ConfirmationScreen = ({ title, content, link }) => (
  <div className="confirmation">
    <h2>{title}</h2>
    <p>{content}</p>
    {link.url && <Link to={link.url}>{link.text}</Link>}
    {link.reload && <a href="javascript:history.go(0);">{link.text}</a>}
  </div>
);

ConfirmationScreen.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired
};
export default ConfirmationScreen;
