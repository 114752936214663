/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Transition } from 'react-spring/renderprops';

import useConditionals from '../../../hooks/useConditionals';
import Conditional from '../../../conditionals/Conditional';
import { transitions } from '../../../utils';

const EditMatrix = ({ id, rows, columns, conditionals, onChangeField, schemaFields, widget, form_editable }) => {
  const [maxColID, setMaxColumn] = useState(-1);
  const [maxRowID, setMaxRow] = useState(-1);
  const [conds, setConds] = useConditionals(conditionals);


  useEffect(() => {
    if (conds) {
      onChangeField(id, 'conditionals', conds);
      onChangeField(id, 'hidden', true);
    }
  }, [conds]);


  /**
   *  chequea si la fila/columna esta vacia y la inicializa
   *  filas columnas pueedn estar vacia si el tipo de campo no es select
   * */
  useEffect(() => {
    let maxRow = -1;
    let maxColum = -1;
    if (!rows || rows.length === 0) {
      onChangeField(id, 'rows', []);
      maxRow = 0;
    } else {
      maxRow = Math.max(...rows.map(f => parseInt(f.key, 10)));
    }

    if (!columns || columns.length === 0) {
      onChangeField(id, 'columns', []);
      maxColum = 0;
    } else {
      maxColum = Math.max(...columns.map(f => parseInt(f.key, 10)));
    }
    setMaxRow(maxRow);
    setMaxColumn(maxColum);
  }, []);


  /**
   *  Handler para manejar cambios en los valores seleccionados
   * */
  const onOptionsChange = (k, event, values, type) => {
    const newValues = [...values];
    const toChange = values.findIndex(f => f.key === k);
    newValues[toChange].value = event.target.value;
    onChangeField(id, type, newValues);
  };

  /**
   *  Handler para agregar nueva opciones
   * */
  const onAddColumn = () => {
    const newMaxID = maxColID + 1;
    const newValues = [...columns, { key: `${newMaxID}`, value: '' }];
    onChangeField(id, 'columns', newValues);
    setMaxColumn(newMaxID);
  };

  const onAddRow = () => {
    const newMaxID = maxRowID + 1;
    const newValues = [...rows, { key: `${newMaxID}`, value: '' }];
    onChangeField(id, 'rows', newValues);
    setMaxRow(newMaxID);
  };

  /**
   *  Handler para remover columna/fila seleccionada
   * */
  const removeOption = (key, values, type) => {
    const newValues = values.filter(v => v.key !== key);
    onChangeField(id, type, newValues);
  };

  return (
    <>
      <div className="matrix">
        <div className="select-values">
          <label htmlFor="input">
        Filas
            {rows && rows.map(v => (
              <div className="value">
                <input disabled={!form_editable} id={v.key} key={v.key} defaultValue={v.value}
                  onChange={e => onOptionsChange(v.key, e, rows, 'rows')}
                />
                {form_editable ? <button className="remove-btn" type="button" onClick={() => removeOption(v.key, rows, 'rows')}>&times;</button> : null}
              </div>
            ))}
            {form_editable ? <button className="add-option" type="button" onClick={onAddRow}>Agregar fila</button> : null}
          </label>
        </div>
        <div className="select-values">
          <label htmlFor="input">
        Columnas
            {columns && columns.map(v => (
              <div className="value">
                <input disabled={!form_editable} id={v.key} key={v.key} defaultValue={v.value}
                  onChange={e => onOptionsChange(v.key, e, columns, 'columns')}
                />
                {form_editable ? <button className="remove-btn" type="button" onClick={() => removeOption(v.key, columns, 'columns')}>&times;</button> : null}
              </div>
            ))}
            {form_editable ? <button className="add-option" type="button" onClick={onAddColumn}>Agregar columna</button> : null}
          </label>
        </div>
      </div>
      <Transition items={conditionals !== undefined} {...transitions}>
        {show => show && (props => (
          <label className="conditionals" style={props}>
            Lógica
            {conds && conds.length > 0 && (
              <div>
                {conds.map((cond, i) => (
                  <Conditional key={i} index={i} data={cond}
                    fields={schemaFields.filter(f => f.id !== id)} onChange={setConds}
                  />
                ))}
              </div>
            )}
            <button className="add-option" type="button" onClick={setConds}>
              Agregar
            </button>
          </label>
        ))}
      </Transition>
    </>
  );
};

EditMatrix.propTypes = {
  onChangeField: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = state => ({
  schemaFields: state.forms.fields,
  form_editable: state.sectores.sector.form_editable,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMatrix);
