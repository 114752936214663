import {
  FETCH_LIST,
  FETCH_SECTOR,
  SAVE_SECTOR,
  FETCH_ANSWER_ID,
  CLEAR_ACTIONS,
  FETCH_SECTOR_FULL,
  SAVE_SECTOR_FULL,
  ENABLE_VALS,
  FETCH_ESTRUCTURA
} from './types';


const BACKEND_API = 'BACKEND_API';

export const fetchList = page => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_LIST,
    method: 'GET',
    url: `/sectores/?page=${page}`
  }
});

export const fetchSector = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_SECTOR,
    method: 'GET',
    url: `/sectores/${id}/`
  }
});

export const fetchAnswerId = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_ANSWER_ID,
    method: 'post',
    url: `/sectores/${id}/answer_id/`
  }
});

export const enableValidaciones = id => ({
  type: BACKEND_API,
  payload: {
    ...ENABLE_VALS,
    method: 'POST',
    url: `/sectores/${id}/habilitar_validaciones/`
  }
});

export const fetchFullSector = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_SECTOR_FULL,
    method: 'GET',
    url: `/sectores/${id}/full`
  }
});

export const fetchEstructuraOcupacional = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_ESTRUCTURA,
    method: 'GET',
    url: `/sectores/${id}/estructura_ocupacional`
  }
});

export const updateSectorForValoration = (id, data) => ({
  type: BACKEND_API,
  payload: {
    ...SAVE_SECTOR_FULL,
    method: 'PATCH',
    url: `/sectores/${id}/`,
    data
  }
});

export const updateSector = (id, data) => ({
  type: BACKEND_API,
  payload: {
    ...SAVE_SECTOR,
    method: 'PATCH',
    url: `/sectores/${id}/`,
    data
  }
});

export const clearActions = () => ({
  type: CLEAR_ACTIONS
});