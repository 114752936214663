import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import Field from './Field';
import Section from './Section';


const FormContainer = SortableContainer((
  { content,
    active,
    onSelectField,
    onChangeField,
    onChangeFields,
    onHoverField,
    onRemove,
    onRemoveSection,
    onSortEnd,
    allFields,
    sections,
    onChangeSections,
    onChangeSectionTitle,
    setInsideSection,
    setCurrentSec,
    changesLimited,
    handleMoveField,
    form_editable,
    validated,
    errors
  }
) => {
  /*
   Handler for remove fields that are inside Section
  */
  const onRemoveFieldSection = (removeId) => {
    // remove field from section
    content.map((c) => {
      if (c.id.includes('SEC')) {
        c.fields = c.fields.filter(f => f !== removeId);
        if (c.fields.length === 0) {
          onRemoveSection(c.id);
        }
      }
      return c;
    });
    onChangeSections([...sections]);

    // remove field from all Fields
    const updateFields = allFields.filter(f => f.id !== removeId);
    onChangeFields(updateFields);
  };

  return (
    <div className="fields-container">
      {content.map((f, i) => (
        !f.id.includes('SEC')
          ? (
            <Field key={`item-${f.id}`} index={i} changesLimited={changesLimited}
              info={f} active={active === f.id} onSelectField={onSelectField}
              onChangeField={onChangeField} onHoverField={onHoverField} onRemove={onRemove}
              collection={0} insideSection={false} setInsideSection={setInsideSection}
              handleMoveField={handleMoveField} form_editable={form_editable}
              validated={validated} errors={errors}
            />
          )
          : (
            <Section content={content} sec={f} allFields={allFields} key={`item-${f.id}`} index={i}
              active={active} onSelectField={onSelectField} onChangeField={onChangeField}
              onHoverField={onHoverField} onRemove={onRemoveFieldSection}
              onRemoveSection={onRemoveSection} collection={0} sections={sections}
              onChangeSections={onChangeSections} onChangeSectionTitle={onChangeSectionTitle}
              onSortEnd={onSortEnd} insideSection setInsideSection={setInsideSection} setCurrentSec={setCurrentSec}
              changesLimited={changesLimited} handleMoveField={handleMoveField} form_editable={form_editable}
              validated={validated} errors={errors}
            />
          )
      ))}
    </div>
  );
});


export default FormContainer;
