import React from 'react';
import PropTypes from 'prop-types';

const Select = ({ free_interpretation, readOnly, id, answer, onAnswerChange, onOthersCHange, values, quantities }) => {
  
  return(
    <ol className="multiple-choice">
      {values.map(op => (
        <li key={op.key}>
          <div className="radio">
            <label>
              {op.value} 
              <input type="radio" disabled={readOnly} onChange={() => onAnswerChange(id, op.key)}
                  name={id} defaultChecked={op.key === 'otros - ' ? answer.includes('otros - ') :answer === op.key}
                />
            </label>
            {quantities && 
              <>
                <div className="info-radio-arrow"/>
                <div className="info-radio">
                  <p>{`${quantities[op.key].quantity} / ${quantities.total}`}</p>
                </div>
              </>
            }
          </div>
          
        </li>
        
      ))}
      { values.find(op => op.key.includes('otros - ')) &&
      <div> 
        <input disabled={readOnly} name={id} id={id} type="text"
          onChange={e => onOthersCHange(id, e.target.value)} 
          value={answer && answer.substring(8)} disabled={!(answer && answer.includes('otros - '))}
          className={!(answer && answer.includes('otros - ')) && "input-others-disabled"}
        />
      </div>
      }
    </ol>
)};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool.isRequired,
  answer: PropTypes.string
};

Select.defaultProps = {
  answer: ''
};

export default Select;
