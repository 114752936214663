const asyncActionType = type => ({
  pending: `${type}_PENDING`,
  success: `${type}_SUCCESS`,
  error: `${type}_ERROR`
});

export const FETCH_LIST = asyncActionType('cetfor/profiles/LIST');
export const FETCH_PDF = asyncActionType('cetfor/profiles/PDF');
export const UPDATE_TEMPANSWER = asyncActionType('cetfor/profiles/UPDATE_TEMPANSWER');
export const SAVE_PROFILE = asyncActionType('cetfor/profiles/SAVE');
export const FETCH_PROFILE = asyncActionType('cetfor/profiles/FETCH');
export const UPDATE_PROFILE = asyncActionType('cetfor/profiles/UPDATE');
export const FETCH_ANSWERS = asyncActionType('cetfor/profiles/FETCH_ANSWERS');
export const FETCH_VALORACION_LIST = asyncActionType('cetfor/profiles/FETCH_VALORACION_LIST');

export const CLEAR_ACTIONS = 'cetfor/profiles/CLEAR';
export const CLEAR_DELAYED_PROFILE = 'cetfor/profiles/CLEAR_DELAYED_PROFILE';
