/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';


const PrimaryButton = ({ text, onClick, type }) => (
  <button className="primary-btn" type={type} onClick={onClick}>{text}</button>
);

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
PrimaryButton.defaultProps = {
  type: 'button'
};

export default PrimaryButton;
