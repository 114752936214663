
import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import Auth from '../../utils/Auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Auth.getInstance().isAuthenticated() === true
      ? <Component {...props} />
      : <Redirect to="/login" />
  )}
  />
);


export default PrivateRoute;
