import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

registerLocale('es', es);

const Datetime = ({ readOnly, id, answer, onAnswerChange }) => {
  const [startDate, setStartDate] = useState(answer ? new window.Date(answer) : '');

  const handleChange = (val) => {
    setStartDate(val);
    onAnswerChange(id, val);
  };

  return (
    <>
      <DatePicker
        disabled={readOnly}
        locale="es" showTimeSelect
        dateFormat="d MMMM yyyy - HH:mm'hs'"
        selected={startDate}
        onChange={handleChange}
      />
    </>
  );
};

Datetime.propTypes = {
  id: PropTypes.string.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  widget: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired
};
export default Datetime;
