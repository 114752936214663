import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from './components/Table';
import Paginator from '../../../components/paginator';
import PrimaryButton from '../../../components/PrimaryButton';
import { fetchListWithValoracion } from '../../Profiles/redux/actions';
import { fetchSector } from '../../Sectors/redux/actions';


const ValoracionList = ({ match, location, fetch, getSector, list, sector, status }) => {
  useEffect(() => {
    fetch(match.params.id);
    getSector(match.params.id);
  }, []);

  return (
    <>
      <div className="page-title-nav">
        <h1>Cargos/Roles valorados</h1>
        <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
      </div>
      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">Sector</span>
            {sector && sector.nombre}
          </div>
        </div>
        <div className="content profiles">
          <div className="filters">
            <select name="" id="">
              <option value="">Seleccione una opción </option>
            </select>
            <PrimaryButton text="Filtrar" onClick={() => console.log('')} />
          </div>
          <div className="scroll-table">
            <Table location={location} data={list} />
          </div>
          {/* <Paginator max={20} onChange={e => console.log(e)} /> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  list: state.profiles.valoraciones,
  sector: state.sectores.sector
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(fetchListWithValoracion(id)),
  getSector: id => dispatch(fetchSector(id))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValoracionList);
