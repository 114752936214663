import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Table = ({ data }) => {
  const getClassName = (estado) => {
    switch (estado) {
      case 'CF':
        return 'badge gray';
      case 'FD':
        return 'badge green';
      case 'CP':
        return 'badge orange';
      case 'PD':
        return 'badge blue';
      default:
        return '';
    }
  };
  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: '30%' }}>Nombre</th>
          <th style={{ width: '50%' }}>Responsable</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        {data.map(d => (
          <tr>
            <td>
              {d.externo ?
              <a href={d.link_externo}>{d.nombre}</a>:
              <Link to={ `/sectores/${d.id}/`}>{d.nombre}</Link>
              }
            </td>
            <td>{d.responsable.map((a, indx)=>(`${a}${indx+1==d.responsable.length?'':', '}`))}</td>
            <td>
              <span className={getClassName(d.estado.key)}>{d.estado.value}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default Table;
