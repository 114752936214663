import React from 'react';
import PropTypes from 'prop-types';
import Clickable from '../../../../../components/Clickable';

const Number = ({ id, title, onSelectField }) => (
  <Clickable onClick={() => onSelectField(id)}>
    <label id={id}>
      {title}
      <input type="number" disabled placeholder="Respuesta numérica" />
    </label>
  </Clickable>
);

Number.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSelectField: PropTypes.func.isRequired
};
export default Number;
