import { FETCH_SCHEMA, SAVE_SCHEMA, CLEAR_ACTIONS, CLEAR_ACTIONS_FLAGS } from './types';


const INITIAL_STATE = {
  schema: undefined,
  fields: [],
  saveStatus: {
    success: false,
    loading: false,
    message: ''
  }
};

export const actionForState = (action, state, message) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  error: state === 'error',
  message: message || state
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*          ACTION STATUS           */
    /* ******************************** */
    case 'FORMS':
      return {
        ...state,
        fields: action.payload.fields
      };
    case FETCH_SCHEMA.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_SCHEMA.success:
      return {
        ...state,
        validado: action.payload.validado,
        puedeEncuestar: action.payload.puede_encuestar,
        tienePerfil: action.payload.tienePerfil,
        perfilID: action.payload.perfilId,
        perfilNombre: action.payload.perfilNombre,
        schema: action.payload.current_version.schema,
        status: action.payload.current_version.status,
        action: actionForState(state.action, 'success')
      };
    case FETCH_SCHEMA.error:
      return {
        ...state,
        action: actionForState(state.action, 'error', action.payload.response ? action.payload.response.data : action.payload.message)
      };
    case SAVE_SCHEMA.pending:
      return {
        ...state,
        saveStatus: actionForState(state.action, 'pending')
      };
    case SAVE_SCHEMA.success:
      return {
        ...state,
        // schema: action.payload.current_version.schema,
        schema: {
          ...state.schema,
          pages: action.payload.current_version.schema.pages,
          fields: action.payload.current_version.schema.fields,
          sections: action.payload.current_version.schema.sections
        },
        saveStatus: actionForState(state.action, 'success')
      };
    case SAVE_SCHEMA.error:
      return {
        ...state,
        saveStatus: actionForState(state.action, 'error',  action.payload.response ? action.payload.response.data : action.payload.message)
      };
    case CLEAR_ACTIONS: {
      return {
        ...state,
        schema: {
          id: '',
          version: 1,
          fields: [],
          sections: [],
          pages: []
        },
        saveStatus: {
          success: false,
          loading: false,
          message: ''
        }
      };
    }
    case CLEAR_ACTIONS_FLAGS: {
      return {
        ...state,
        // schema: undefined,
        saveStatus: {
          success: false,
          loading: false,
          message: ''
        }
      };
    }
    default:
      return state;
  }
}
