import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc';

import useModal from '../../../hooks/useModal';
import ConfirmModal from '../../../components/ConfirmModal';
import Trash from '../../../images/trash.svg';


import SectionContainer from './SectionContainer';

import handle from '../../../images/handler.svg';

const DragHandle = sortableHandle(() => <img className="handler" src={handle} alt="handle" />);

const Section = SortableElement(({
  sec,
  allFields,
  active,
  onSelectField,
  onChangeField,
  onHoverField,
  onRemove,
  onRemoveSection,
  sections,
  onChangeSections,
  onChangeSectionTitle,
  setInsideSection,
  setCurrentSec,
  handleMoveField,
  changesLimited,
  form_editable,
  errors
}) => {
  const [activeModal, toggleModal] = useModal();
  const [removeSection, setRemoveSection] = useState(-1);

  // sec es de la forma: { FIELDS: [FIELD1,FIELD2], ID:SEC9 ... }
  const contentInSection = sec.fields.map(secField => allFields.find(f => f.id === secField));
  // contentInSection es de al forma:[ { ID:FIELD1,TITLE:... } ...]


  /*
  Handler for updating section title
  */
  const onChangeTitleSection = (e) => {
    sec.title = e.target.value;
    onChangeSections([...sections]);
    onChangeSectionTitle(sec.title);
  };

  /*
  Handler for sort fields inside sections
  */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const currentSec = sec.fields;
    const newCurrent = arrayMove(currentSec, oldIndex, newIndex);
    sec.fields = newCurrent;
    onChangeSections([...sections]);
    const div = document.getElementsByClassName('fields-container')[0];
    div.classList.remove('dragging-section');
  };

  const onSortStart = () => {
    const div = document.getElementsByClassName('fields-container')[0];
    div.classList.add('dragging-section');
  };

  const handleDelete = (sectionId) => {
    toggleModal();
    setRemoveSection(sectionId);
  };

  const handleConfirm = () => {
    onRemoveSection(removeSection);
    toggleModal();
  };


  return (
    <>
      <div className="form-section">
        <div className="section-title">
          {form_editable ? <DragHandle /> : null}
          <label className={`page-title ${sec.title === '' && 'field-error'}`}>
            <div className="form-group">
              <input disabled={!form_editable} type="text" defaultValue={sec.title} onChange={e => onChangeTitleSection(e)} />
              {sec.title === '' && <span className="error-message">Inserte un nombre de sección válido</span>}
            </div>
          </label>
          {form_editable ? <button type="button" className="delete-section" onClick={() => handleDelete(sec.id)}>
            <img src={Trash} alt="" width="20px" />
          </button> : null}
        </div>
        <SectionContainer content={contentInSection} sec={sec}
          active={active} onSelectField={onSelectField} onChangeField={onChangeField}
          onHoverField={onHoverField} onRemove={onRemove} useDragHandle lockAxis="y"
          helperClass="drag-section" insideSection onSortEnd={onSortEnd} onSortStart={onSortStart}
          setInsideSection={setInsideSection} setCurrentSec={setCurrentSec} changesLimited={changesLimited}
          handleMoveField={handleMoveField} form_editable={form_editable} errors={errors}
        />
        <ConfirmModal active={activeModal} title="Borrar Sección" content="Está seguro que desea eliminar la sección ?"
          onConfirm={() => handleConfirm()} onCancel={toggleModal}
        />
      </div>
    </>
  );
});

Section.propTypes = {
  sec: PropTypes.arrayOf.isRequired,
  allFields: PropTypes.arrayOf.isRequired,
  active: PropTypes.func.isRequired,
  onSelectField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onHoverField: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired
};


export default Section;
