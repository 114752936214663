import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { fetchValoracion } from '../redux/actions';
import { printProfileDescription } from '../../Profiles/redux/actions';
import SectionDetail from './components/SectionDetail';
import PrimaryButton from '../../../components/PrimaryButton';


const ValoracionDetail = ({ match, valoracion, fetch, print }) => {
  useEffect(() => {
    fetch(match.params.id);
  }, []);

  const handlePrint = () => {
    print(valoracion.perfil.id);
  };

  let perfil = null;
  let missingFields = [];

  if (valoracion) {
    perfil = valoracion.perfil.perfil;
    missingFields = valoracion.schema.fields.filter(field => (
      field.rate && (!(field.id in perfil) || (perfil[field.id] === '' || perfil[field.id] === []))));
  }

  if (!valoracion) return null;
  return (
    <div className="valoracion">
      <div className="page-title-nav">
        <h1>Valoración</h1>
        <Link to={`/sectores/${valoracion.sector.id}/`}>Volver al sector</Link>
      </div>

      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">Sector</span>
            {valoracion.sector.nombre}
          </div>
          <div className="info">
            <span className="label">Cargo/Rol</span>
            {valoracion.perfil.nombre}
          </div>
          <div className="info total">
            <span className="label">Total Valoración</span>
            <span>{valoracion.total}</span>
          </div>
            <Link to={`/perfiles/${valoracion.perfil.id}/imprimir`} onClick={handlePrint} className="primary-btn">Imprimir</Link>
        </div>

        <div className="content">
          {valoracion.resultados && valoracion.resultados.content ? (
            valoracion.resultados.content.map(section => (
              <SectionDetail key={`sec-${section.id}`} level={1} fields={valoracion.schema.fields} section={section} />
            ))
          ) : (
            <p>
              No fue posible calcular la valoración.
              { missingFields.length ? (
                <>
                  <br/> Faltan completar los siguientes campos:
                  {missingFields.map(field => <li>{field.title}</li>)}
                </>
              ) : null }
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

ValoracionDetail.propTypes = {
  match: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  valoracion: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  valoracion: state.valoraciones.valoracion,
  action: state.profiles.action
});

const mapDispatchToProps = dispatch => ({
  fetch: profileId => dispatch(fetchValoracion(profileId)),
  print: profileId => dispatch(printProfileDescription(profileId))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValoracionDetail);
