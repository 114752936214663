import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../pulpoforms/pulpovalidator';
import AnswerField from '../../pulpoforms/answer/AnswerField';
import ProgressBar from './components/ProgressBar';
import AnswerSection from './components/AnswerSection';
import { fetchForm, fetchFormUUID } from '../FormBuilder/redux/actions';
import { saveAnswer, saveAnswerUUID, clearActions as answerClear, fetchAnswer, fetchAnswerRevisar, updateAnswer } from './redux/actions';
import {fetchOriginalAnswer} from './redux/actions';
import { fetchList } from '../Profiles/redux/actions';
import PrimaryButton from '../../components/PrimaryButton';
import Spinner from '../../components/Spinner';
import ConfirmationScreen from '../../components/ConfirmationScreen';
import moment from 'moment';
import { Prompt } from 'react-router-dom';

const isSection = sectionID => sectionID.includes('SEC');

const getAllPageFields = (schema, page) => (
  page.content.map(c => (
    isSection(c)
      ? schema.sections.find(s => s.id === c).fields
      : c
  )).flat()
);

const AnswerForm = ({
  match, print, revisar, wizard, schema, tienePerfil, puedeEncuestar, perfilID, perfilNombre, fetch,
  fetchUUID, answerSchema, save, saveUuid, sector_id, clear, profileList, fetchProfiles,
  listAnswer, getAnswerRevisar, update, saveStatus, answerAction, formError, formErrorMessage, original, listOriginalAnswer }) => {
  
  /**
   * muestra el formulario en la opcion de imprimir,
   * para procesarlo, para llenarlos y
   * para ver la respuesta original.
   * Cuidado al modificarlo
   */
  
  // Hooks
  const [currentPage, setCurrentPage] = useState(undefined);
  const [answers, setAnswers] = useState(undefined);
  const [errors, setErrors] = useState({});
  const [localError, setLocalError] = useState({ error: false });
  const [profile, setProfile] = useState();
  const [profileValue, setProfileValue] = useState();
  const [Tiene_Perfil, setTiene_Perfil] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const refPerfiles = useRef(null)

  useEffect(() => {
    const id = match.params.encoded
      ? window.atob(match.params.encoded)
      : match.params.id;

    const uuid = match.params.uuid;
    if (wizard) {
      if (original){
        listOriginalAnswer(match.params.answerId)
      }
      else{
        listAnswer(match.params.answerId);
      }
    } else if (revisar) {
      getAnswerRevisar(match.params.answerId);
    } else {
      if (uuid) {
        fetchUUID(uuid)
      } else {
        fetch(id);
      }
      fetchProfiles(id, {page: 'all'});
    }
    return () => { clear(); };
  }, []);

  useEffect(() => {
    if (tienePerfil) {
      setTiene_Perfil(true);
      setProfile({ value: perfilID, text: perfilNombre });
    } else {
      setTiene_Perfil(false);
    }
  }, [tienePerfil]);

  /**
   * crea el mapa de respuestas
   * */
  useEffect(() => {
    if (schema) {
      const prevAns = window.localStorage.getItem(`answers-${match.params.id}`);
      if (prevAns) {
        setAnswers(JSON.parse(prevAns));
      } else {
        const newAnswers = {};
        schema.fields.forEach((f) => { newAnswers[f.id] = ''; });
        setAnswers(newAnswers);
      }
    }
  }, [schema]);

  useEffect(() => {
    if ((revisar || wizard) && answerSchema) {
      setAnswers(answerSchema.response);
      setProfile({ ...answerSchema.perfil });
    }
  }, [answerSchema]);

  /**
   * Selecciona la primera hoja como la actual
   * */
  useEffect(() => {
    if (schema) {
      setCurrentPage(schema.pages[0]);
      // se espera a que el formulario este renderizado
      if (print) {
        setTimeout(() => {
          window.print();
          window.location.replace(`/sectores/${match.params.id}/`);
        }, 1000);
      }
      if (wizard) {
        setCurrentPage( schema.pages[schema.pages.length -1]);
      }
    }
  }, [schema]);

  useEffect(() => {
    setLocalError({ error: false });
  }, [currentPage]);

  useEffect(() => {
    if (saveStatus.success) {
      setConfirmation(true);
      const id = match.params.encoded
        ? window.atob(match.params.encoded)
        : match.params.id;
      window.localStorage.removeItem(`answers-${id}`);
    }
  }, [saveStatus]);


  let contentPage;
  if(wizard){
    contentPage = schema && schema.pages.map(page => page.content.map((c) => {
        const collection = isSection(c) ? 'sections' : 'fields';
        return schema[collection].find(e => e.id === c);
      }));
  }
  else{
    if (print) {
      contentPage = schema && schema.pages.map(page => page.content.map((c) => {
        const collection = isSection(c) ? 'sections' : 'fields';
        return schema[collection].find(e => e.id === c);
      }).filter(e => e));
    } else {
      contentPage = schema && currentPage && [currentPage.content.map((c) => {
        const collection = isSection(c) ? 'sections' : 'fields';
        return schema[collection].find(e => e.id === c);
      }).filter(e => e)];
    }
  }

  const indexCurrentPage = schema && currentPage && schema.pages.findIndex(p => p.id === currentPage.id);

  /**
   * handles the answer value changes.
   */
  const handleAnswerChange = (id, value) => {
    setAnswers({ ...answers, [id]: value });
    const newErrors = { ...errors };
    delete newErrors[id];
    setLocalError({ error: false });
    setErrors(newErrors);
  };

  const handleAnswerChangeSelectOthers = (id, value) => {
    setAnswers({ ...answers, [id]: "otros - " + value });
    const newErrors = { ...errors };
    delete newErrors[id];
    setLocalError({ error: false });
    setErrors(newErrors);
  };

  const handleOthersMultiSelect = (id, value) => {
    let multiSelect = answers[id].filter(v => !v.includes('otros - '))
    multiSelect.push('otros - ' + value)
    setAnswers({ ...answers, [id]: multiSelect });
    const newErrors = { ...errors };
    delete newErrors[id];
    setLocalError({ error: false });
    setErrors(newErrors);
  };

  /**
   * handles the page change action
   * It Validate if the is correct.
   */
  const handlePageChange = () => {
    // chequea errores en la respuesta
    const autocompletado = !puedeEncuestar;
    const json = window.PulpoValidator.validateAnswer(schema, answers, autocompletado);
    let hasErrors = false;
    if (json.result !== 'OK') {
      // Tenemos qu chequear si hay errores
      // en la página actual
      const fieldsID = getAllPageFields(schema, currentPage);
      const newErrors = {};
      fieldsID.forEach((f) => {
        const error = json.errors.find(e => e.id === f);
        if (error) newErrors[error.id] = error.message;
      });
      setErrors(newErrors);
      hasErrors = Object.keys(newErrors).length > 0;
    }

    if (json.result === 'OK' || !hasErrors) {
      const pagesLength = schema.pages.length;
      if (indexCurrentPage < pagesLength - 1) {
        if (!revisar || !wizard) {
          window.localStorage.setItem(`answers-${match.params.id}`, JSON.stringify(answers));
        }
        setCurrentPage(schema.pages[indexCurrentPage + 1]);
      } else if (json.result === 'OK') {
        const data = {
          perfil: profile.value,
          response: answers
        };
        const id = match.params.encoded
          ? window.atob(match.params.encoded)
          : match.params.id;
        if (!revisar) {
          if (match.params.uuid) {
            saveUuid(match.params.uuid, data);
          } else {
            save(id, data);
          }
        } else {
          const idAnswer = match.params.answerId;
          const newAnswer = { ...answerSchema };
          newAnswer.response = { ...answers };
          newAnswer.version = newAnswer.version.id;
          newAnswer.estado = 'RP';
          newAnswer.perfil = newAnswer.perfil.value;
          update(idAnswer, newAnswer);
        }
      } else {
        setLocalError({
          error: true,
          message: 'Hay Respuestas sin completar, por favor revise nuevamente.'
        });
      }
    }
  };

  /**
   * Chequea si el campo cumple las condiciones
   * */
  const satisfyConditional = (fieldId) => {
    const field = schema.fields.find(f => f.id === fieldId);
    if (!field.conditionals || field.conditionals.length === 0) {
      return true;
    }
    let meetConditions = false;

    field.conditionals.forEach((cond) => {
      const target = schema.fields.find(f => f.id === cond.field);
      if (target.type === 'multiselect') {
        meetConditions = answers[cond.field].includes(cond.value);
      } else {
        meetConditions = answers[cond.field] === cond.value;
      }
    });
    return meetConditions;
  };

  if (confirmation) {
    const link = {};
    let content = 'Su respuesta fue guardada con éxito';
    if (revisar) {
      link.url = `/sectores/${answerSchema.sector}/respuestas`;
      link.reload = false;
      link.text = 'Volver al listado';
    } else if (wizard) {
      content = '';
      link.url = `/perfiles/${answerSchema.perfil}/wizard`;
      link.reload = false;
      link.text = 'Volver al wizard';
    } else if (match.params.uuid == null) {
      link.url = '';
      link.reload = true;
      link.text = 'Volver a responder';
    } else {
      link.url = '';
      link.reload = true;
    }
    return (
      <ConfirmationScreen title="Respuesta"
        content={content}
        link={
          { url: link.url, reload: link.reload, text: link.text }
        }
      />
    );
  }

  if (revisar && answerAction && !answerAction.loading && !answerAction.success) {
    return (
      <div>
        <h1>Procesar respuesta</h1>
        <div className="col-container">
          <div>Esta respuesta se encuentra asignada a otro analista.</div>
        </div>
      </div>
    );
  }

  if (formError) {
    //si no puede traer el formulario se muestra un error
    return (
      <div>
        <h1>Error</h1>
        <div className="col-container">
          <div>{formErrorMessage}</div>
        </div>
      </div>
    );
  }

  const onChangePerfiles = e => {
    if (refPerfiles){
      const seleccionado = [...refPerfiles.current.options].find(o => o.label === e.target.value)
      if (seleccionado) {
        setProfileValue({value: seleccionado.dataset.id, text: seleccionado.label})
      }
    }
  }
  
  if (!schema) return null;

  return (
    <div key={answerSchema && answerSchema.id} className="answer-form">
      { print && (
      <div className="print-spinner">
        <Spinner active />
      </div>
      )}
      {!wizard && currentPage && (
      <ProgressBar pages={schema.pages} currentPage={currentPage}
        onSetCurrentPage={setCurrentPage}
      />
      )}
      {currentPage && (
        <>
          <div className="print-col-container col-container">
            <div className="print-content content">
              {/* ******************* FOR SELECTING PROFILE ******************** */}
              {/* ************************************************************** */}
              {!tienePerfil ? ((!profile || print) && (
                <div className="answer-form">
                  <h1>Cargo/Rol</h1>
                  <div className="answer-field-container">
                    <div className="col-left" />
                    <label>
                      ¿Cuál es su cargo/rol?
                      <div className="field-group">
                        <input list="perfiles" id="input_perfil" onChange={e => onChangePerfiles(e)} />
                        <datalist ref={refPerfiles} id="perfiles" >
                          <option data-id="" selected disabled>Seleccione una opción</option>
                          {profileList && profileList.map(p => (
                            <option data-id={p.id}>{p.nombre}</option>
                          ))}
                        </datalist>
                        <span className="tooltip"><pre>Indique un único cargo/rol de la lista por el cual va a responder</pre></span>
                        <ul className="print-options">
                          {profileList && profileList.map(op => <li>{op.nombre}</li>)}
                        </ul>
                      </div>
                    </label>
                  </div>
                  <div className="next-btn">
                    <div className="col-left" />
                    <div className="content">
                      <PrimaryButton text="Siguiente" onClick={() => setProfile(profileValue)} />
                    </div>
                    <div className="col-right" />
                  </div>
                </div>
              )) : null}
              {/* ********************* RENDER QUESTIONS *********************** */}
              {/* ************************************************************** */}
              {(profile || print) && contentPage.map((page, id) => {
                return(
                <>
                  {profile && !wizard && <h1 className="cargo-title">{`Cargo/Rol: ${profile.text}`}</h1>}
                  {wizard &&
                    <div>
                      {id<1 && <h1 className="cargo-title">{`Respuesta: ${match.params.answerId}`}</h1>}
                      {profile && id<1 && <p className="cargo-title">{`Cargo/Rol: ${profile.text}`}</p>}
                      {answerSchema && id<1 && <p className="cargo-title">{`Fecha y hora: ${answerSchema.date ? moment(answerSchema.date).format('DD/MM/YYYY HH:mm'):''}`}</p>}
                    </div>
                  }
                  {!wizard && !print && <h1>{` ${indexCurrentPage + 1} - ${currentPage.title}`}</h1>}
                  {(print || wizard) && <h1>{` ${id + 1} - ${page && schema.pages[id].title}`}</h1>}
                  {page.map((c, idx) => (
                    isSection(c.id)
                      ? (
                        <AnswerSection sec={c} fields={schema.fields}
                          index={`${print || wizard ? id + 1 : indexCurrentPage + 1}.${idx + 1}`}
                          onAnswerChange={handleAnswerChange} onOthersCHange={handleAnswerChangeSelectOthers} key={c.id}
                          errors={errors} answers={print ? {} : answers} readOnly={wizard}
                          printable={print}
                          puedeEncuestar={puedeEncuestar}
                          onOthersCHangeMulti ={handleOthersMultiSelect}
                        />
                      )
                      : (
                        <AnswerField {...c} errors={errors[c.id]}
                          index={`${print || wizard ? id + 1 : indexCurrentPage + 1}.${idx + 1}`}
                          answer={print ? null : answers[c.id]}
                          onAnswerChange={handleAnswerChange} key={c.id}
                          satisfyConditional={print ? true : satisfyConditional(c.id)}
                          readOnly={wizard} printable={print}
                          puedeEncuestar={puedeEncuestar}
                          onOthersCHange={handleAnswerChangeSelectOthers}
                          onOthersCHangeMulti ={handleOthersMultiSelect}
                        />
                      )
                  ))}
                  {id < contentPage.length - 1 && <div className="page-break" />}
                </>
              )})}
            </div>
          </div>
          {profile && (
            <div className="col-container save">
              <div className="content">
                <Spinner active={saveStatus.loading} />
                {saveStatus.error && <span className="feedback-error">{saveStatus.message}</span> }
                {saveStatus.success && <span className="feedback-success">Su Respuesta fue guardada con éxito</span> }
                {localError.error && <span className="feedback-error">{localError.message}</span>}
                {!wizard &&
                  <div className="feedback-error">
                    {Object.keys(errors).length !== 0 && errors.constructor === Object && <div>Hay errores en las respuestas ingresadas</div>}
                    <PrimaryButton text={indexCurrentPage === schema.pages.length - 1 ? 'Enviar respuesta' : 'Guardar y seguir'} onClick={handlePageChange} />
                  </div>
                }
                {/* <PrimaryButton text={indexCurrentPage === schema.pages.length - 1 ? 'Enviar respuesta' : 'Guardar y seguir'} onClick={handlePageChange} /> */}
              </div>
              <div className="col-right" />
            </div>
          )}
        </>
      )}
      <Prompt
        when={saveStatus.loading}
        message="Hay cambios pendientes de guardar. Seguro que quieres salir?"
      />
    </div>
  );
};

AnswerForm.propTypes = {
  match: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  saveStatus: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  print: PropTypes.bool,
  revisar: PropTypes.bool,
  wizard: PropTypes.bool,
  puedeEncuestar: PropTypes.bool
};

AnswerForm.defaultProps = {
  print: false,
  revisar: false,
  wizard: false,
  puedeEncuestar: true
};

const mapStateToProps = state => ({
  schema: (state.answers.answer && state.answers.answer.version.schema) || state.forms.schema,
  tienePerfil: state.forms.tienePerfil,
  puedeEncuestar: state.forms.puedeEncuestar,
  perfilID: state.forms.perfilID,
  perfilNombre: state.forms.perfilNombre,
  answerSchema: state.answers.answer,
  profileList: state.profiles.list,
  saveStatus: state.answers.saveStatus,
  answerAction: state.answers.answerAction,
  formError: state.forms.action ? state.forms.action.error: false,
  formErrorMessage: state.forms.action ? state.forms.action.message: ""
});

const mapDispatchToProps = dispatch => ({
  fetchUUID: uuid => dispatch(fetchFormUUID(uuid)),
  fetch: id => dispatch(fetchForm(id)),
  clear: () => dispatch(answerClear()),
  listAnswer: id => dispatch(fetchAnswer(id)),
  getAnswerRevisar: id => dispatch(fetchAnswerRevisar(id)),
  save: (id, data) => dispatch(saveAnswer(id, data)),
  saveUuid: (uuid, data) => dispatch(saveAnswerUUID(uuid, data)),
  update: (id, data) => dispatch(updateAnswer(id, data)),
  fetchProfiles: (id, page) => dispatch(fetchList(id, page)),
  listOriginalAnswer: id => dispatch(fetchOriginalAnswer(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnswerForm);
