import jwt_decode from 'jwt-decode'; // eslint-disable-line camelcase
// eslint-disable-next-line
import { setHeaders, axiosInstance } from '../config/axios';


const REFRESH_INTERVAL = 1000 * 60 * 60; // Miliseconds
let instance = null;

export default class Auth {
  constructor() {
    if (!instance) {
      this.getAuthToken = this.getAuthToken.bind(this);
      this.setAuthToken = this.setAuthToken.bind(this);

      this.refreshUserToken = this.refreshUserToken.bind(this);
      this.refresherAction = this.refresherAction.bind(this);

      this.isAuthenticated = this.isAuthenticated.bind(this);
      this.isTokenExpired = this.isTokenExpired.bind(this);
      this.decodeToken = this.decodeToken.bind(this);

      this.logout = this.logout.bind(this);

      this.storage = window.localStorage;
      this.refresher = null;

      instance = this;
    }
    return instance;
  }

  static getInstance() {
    if (instance === null) {
      instance = new Auth();
    }
    // Se deshabilita el refresh ya que el token dura un año
    // if (this.refresher === null) {
    //   this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    // }
    return instance;
  }

  /*
   Returns JWT for the backend authentication.
   @returns {String} JWT.
  */
  getAuthToken() {
    return this.storage.getItem('token');
  }

  /*
   Stores the JWT in the browser.
   @returns {String} token.
  */
  setAuthToken(newToken) {
    setHeaders(newToken);
    this.storage.setItem('token', newToken);
    // Se deshabilita el refresh ya que el token dura un año
    // if (this.refresher === null) {
    //   this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    // }
    return newToken;
  }


  refreshUserToken() {
    axiosInstance({
      method: 'post',
      url: '/auth/refresh/',
      data: { token: this.getAuthToken() }
    }).then((response) => {
      this.setAuthToken(response.data.token);
    }).catch(() => {
      clearInterval(this.refresher);
      this.storage.clear();
      window.location.replace('/login');
    });
  }

  /* Indicates if a user is logged in.
   @returns {Boolean} Returns True if a user's token has not expired, and False otherwise.
  */
  isAuthenticated() {
    const res = !this.isTokenExpired();
    if (!res) {
      this.cleanStorage();
    }
    return res;
  }

  /*
   Returns True if the stored token has expired
   @returns {Boolean}
  */
  isTokenExpired() {
    const decoded = this.decodeToken();
    if (!decoded || typeof decoded.exp === 'undefined') {
      return true;
    }
    const d = new Date(0);
    d.setUTCSeconds(decoded.exp);
    if (d === null) {
      return true;
    }
    return ((new Date().valueOf()) > d.valueOf());
  }

  /*
   Decodes stored JWT.
   Does not validate signature.
   @returns {Object} Token decodificado.
  */
  decodeToken() {
    const token = this.getAuthToken();
    let decoded;
    try {
      decoded = jwt_decode(token);
    } catch (err) {
      decoded = null;
    }
    return decoded;
  }

  /*
   Clears user information.
  */
  logout() {
    this.cleanStorage();
    clearInterval(this.refresher);
  }

  /*
  Clears token from storage
  */
  cleanStorage() {
    this.storage.removeItem('token');
  }

  refresherAction() {
    if (this.isAuthenticated()) {
      this.refreshUserToken();
    }
  }


  startRefresher() {
    if (this.refresher === null) {
      const decoded = this.decodeToken();
      if (decoded !== null) {
        const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
        date.setUTCSeconds(decoded.exp);

        const now = new Date();
        const secondsUntilExpiration = Math.floor((date - now) / 1000);
        if (secondsUntilExpiration < 600) {
          this.refresherAction();
        } else if (secondsUntilExpiration < REFRESH_INTERVAL / 1000) {
          this.refresher = setInterval(this.refresherAction, (secondsUntilExpiration - 120) * 1000);
        } else {
          this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
        }
      } else {
        this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
      }
    }
  }
}

// Se deshabilita el refresh ya que el token dura un año
// Auth.getInstance().startRefresher();
Auth.getInstance();
