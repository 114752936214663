import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';


const isSection = sectionID => sectionID.includes('SEC');

const Imprimir = ({ descripcion }) => {

  return (
      
          <div dangerouslySetInnerHTML={{ __html: descripcion }} />
      
  );
};


const mapStateToProps = state => ({
  descripcion: state.profiles.descripcionCargo
});

const mapDispatchToProps = dispatch => ({
 
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Imprimir);
