import React from 'react';
import PropTypes from 'prop-types';

const MultiselectValidator = ({ index, type, value, used, error, onChange }) => {
  const availableValidators = [
    { key: 'maxChoices', text: 'Menos de' },
    { key: 'minChoices', text: 'Mas de' }
  ];

  const onValidatorChange = (event) => {
    const { name, value: newValue } = event.target;
    onChange({ index, type, value, [name]: newValue });
  };

  const validatorsToUse = availableValidators.filter(a => !used.includes(a.key) || type === a.key);

  return (
    <div className="number-validator" key={`${type}-${index}`}>
      <span>Debe seleccionar</span>
      <select name="type" id="" defaultValue={type} onChange={onValidatorChange}>
        <option value="" disabled>Seleccione una opción</option>
        {validatorsToUse.map(op => <option value={op.key}>{op.text}</option>)}
      </select>
      <label className={error ? 'field-error' : ''}>
        <input type="number" name="value" placeholder="valor"
          defaultValue={value} onChange={onValidatorChange}
        />
        {error && <span className="error-message">{error}</span>}
      </label>
      {value > 1 ? 'Opciones' : 'Opción'}
      <button type="button" className="remove-btn" onClick={() => onChange({ index })}>
      &times;
      </button>
    </div>
  );
};
MultiselectValidator.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  used: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
};
export default MultiselectValidator;
