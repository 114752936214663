
import React from 'react';

import { Route } from 'react-router-dom';
import Footer from '../Footer';
import MainContainer from '../MainContainer';
import Header from '../Header';


const PublicRoute = ({ component: Component, ...rest }) => (
  <>
    <Header />
    <MainContainer>
      <Route {...rest} render={props => (
        <Component {...props} />
      )}
      />
    </MainContainer>
    <Footer />
  </>

);


export default PublicRoute;
