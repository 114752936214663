import React from 'react';
import PropTypes from 'prop-types';

const Multiselect = ({ answer, values }) => (
  <div>
    <ol>
      {values.map(op => (
        answer && answer.includes(op.key) && (
          <>
            <div className="wizard-multi-select">
              <span>
                {answer && answer.includes(op.key) && <>&#10004;</>}
              </span>
              <p>
                {op.value}
              </p>
            </div>
          </>
        )
      ))}
    </ol>
  </div>

);

Multiselect.propTypes = {
  answer: PropTypes.object.isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default Multiselect;
