import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import Auth from '../utils/Auth';
import PrivateRoute from '../components/privateRoute';
import PublicRoute from '../components/publicRoute';
import Imprimir from '../modules/Profiles/Imprimir';

import MainLayout from '../modules/MainLayout';
import Login from '../modules/Auth/Login';
import AnswerForm from '../modules/Answers/AnswerForm'

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/login" render={props => (
        Auth.getInstance().isAuthenticated() === false
          ? <Login {...props} />
          : <Redirect to="/sectores" />
      )}
      />
      <PublicRoute exact path="/r/:encoded/:uuid" component={AnswerForm} />
      <PrivateRoute path="/perfiles/:profileId/imprimir" component={Imprimir} />
      <PrivateRoute path="/" component={MainLayout} />
      
    </Switch>
  </Router>
);


export default Routes;
