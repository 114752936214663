import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/* ************************************************************ */
/* Components import */
/* ***************** */
import IconButton from './IconButton';

/* ************************************************************ */
/* Assets import */
/* ************* */
import Hamburguer from '../images/hamburguer.svg';
import Inefop from '../images/inefop-celeste.svg';

const Header = () => {
  const handleLogout = () => {
    window.localStorage.removeItem('token');
    window.location.href = '/';
  };

  const [displayMenu, setDisplayMenu] = useState(false)

  return (
    <>
    <header>
      <Link className="logo" to="/sectores">
        <img src={Inefop} alt="" />
      </Link>

      <nav />

      <div className="icons">
        {/* <IconButton image={User} onClick={() => ({})} /> */}
        <span tabIndex="0" role="button"
          onKeyPress={handleLogout} onClick={handleLogout}
        >
          Cerrar sesión
        </span>
      </div>
      <div className="menu">
        <IconButton image={Hamburguer} onClick={() => {setDisplayMenu( !displayMenu)}} />
      </div>
      
    </header>
    {displayMenu &&
      <div className="menu_out">
      <div className="menu_in">
        <span tabIndex="0" role="button"
          onKeyPress={handleLogout} onClick={handleLogout}
        >
          Cerrar sesión
        </span>
        
      </div>
    </div>

    }
    </>
  );
};

export default Header;
