/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-spring/renderprops';
import Checkbox from '../../../components/Checkbox';
import TypeSelector from './TypeSelector';
import Trash from '../../../images/trash.svg';
import { connect } from 'react-redux';


const transitions = {
  from: { opacity: 0, height: 0 },
  enter: { opacity: 1, height: 'auto' },
  leave: { opacity: 0, height: 0 },
  config: { duration: 100 }
};


const EditField = (props) => {
  const {
    id, type, title, tooltip, widget, required, validators, conditionals,
    pollster, rate, profile,
    onChangeField, onRemove,
    handleMoveField, profile_text,
    sec, changesLimited, isNew, form_editable,
    validated, schema_valoracion,
    free_interpretation
  } = props;

  const [tooltipActive, setTooltipActive] = useState(!!tooltip);
  const [interpretationRate, setInterpretationRate] = useState({
    rate: rate,
    free_interpretation: free_interpretation
  })

  useEffect(() => {
    if (!tooltipActive) onChangeField(id, 'tooltip', '');
  }, [tooltipActive]);
  // eslint-disable-next-line
  const Component = require(`../../../pulpoforms/builder/fields/${type}/edit`).default;

  const noValidators = ['select', 'email', 'date', 'datetime', 'matrix'];

  const onAlterProfileVal = (event, identifier) => {
    onChangeField(identifier, 'rate', event);
    if (!event) {
      onChangeField(identifier, 'profile', !event);
    }
  };

  const changeConditionals = (e) => {
    onChangeField(id, 'conditionals', e.target.checked ? [] : undefined);
    onChangeField(id, 'hidden', e.target.checked);
  };

  const findIdInSchemaValoracion = (id, schema) => {
    if (schema === []){
      return false;
    }
    return schema.find(f => f.field_id === id || findIdInSchemaValoracion(id, f.content))
  }


  return (
    <div className="new-field">
      <div className="field-header">
        <TypeSelector widget={widget} type={type} onChangeField={onChangeField} id={id} disabled={!form_editable || (changesLimited && !isNew)} />
        {form_editable ? <button className="move-to" type="button" onClick={() => handleMoveField(id, title, sec && sec.id)}>Mover a</button> : null}
      </div>
      <label>
        Pregunta para formulario
        <input disabled={!form_editable} type="text" defaultValue={title} autoFocus
          onChange={e => onChangeField(id, 'title', e.target.value)}
        />
      </label>
      <div className="tooltip">
        <Transition items={tooltipActive} {...transitions}>
          {show => show && (tprops => (
            <label style={tprops}>
            Texto de ayuda
              <textarea disabled={!form_editable} className="tooltip-input" type="text" defaultValue={tooltip}
                onChange={e => onChangeField(id, 'tooltip', e.target.value)}
                placeholder="Texto de ayuda"
              />
            </label>
          ))}
        </Transition>
      </div>
      <div className="tooltip">
        <Transition items={profile} {...transitions}>
          {show => show && (tprops => (
            <label style={tprops}>
              Texto para descripción del Cargo/Rol
              <small style={{ margin: '0', color: '#787878' }}>
              Este texto sustituirá la pregunta para formulario en la salida de descripción. 
              En caso de ser vacío se tomará la pregunta para formulario.
              </small>
              <textarea disabled={!form_editable} className="tooltip-input" type="text" defaultValue={profile_text || title}
                onChange={e => onChangeField(id, 'profile_text', e.target.value)}
                placeholder="descripción del campo"
              />
            </label>
          ))}
        </Transition>
      </div>
      <Component interpretation={interpretationRate.free_interpretation} {...props} />
      <div className="options">
        <Checkbox readOnly={!form_editable} checked={tooltipActive} label="Texto de ayuda"
          onChange={e => setTooltipActive(e.target.checked)}
        />
        <Checkbox checked={conditionals && conditionals.length > 0} label="Lógica"
          onChange={changeConditionals} readOnly={!form_editable || (changesLimited && !isNew)}
        />
        {!noValidators.includes(type) && (
        <Checkbox checked={validators && validators.length > 0} label="Condición" readOnly={!form_editable || (changesLimited && !isNew)}
          onChange={e => (onChangeField(id, 'validators', e.target.checked ? [] : undefined))}
        />
        )}
        <Checkbox checked={required} label="Obligatorio" readOnly={!form_editable || (changesLimited && !isNew)}
          onChange={e => onChangeField(id, 'required', e.target.checked)}
        />
        {(form_editable && (!changesLimited || isNew)) && (
          <button type="button" className="delete-field" onClick={() => onRemove(id)}>
            <img src={Trash} alt="" width="20px" />
          </button>
        )}
      </div>
      <div className="options">
        <Checkbox checked={pollster} label="Pregunta Encuestador" readOnly={!form_editable || (changesLimited && !isNew)}
          onChange={e => (onChangeField(id, 'pollster', e.target.checked))}
        />
        <Checkbox checked={profile} label="Descripción del cargo/rol" readOnly={!form_editable || (changesLimited && !isNew)}
          onChange={e => (onChangeField(id, 'profile', e.target.checked))}
        />
        {(type === 'matrix' || type === 'select' || type === 'multiselect') && (
          <Checkbox
            id={'puntua_valoracion'}
            isChecked={interpretationRate.rate}
            checked={rate}
            label="Puntua Valoración"
            readOnly={!validated ? !form_editable : ((findIdInSchemaValoracion(id, schema_valoracion) && !isNew) || !form_editable ) }
            onChange={e => {
              onAlterProfileVal(e.target.checked, id);
              e.target.checked && type !== 'matrix' && onChangeField(id, 'free_interpretation', false);
              setInterpretationRate({
                rate: e.target.checked,
                free_interpretation: false
              })
            }}
          />
        )}
      </div>
      {(type === 'select' || type === 'multiselect') &&
        <div className="options">
            <Checkbox id={'free_interpretation'} isChecked={interpretationRate.free_interpretation} checked={interpretationRate.free_interpretation} label="Habilitar ingreso libre" 
              readOnly={!form_editable || (changesLimited && !isNew)}
              onChange={e => {
                onChangeField(id, 'free_interpretation', e.target.checked);
                e.target.checked && onAlterProfileVal(false, id);
                setInterpretationRate({
                  rate: false,
                  free_interpretation: e.target.checked
                })
              }}
            />
        </div>
      }
    </div>
  );
};

EditField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  validators: PropTypes.arrayOf(PropTypes.object).isRequired,
  conditionals: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
  onChangeField: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  handleMoveField: PropTypes.func.isRequired
};

EditField.defaultProps = {
  required: false
};
const mapStateToProps = state => ({
  schema_valoracion: state.sectores.sector && state.sectores.sector.schema_valoracion,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditField);