import {
  SAVE_ANSWER,
  FETCH_ANSWERS,
  CLEAR_ACTIONS,
  FETCH_LIST_ANSWERS,
  UPDATE_ANSWER,
  FETCH_ANSWER,
  CLEAR_DELAYED_ANSWER } from './types';

const BACKEND_API = 'BACKEND_API';

export const saveAnswer = (id, data) => ({
  type: BACKEND_API,
  payload: {
    ...SAVE_ANSWER,
    method: 'post',
    url: `/sectores/${id}/responder/`,
    data
  }
});

export const saveAnswerUUID = (uuid, data) => ({
  type: BACKEND_API,
  payload: {
    ...SAVE_ANSWER,
    method: 'post',
    url: `/sectores/${uuid}/responderUUID/`,
    data
  }
});

export const updateAnswer = (id, data) => ({
  type: BACKEND_API,
  payload: {
    ...UPDATE_ANSWER,
    method: 'PATCH',
    url: `/answers/${id}/`,
    data
  }
});

export const fetchListPage = (id, params) => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_ANSWERS,
    method: 'get',
    url: `/sectores/${id}/respuestas/`,
    params
  }
});

export const fetchPruebaAnswers = (id, params) => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_ANSWERS,
    method: 'get',
    url: `/sectores/${id}/respuestas/`,
    params: { ...params, prueba: true }
  }
});


export const clearActions = () => ({
  type: CLEAR_ACTIONS
});


export const clearDelayedAnswer = () => ({
  type: CLEAR_DELAYED_ANSWER
});

export const fetchListAnswers = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_LIST_ANSWERS,
    method: 'GET',
    url: `/sectores/${id}/respuestas`
  }
});

export const fetchAnswer = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_ANSWER,
    method: 'GET',
    url: `/answers/${id}`
  }
});

export const fetchAnswerRevisar = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_ANSWER,
    method: 'GET',
    url: `/answers/${id}/revisar`
  }
});

export const fetchOriginalAnswer = id => ({
  type: BACKEND_API,
  payload: {
    ...FETCH_ANSWER,
    method: 'GET',
    url: `/original_answers/${id}`
  }
});