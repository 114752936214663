import React from 'react';
import PropTypes from 'prop-types';
import Clickable from '../../../../../components/Clickable';

const Date = ({ id, title, onSelectField }) => (
  <Clickable onClick={() => onSelectField(id)}>
    <label id={id}>
      {title}
      <input type="text" disabled placeholder="Fecha  dia/mes/año" />
    </label>
  </Clickable>
);

Date.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSelectField: PropTypes.func.isRequired,
  widget: PropTypes.object.isRequired
};
export default Date;
