import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../components/Checkbox';

const Multiselect = ({free_interpretation ,onOthersCHangeMulti, readOnly, id, answer, onAnswerChange, values, quantities }) => {
  const handleChange = (e) => {
    let newChecked = answer ? [...answer] : [];
    if (e.target.checked) {
      newChecked.push(e.target.id);
    } else {
      if (e.target.id === 'otros - '){
        newChecked = answer.filter(ch => !ch.includes('otros - '));
      }
      else{
        newChecked = answer.filter(ch => ch !== e.target.id);
      }
    }

    onAnswerChange(id, newChecked);
  };
  
  return (
    <>
    <ol className="multiple-choice">
      {values.map(op => {
        return(
        <li key={op.key}>
          <Checkbox 
            checked={answer && (answer.includes(op.key) || (op.key.includes('otros - ') && answer.find(v => v.includes('otros - '))))}
            readOnly={readOnly} id={op.key} label={op.value}
            onChange={handleChange} quantity={quantities && quantities[op.key].quantity}
            total={quantities && quantities.total}
          />
        </li>
      )})}
      { values.find(op => op.key.includes('otros - ')) && 
        <div> 
          <input disabled={readOnly} name={id} id={id} type="text"
            onChange={e => onOthersCHangeMulti(id, e.target.value)} 
            value={
              answer && answer.find(v => v.includes('otros - ')) ?
              answer.find(v => v.includes('otros - ')).substring(8) :
              ''
              }
            disabled={!(answer && answer.find(v => v.includes('otros - ')))}
            className={!(answer && answer.find(v => v.includes('otros - '))) && "input-others-disabled"}
          />
        </div>
      } 
    </ol>
    
    </>
  );
};

Multiselect.propTypes = {
  id: PropTypes.string.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  answer: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool.isRequired

};

export default Multiselect;
