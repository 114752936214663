import React from 'react';
import PropTypes from 'prop-types';
import Clickable from '../../../../../components/Clickable';

const Matrix = ({ id, title, onSelectField, rows, columns, widget }) => (
  <Clickable onClick={() => onSelectField(id)}>
    {title}
    <table className="matrix-field-table">
      <tr>
        <th />
        {columns.map(c => <th key={c.key}>{c.value}</th>)}
      </tr>
      {rows.map(r => (
        <tr>
          <th key={r.key}>{r.value}</th>
          {columns.map(c => (
            <>
              {widget && widget.type === 'radio'
                ? <td><input type="radio" disabled /></td>
                : <td><input type="checkbox" disabled /></td>
            }
            </>
          ))}
        </tr>
      ))}
    </table>
  </Clickable>
);

Matrix.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
export default Matrix;
