import React from 'react';
import PropTypes from 'prop-types';

const Nivel = ({ nivel }) => (
  <div className="nivel">
    {/* <div className="nivel-container">
      {[...Array(6)].map((u, i) => i + 1).map(i => <span className={`nivel-circle ${i <= nivel ? 'full' : ''}`} />)}
    </div> */}
    {`Nivel ${nivel}`}
  </div>
);

Nivel.propTypes = {
  nivel: PropTypes.number.isRequired
};
export default Nivel;
