import React from 'react';
import PropTypes from 'prop-types';

const TypeSelector = ({ type, widget, onChangeField, id, disabled }) => {
  /**
   * actualiza el tipo y sus correspondientes
   * propiedad deacuerdo a la seleccion
   */
  const updateType = (e) => {
    const newType = e.target.value;
    const changedProps = {};
    switch (newType) {
      case 'number':
        changedProps.type = 'number';
        changedProps.values = undefined;
        break;
      case 'input':
        changedProps.type = 'string';
        changedProps.widget = { type: 'charfield' };
        changedProps.values = undefined;
        break;
      case 'email':
        changedProps.type = 'email';
        changedProps.widget = undefined;
        changedProps.values = undefined;
        break;
      case 'textarea':
        changedProps.type = 'string';
        changedProps.widget = { type: 'textfield' };
        changedProps.values = undefined;
        break;
      case 'extensivetextarea':
        changedProps.type = 'string';
        changedProps.widget = { type: 'extensivetextfield' };
        changedProps.values = undefined;
        break;
      case 'select':
        changedProps.type = 'select';
        changedProps.widget = { type: 'radio' };
        changedProps.values = [];
        break;
      case 'multiselect':
        changedProps.type = 'multiselect';
        changedProps.widget = { type: 'checkbox' };
        changedProps.values = [];
        break;
      case 'date':
        changedProps.type = 'date';
        changedProps.values = undefined;
        break;
      case 'datetime':
        changedProps.type = 'datetime';
        changedProps.values = undefined;
        break;
      case 'radio-matrix':
        changedProps.type = 'matrix';
        changedProps.widget = { type: 'radio' };
        changedProps.values = undefined;
        break;
      case 'checkbox-matrix':
        changedProps.type = 'matrix';
        changedProps.widget = { type: 'checkbox' };
        changedProps.values = undefined;
        break;
      default:
        break;
    }
    changedProps.validators = undefined;
    changedProps.conditionals = undefined;

    Object.keys(changedProps).forEach((ch) => {
      onChangeField(id, ch, changedProps[ch]);
    });
  };

  /**
   * Get the selector option based on type and widget
   */
  const getOptionFromWidget = () => {
    if (type === 'string') {
      if (widget) {
        switch (widget.type) {
          case 'textfield':
            return 'textarea';
          case 'extensivetextfield':  
            return 'extensivetextarea';
          default:
            return 'input';
        }
      }else{
        return 'input';
      }
    }
    if (type === 'matrix') {
      return widget && widget.type === 'radio' ? 'radio-matrix' : 'checkbox-matrix';
    }
    return type;
  };

  const helptext = () => {
    switch (type){
      case "number":
        return "Número: se utiliza para aquellas preguntas de respuesta únicamente numérica. Por ejemplo, número de teléfono de contacto, edad, etc."
        break
      case "string":
        console.log(widget)
        if(!widget || widget.type === 'charfield')
          return "Respuesta breve: la respuesta es muy acotada (una oración). Por ejemplo, para preguntar el nombre de pila, los cargos que supervisa, etc."
        if(widget && widget.type === 'textfield')
          return "Párrafo: la respuesta es más extensa que una oración. Se utiliza por ejemplo, para preguntar el objetivo del rol."
        if(widget && widget.type === "extensivetextfield")
          return "Párrafo extenso: este tipo de respuesta es utilizado para las funciones principales del cargo/rol. No se debería utilizar para ninguna otra pregunta, ya que se entiende que para el resto alcanzaría con el tipo de respuesta “párrafo”. En este caso, para el formulario impreso, el sistema deja en blanco dos carillas (es por esto que no se recomienda usar para otro tipo de preguntas, ya que hace que el formulario sea bastante más largo)."
        break
      case "select":
        return "Opciones: sirve para preguntas en las que le brindamos una serie de opciones al trabajador/a, de las cuales solamente puede seleccionar una. Ejemplo: se utiliza para las competencias. Se ponen como opción los niveles del marco de competencias y el trabajador/a debe seleccionar una opción. El texto de las opciones debe iniciar con minúscula e ir sin punto final, ya que el sistema agrega un punto final en la salida de la descripción (si lo incorporamos, sale “..”)."
        break
      case "multiselect":
        return "Opción múltiple: Al igual que en “opciones” se le brinda una serie de opciones de respuesta al trabajador/a, pero en este caso puede seleccionar más de una opción. Ejemplo: se utiliza para la pregunta de contexto funcional “con quién/quiénes se relaciona/interactúa”?. El texto de las opciones debe iniciar con minúscula y no incluir el punto final."
        break
      case "email":
        return "Correo electrónico, fecha y fecha y hora: son tipos de respuesta que diseñó la empresa informática y sirven para preguntar correo electrónico, fecha y fecha y hora respectivamente. "
        break
      case "matrix":
        if(widget && widget.type === "radio")
          return "Matriz simple: sirve para diseñar la pregunta en formato tabla. Se crean filas y columnas para que la persona seleccione la opción que corresponda. Tener en cuenta que en el caso de matriz simple, el sistema obliga a seleccionar una opción de columna en cada una de las filas. En la salida de la descripción, esto se traduce en texto de la siguiente forma “texto de fila: texto de columna”, y dado que obliga a responder algo en cada fila, en la descripción aparece el texto de todas las filas y de todas las columnas. Recomiendo utilizar siempre la matriz múltiple, ya que la salida de la descripción es mejor."
        if(widget && widget.type === "checkbox")
          return "Matriz múltiple: al igual que la anterior, sirve para armar tablas, pero en este caso, se puede seleccionar solamente un ítem en toda la tabla. En la salida de la descripción, solamente se visualiza la opción que seleccionó el trabajador/a de la misma manera que en la opción anterior: Fila: columna. Ejemplo: se puede utilizar para el caso de las condiciones de contexto físico, levantamiento de carga, posturas, etc"
        break
    }
}

  return (
    <div>
      <select defaultValue={getOptionFromWidget()} onChange={updateType} disabled={disabled}>
        <option value="number">Numero</option>
        <option value="input">Respuesta breve</option>
        <option value="textarea">Párrafo</option>
        <option value="extensivetextarea">Párrafo extenso</option>
        <option value="select">Opciones</option>
        <option value="multiselect">Opción Múltiple</option>
        <option value="email">Correo Electrónico</option>
        <option value="date">Fecha</option>
        <option value="datetime">Fecha y hora</option>
        <option value="radio-matrix">Matriz Simple</option>
        <option value="checkbox-matrix">Matriz Múltiple</option>
      </select>
      <div title={helptext()} className="helptext-icon">?</div>
    </div>
  );
};

TypeSelector.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  widget: PropTypes.object.isRequired
};

export default TypeSelector;
