/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Transition } from 'react-spring/renderprops';
import NumberValidator from '../../../validators/NumberValidator';
import Conditional from '../../../conditionals/Conditional';
import useValidators from '../../../hooks/useValidators';
import useConditionals from '../../../hooks/useConditionals';
import { transitions } from '../../../utils';

const EditNumber = ({ id, validators, conditionals, schemaFields, onChangeField }) => {
  const [vals, setVals] = useValidators({ max: 2, list: validators });
  const [conds, setConds] = useConditionals(conditionals);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (vals && vals.length === 2) {
      const max = vals.find(v => v.type === 'maxValue');
      const min = vals.find(v => v.type === 'minValue');
      if (max !== undefined && min !== undefined) {
        if (min.value > max.value) {
          setErrors({ ...errors, minValue: 'Debe ser menor que el máximo.' });
        } else {
          setErrors({});
          onChangeField(id, 'validators', vals);
        }
      }
    } else {
      onChangeField(id, 'validators', vals);
    }
  }, [vals]);

  useEffect(() => {
    if (conds) {
      onChangeField(id, 'conditionals', conds);
      onChangeField(id, 'hidden', true);
    }
  }, [conds]);

  return (
    <>
      <Transition items={validators !== undefined} {...transitions}>
        {show => show && (props => (
          <label className="validators" style={props}>
          Condiciones
            {vals && vals.map((v, k) => (
              <>
                <NumberValidator index={k} type={v.type}
                  value={v.value} onChange={setVals} error={errors[v.type]}
                  used={vals.map(va => va.type).filter(va => va)}
                />
              </>
            ))}
            {(!vals || vals.length < 2) && (
            <button className="add-option" type="button" onClick={setVals}>
              Agregar
            </button>
            )}
          </label>
        ))}
      </Transition>
      <Transition items={conditionals !== undefined} {...transitions}>
        {show => show && (props => (
          <label className="conditionals" style={props}>
            Lógica
            {conds && conds.length > 0 && (
            <div>
              {conds.map((cond, i) => (
                <Conditional key={i} index={i} data={cond}
                  fields={schemaFields.filter(f => f.id !== id)} onChange={setConds}
                />
              ))}
            </div>
            )}
            <button className="add-option" type="button" onClick={setConds}>
              Agregar
            </button>
          </label>
        ))}
      </Transition>
    </>
  );
};

EditNumber.propTypes = {
  id: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.object),
  onChangeField: PropTypes.func.isRequired
};

EditNumber.defaultProps = {
  validators: undefined
};

const mapStateToProps = state => ({
  schemaFields: state.forms.fields
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNumber);
