import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { fetchSector, updateSector, clearActions } from '../redux/actions';
import PrimaryButton from '../../../components/PrimaryButton';
import Spinner from '../../../components/Spinner';

const SectorDescription = ({ match, fetch, sector, update, action, clear }) => {
  /*
    Component to modify the sector's description
  */

  const [description, setDescription] = useState();
  const text = useRef();
  useEffect(() => {
    fetch(match.params.id);
    return () => { clear(); };
  }, []);

  const handleSave = () => {
    update(match.params.id, { descripcion: description });
  };

  function insertTextAtCursor(text) {
    var val = text.value, endIndex, range, doc = text.ownerDocument;
    if (typeof text.selectionStart == "number"
            && typeof text.selectionEnd == "number") {
        endIndex = text.selectionEnd;
        text.value = val.slice(0, endIndex) + '\u2022' + val.slice(endIndex);
        text.selectionStart = text.selectionEnd = endIndex + text.length;
    } else if (doc.selection != "undefined" && doc.selection.createRange) {
        text.focus();
        range = doc.selection.createRange();
        range.collapse(false);
        range.text = "\u2022";
        range.select();
    }
    setDescription(text.value)
}

  if (!sector) return null;
  return (
    <div className="page">
      <div className="page-title-nav">
        <h1>Valoración</h1>
        <Link to={`/sectores/${match.params.id}/`}>Volver al sector</Link>
      </div>
      <div className="col-container">
        <div className="col-left">
          <div className="info">
            <span className="label">Sector</span>
            {sector.nombre}
          </div>
          <div className="info">
            <span className="label">Responsables INEFOP</span>
            {sector.responsable.map(a => <div>{a}</div>)}
          </div>
          {sector && sector.colaboradores.length > 0 && (
            <div className="info">
              <span className="label">Colaboradores</span>
              {sector.colaboradores.map(a => <div>{a}</div>)}
            </div>
          )}
          {sector && sector.encuestadores.length > 0 && (
            <div className="info">
              <span className="label">Encuestadores</span>
              {sector.encuestadores.map(e => <div>{e}</div>)}
            </div>
          )}
        </div>
        <div className="content">
          <label>
            Esta descripción se incluirá en el encabezado de todos los cargos/roles
            <textarea ref={text} name="" id="" rows="8"  value={description} defaultValue={sector.descripcion} onChange={e => setDescription(e.target.value)} />
          </label>
          <div className="col-container description-save">
            <div>
              <PrimaryButton text="Bullet" onClick={() => {insertTextAtCursor(text.current)}} />
            </div>
            <div className="content">
              <Spinner active={action.loading} />
              {action.error && <span className="feedback-error">{action.message}</span> }
              {action.success && <span className="feedback-success">Sus valores fueron guardados con éxito</span> }
              <PrimaryButton text="Guardar" onClick={handleSave} />
            </div>
          </div>
        </div>
        <div className="col-right" />
      </div>
    </div>

  );
};

SectorDescription.propTypes = {
  match: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  sector: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  sector: state.sectores.sector,
  action: state.sectores.saveAction
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(fetchSector(id)),
  update: (id, info) => dispatch(updateSector(id, info)),
  clear: () => dispatch(clearActions())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectorDescription);
