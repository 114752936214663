import React from 'react';
import PropTypes from 'prop-types';
import AnswerField from '../../../pulpoforms/answer/AnswerField';

const AnswerSection = ({
  printable, readOnly, errors, onOthersCHangeMulti, puedeEncuestar, onOthersCHange, sec, fields, index, answers, onAnswerChange, renderProfileText
}) => {
  const satisfyConditional = (fieldId) => {
    const field = fields.find(f => f.id === fieldId);
    if (!field.conditionals || field.conditionals.length === 0) {
      return true;
    }
    let meetConditions = false;

    field.conditionals.forEach((cond) => {
      const target = fields.find(f => f.id === cond.field);
      if (target.type === 'multiselect') {
        meetConditions = answers[cond.field] ? answers[cond.field].includes(cond.value) : false ;
      } else {
        meetConditions = answers[cond.field] === cond.value;
      }
    });
    return meetConditions;
  };

  return (
    <div className="answer-section-container">
      <p>{`${index} - ${sec.title}`}</p>
      {sec.fields.map((secf, idx) => {
        const field = fields.find(f => f.id === secf);
        return (
          <div key={field.id} className="fields-section">
            <AnswerField {...field} errors={errors[field.id]}
              index={`${index}.${idx + 1}`}
              onAnswerChange={onAnswerChange}
              satisfyConditional={satisfyConditional(field.id)}
              renderProfileText={renderProfileText}
              answer={answers[field.id]} readOnly={readOnly}
              puedeEncuestar={puedeEncuestar} printable={printable}
              onOthersCHange={onOthersCHange}
              onOthersCHangeMulti={onOthersCHangeMulti}
            />
          </div>
        );
      })}
    </div>
  );
};
AnswerSection.propTypes = {
  sec: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

AnswerSection.defaultProps = {
  readOnly: false
};

export default AnswerSection;
