import React from 'react';
import PropTypes from 'prop-types';


const FieldDetail = ({
  fieldForm, fieldValor }) => (
    <div className="valoracion-row">
      <div>{`${fieldValor.percentage}% - ${fieldForm.profile_text ? fieldForm.profile_text : fieldForm.title}`}</div>
      <div>{fieldValor.total}</div>
    </div>
);

FieldDetail.propTypes = {
  fieldForm: PropTypes.object.isRequired,
  fieldValor: PropTypes.object.isRequired
};
export default FieldDetail;
