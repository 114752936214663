import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const Table = ({ data }) => {
  const getClassName = (estado) => {
    switch (estado) {
      case 'CO':
        return 'badge orange';
      case 'HA':
        return 'badge blue';
      case 'ER':
        return 'badge green';
      default:
        return '';
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: '10%' }}>ID</th>
          <th>Nombre</th>
          <th style={{ width: '25%' }}>Estado</th>
          <th style={{ width: '20%' }}>Accion</th>
        </tr>
      </thead>
      <tbody>
        {data && data.map(d => (
          <tr>
            <td>
              <Link to={`/perfiles/${d.id}`}>{d.id}</Link>
            </td>
            <td>
              <Link to={`/perfiles/${d.id}`}>{d.nombre}</Link>
            </td>
            <td>
              <span className={d.estado && getClassName(d.estado.key)}>{d.estado && d.estado.value}</span>
            </td>
            <td>
              {!d.validacion && <Link to={`/perfiles/${d.id}/validacion`}>Adjuntar Validación</Link>}
              {d.validacion && <a href={d.validacion}>Ver Validación</a>}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default Table;
