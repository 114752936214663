import React from 'react';
import PropTypes from 'prop-types';

const email = ({ readOnly, id, answer, onAnswerChange }) => (
  <input name={id} id={id} type="email" defaultValue={answer} disabled={readOnly}
    onChange={e => onAnswerChange(id, e.target.value)}
  />
);

email.propTypes = {
  id: PropTypes.string.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};
export default email;
