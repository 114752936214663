import { useState } from 'react';

const useModal = () => {
  const [active, setActive] = useState(false);

  function toggleModal() {
    setActive(!active);
  }

  return [active, toggleModal];
};

export default useModal;
