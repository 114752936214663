/* eslint-disable no-param-reassign */
import {
  FETCH_LIST,
  FETCH_SECTOR,
  SAVE_SECTOR,
  FETCH_SECTOR_FULL,
  SAVE_SECTOR_FULL,
  CLEAR_ACTIONS,
  ENABLE_VALS,
  FETCH_ESTRUCTURA,
  FETCH_ANSWER_ID } from './types';

const INITIAL_STATE = {
  list: [],
  saveAction: {
    success: false,
    loading: false,
    message: ''
  },
  action: {
    success: false,
    loading: false,
    message: ''
  }
  /* initialize state */
};

export const actionForState = (action, state, message) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: message || state
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /* ******************************** */
    /*            FETCH LIST            */
    /* ******************************** */
    case FETCH_LIST.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_LIST.success:
      return {
        ...state,
        list: action.payload.results,
        count: Math.ceil(action.payload.count / 10),
        action: actionForState(state.action, 'success')
      };
    case FETCH_LIST.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*            FETCH SECTOR          */
    /* ******************************** */
    case FETCH_SECTOR.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_SECTOR.success:
      return {
        ...state,
        sector: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_SECTOR.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
      /* ******************************** */
    /*            FETCH SECTOR          */
    /* ******************************** */
    case FETCH_ESTRUCTURA.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_ESTRUCTURA.success:
      return {
        ...state,
        estructura: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_ESTRUCTURA.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*            FETCH ANSWER ID          */
    /* ******************************** */
    case FETCH_ANSWER_ID.pending:
      return {
        ...state,
        action: actionForState(state.action, 'pending')
      };
    case FETCH_ANSWER_ID.success:
      return {
        ...state,
        answer_id: action.payload,
        action: actionForState(state.action, 'success')
      };
    case FETCH_ANSWER_ID.error:
      return {
        ...state,
        action: actionForState(state.action, 'error')
      };
    /* ******************************** */
    /*            ENABLE VALS           */
    /* ******************************** */
    case ENABLE_VALS.pending:
      return {
        ...state,
        valsAction: actionForState(state.action, 'pending')
      };
    case ENABLE_VALS.success:
      return {
        ...state,
        valsAction: actionForState(state.action, 'success')
      };
    case ENABLE_VALS.error:
      return {
        ...state,
        valsAction: {
          success: false,
          loading: false,
          data: action.payload.response.data
        }
      };
    /* ******************************** */
    /*          FETCH SECTOR FULL       */
    /* ******************************** */
    case FETCH_SECTOR_FULL.pending:
    case SAVE_SECTOR_FULL.pending: {
      const status = action.type === SAVE_SECTOR_FULL.pending ? 'saveAction' : 'action';
      return {
        ...state,
        [status]: actionForState(state.action, 'pending')
      };
    }
    case FETCH_SECTOR_FULL.success:
    case SAVE_SECTOR_FULL.success: {
      const { schema } = action.payload;
      schema.fields = schema.fields.filter(f => f.rate);

      const relevantFieldsID = schema.fields.map(f => f.id);
      schema.sections.forEach((s, i) => {
        s.fields = s.fields.filter(f => relevantFieldsID.includes(f));
        // If the section has not relevant fields, we must to delete it.
        if (s.fields.length === 0) schema.sections.splice(i, 1);
      });

      const relevantSectionsID = schema.sections.map(f => f.id);
      schema.pages.forEach((p, i) => {
        p.content = p.content.filter(f => (
          relevantFieldsID.concat(relevantSectionsID)).includes(f));
        // If the page has not relevant content, we must to delete it.
        if (p.content.length === 0) delete schema.pages[i];
      });
      const status = action.type === SAVE_SECTOR_FULL.success ? 'saveAction' : 'action';
      return {
        ...state,
        sector: { ...action.payload, schema },
        [status]: actionForState(state.action, 'success')
      };
    }
    case FETCH_SECTOR_FULL.error:
    case SAVE_SECTOR_FULL.error: {
      const status = action.type === SAVE_SECTOR_FULL.error ? 'saveAction' : 'action';
      return {
        ...state,
        [status]: actionForState(state.action, 'error')
      };
    }
    /* ******************************** */
    /*           SAVE SECTOR            */
    /* ******************************** */
    case SAVE_SECTOR.pending:
      return {
        ...state,
        asaveAction: actionForState(state.action, 'pending')
      };
    case SAVE_SECTOR.success:
      return {
        ...state,
        sector: action.payload,
        saveAction: actionForState(state.action, 'success')
      };
    case SAVE_SECTOR.error:
      return {
        ...state,
        saveAction: actionForState(state.action, 'error')
      };
    case CLEAR_ACTIONS:
      return {
        ...state,
        saveAction: INITIAL_STATE.saveAction,
        action: INITIAL_STATE.action,
      };
    default:
      return state;
  }
}
