import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../../../components/ConfirmModal';
import useModal from '../../../../hooks/useModal';
import { fetchAnswerId } from '../../../Sectors/redux/actions';
import { fetchList, updateTempAnswer } from '../../../Profiles/redux/actions';

const Step = ({ inactive, info, step, current, getAnswerId , answer_id, profileList, fetchProfiles, updateDatos}) => {
  const { title, image, actions, pruebaActions } = info;
  const [mailText, setMailText] = useState('');
  const [mail, setMail] = useState('');
  const [profileValue, setProfileValue] = useState();
  const [activeModal, toggleModal] = useModal();
  const [publicID, setPublicID] = useState(undefined);

  const handleModal = (sectorID) => {
    toggleModal();
    getAnswerId(sectorID);
    fetchProfiles(sectorID, {page: 'all'});
    setPublicID(btoa(sectorID));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof profileValue === 'undefined') {
      updateDatos(mail, mailText, null, answer_id);
    } else {
      updateDatos(mail, mailText, profileValue.value, answer_id);
    }
    setMail('');
    setMailText('');

    toggleModal();
  };

  return (
    <div className="step">
      <div className="circle">
        <img src={image} alt={title} />
      </div>
      <div className="info">
        <p>{title}</p>
        {inactive
          ? <span className="inactive"> Completar el paso anterior para continuar</span>
          : (
            <ul className="actions">
              {actions.filter(a => (!('condition' in a) || a.condition)).map(a => (
                <>
                  {console.log(a)}
                  {a.modal
                    ? <li><a href="#" onClick={() => handleModal(a.modal)}>{a.title}</a></li>
                    : <li><Link to={a.link}>{a.title}</Link></li>
                  }
                </>
              ))}
              <ConfirmModal active={activeModal} title="Link trabajador"
                onConfirm={handleSubmit} onCancel={toggleModal} hideCancelBtn
                content={(
                  <div>
                    Envíe este link de encuesta para el o la trabajadora:
                    <br />
                    {answer_id && (
                      <a href={`${window.location.origin}/r/${publicID}/${answer_id}`}>
                        {`${window.location.origin}/r/${publicID}/${answer_id}`}
                      </a>
                    )}

                    <div style={{ display: 'grid', paddingTop: '16px', paddingBottom: '54px' }}>
                      <label id='cargo-field'>
                        Cargo/Rol
                        <select onChange={e => setProfileValue({ value: e.target.value,
                          text: e.target.options[e.target.selectedIndex].text })}
                        >
                          <option value="" selected>Seleccione una opción</option>
                          {profileList && profileList.map(p => (
                            <option value={p.id}>{p.nombre}</option>
                          ))}
                        </select>
                      </label>

                      <label id='mail-text-field'>
                        Mensaje (opcional)
                        <textarea onChange={e => setMailText(e.target.value)} />
                      </label>

                      <label id='mail-field'>
                        Enviar vía mail
                        <input type="text" onChange={e => setMail(e.target.value)} />
                      </label>
                    </div>
                  </div>
                )}
              />
            </ul>
          )}
        {step === 0 && (pruebaActions[0].condition || pruebaActions[1].condition) && (
          <span className="label test_answers">
            Respuestas de prueba:
            <ul className="actions">
            {current === 0 && pruebaActions[0].condition
              && <li><a href={pruebaActions[0].link} className="inline-link">{pruebaActions[0].title}</a></li>}
            {pruebaActions[1].condition && <li><Link to={pruebaActions[1].link} className="inline-link">{pruebaActions[1].title}</Link></li>}
            </ul>
          </span>
        )}
      </div>
    </div>
  );
};

Step.propTypes = {
  inactive: PropTypes.bool,
  info: PropTypes.object.isRequired
};

Step.defaultProps = {
  inactive: false
};

const mapStateToProps = state => ({
  sector: state.sectores.sector,
  answer_id: state.sectores.answer_id,
  profileList: state.profiles.list
});

const mapDispatchToProps = dispatch => ({
  getAnswerId: id => dispatch(fetchAnswerId(id)),
  fetchProfiles: (id, page) => dispatch(fetchList(id, page)),
  updateDatos: (mail, mailText, profile, uuid) => dispatch(updateTempAnswer(mail, mailText, profile, uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Step);
